import { IsSchemaCompliant } from 'app/common/interfaces/isSchemaCompliant';

// SectionsDynamic models data returned from the API.
// *** Shape should match exactly what the API returns. ***
export class SectionsDynamic implements IsSchemaCompliant {
    public httpVerb: string;
    public api: string;

    // Not part of data returned from API.
    public initialLoadComplete: boolean = false;
    public isReloading: boolean = false;

    // Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
    constructor(jsonData: any) {
        this.httpVerb = jsonData.httpVerb;
        this.api = jsonData.api;
    }

    // User defined type guard to verify data is a valid SectionsDynamic.
    public isSchemaCompliant(): boolean {
        let isCompliant: boolean = true;

        // Make sure all non-nullable properties are present.
        isCompliant = !!this.httpVerb && !!this.api;
        if (!isCompliant) {
            console.error('JSON is non-compliant - sectionsDynamic required properties not found.');
        }

        return isCompliant;
    }
}

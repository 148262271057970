import { DropdownOption } from './dropdownOption';
import { DropdownOptionDynamic } from './dropdownOptionDynamic';
import { IsSchemaCompliant } from 'app/common/interfaces/isSchemaCompliant';

// ControlOptions models data returned from the API.
// *** Shape should match exactly what the API returns. ***
export class ControlOptions implements IsSchemaCompliant {
    // For use with: ControlType.textbox
    public maxLength?: number;
    public minLength?: number;
    public regExPattern?: string;
    public placeholder?: string;
    public helpText?: string;
    public passwordMask?: boolean;
    public mustEqualFieldId?: string;
    public modalAlertType?: string; 

    // For use with: ControlType.radiobutton
    public groupName?: string; // Sets multiple radio buttons with the same name to be part of the same grouping.

    // For use with: ControlType.dropdown
    public nullSelectionText?: string;
    public dropdownOptionsStatic?: DropdownOption[];
    public dropdownOptionsDynamic?: DropdownOptionDynamic;

    // For use with ControlType.upload
    public acceptedFileExtensions?: string;
    public maxFileSize?: number;
    public uploadFileUrl?: string;

    // For use with ControlType.html
    public cssClass?: string;

    // For use with ControlType.iframe
    public src?: string;

    // Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
    constructor(jsonData: any) {
        this.maxLength = jsonData.maxLength;
        this.minLength = jsonData.minLength;
        this.regExPattern = jsonData.regExPattern;
        this.placeholder = jsonData.placeholder;
        this.helpText = jsonData.helpText;
        this.passwordMask = jsonData.passwordMask;
        this.mustEqualFieldId = jsonData.mustEqualFieldId;
        this.modalAlertType = jsonData.modalAlertType; 
        this.groupName = jsonData.groupName;
        this.nullSelectionText = jsonData.nullSelectionText;
        this.dropdownOptionsStatic = !!jsonData.dropdownOptionsStatic ? [] : undefined;
        if (!!jsonData.dropdownOptionsStatic) {
            for (let i: number = 0; i < jsonData.dropdownOptionsStatic.length; i++) {
                this.dropdownOptionsStatic.push(new DropdownOption(jsonData.dropdownOptionsStatic[i]));
            }
        }
        this.dropdownOptionsDynamic = !!jsonData.dropdownOptionsDynamic ? new DropdownOptionDynamic(jsonData.dropdownOptionsDynamic) : undefined;
        this.acceptedFileExtensions = jsonData.acceptedFileExtensions;
        this.maxFileSize = jsonData.maxFileSize;
        this.uploadFileUrl = jsonData.uploadFileUrl;
        this.cssClass = jsonData.cssClass;
        this.src = jsonData.src;
    }

    // User defined type guard to verify data is a valid ControlOptions.
    public isSchemaCompliant(): boolean {
        let isCompliant: boolean = true;
        // Make sure all non-nullable properties are present.
        // There are no current non-nullable fields on this object.

        // Call isSchemaCompliant on child objects.
        if (isCompliant && !!this.dropdownOptionsStatic) {
            for (let i = 0; i < this.dropdownOptionsStatic.length; i++) {
                isCompliant = this.dropdownOptionsStatic[i].isSchemaCompliant();
                if (!isCompliant) {
                    break;
                }
            }
        }

        if (isCompliant && !!this.dropdownOptionsDynamic) {
            isCompliant = this.dropdownOptionsDynamic.isSchemaCompliant();
        }

        if (isCompliant && !!this.dropdownOptionsStatic && !!this.dropdownOptionsDynamic) {
            isCompliant = false;
            console.error('JSON is non-compliant - dropdownOptionsStatic and dropdownOptionsDynamic both present. Only one is allowed.');
        }

        return isCompliant;
    }
}

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { InstrumentationService } from 'app/common/services/instrumentation.service';
import { AppSharedStateService } from 'app/common/services/app-shared-state.service';

// This interceptor times the http calls and logs them.
@Injectable()
export class InstrumentationInterceptorService implements HttpInterceptor {
    // Constructor for InstrumentationInterceptorService.
    constructor(
        private instrumentationService: InstrumentationService,
        private appSharedStateService: AppSharedStateService
    ) { }

    // Intercepts all outbound http request traffic.
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const startDate: Date = new Date();
        let index: number = req.url.indexOf('BankService') + 1;
        index = req.url.indexOf('/', index) + 1;
        this.instrumentationService.trackEvent(
            `API request: ${req.method} ${req.url.substring(index)}`,
            {
                url: req.url,
                startTime: startDate.toString(),
                correlationId: req.headers.get('correlationid'),
                referenceId: this.appSharedStateService.referenceId,
                userKey: this.appSharedStateService.userKey
            }
        );
        return next
            .handle(req).pipe(
                tap(event => {
                    if (event instanceof HttpResponse) {
                        const endDate: Date = new Date();
                        const elapsedMilliseconds: number = (endDate.getTime() - startDate.getTime()) / 1000;
                        console.log(`Request for ${req.urlWithParams} took ${elapsedMilliseconds} ms.`);
                        this.instrumentationService.trackEvent(
                            `API response: ${req.method} ${req.url.substring(index)}`,
                            {
                                url: req.url,
                                endTime: endDate.toString(),
                                status: event.status.toString(),
                                correlationId: req.headers.get('correlationid'),
                                referenceId: this.appSharedStateService.referenceId,
                                userKey: this.appSharedStateService.userKey
                            },
                            {
                                elapsedTime: elapsedMilliseconds
                            }
                        );
                    }
                }, err => {
                const endDate: Date = new Date();
                const elapsedMilliseconds: number = (endDate.getTime() - startDate.getTime()) / 1000;
                this.instrumentationService.trackEvent(
                    `API error: ${req.method} ${req.url.substring(index)}`,
                    {
                        url: err.url,
                        endTime: endDate.toString(),
                        status: err.status.toString(),
                        correlationId: req.headers.get('correlationid'),
                        referenceId: this.appSharedStateService.referenceId,
                        userKey: this.appSharedStateService.userKey
                    },
                    {
                        elapsedTime: elapsedMilliseconds
                    }
                );
            }));
    }
}

import { ControlOptions } from './controlOptions';
import { ControlType, controlTypeFromString } from './controlType';
import { IsSchemaCompliant } from 'app/common/interfaces/isSchemaCompliant';

// FieldMetadata models data returned from the API.
// *** Shape should match exactly what the API returns. ***
export class FieldMetadata implements IsSchemaCompliant {
    // Note: Everything is nullable by design. Even label and control which typically should not be null.
    // Reason is because of support for the alterExistingFieldMetadata property which allows for swapping
    // out of any given property with another.
    public alterExistingFieldMetadata?: boolean; // If true, then look for existing field matching the SectionField id and copy over changed metadata.
    public fieldGroup?: string;
    public columnHint?: number;
    public indentHint?: number;
    public required?: boolean;
    public readOnly?: boolean;
    public visible?: boolean; // If null or undefined or missing then this is implied to be true. Only hide if property is present and boolean is false.
    public label?: string;
    public ariaLabel?: string;
    public fieldInfo?: string;
    public control?: ControlType;
    public canDirtyNextSections?: boolean;
    public controlOptions?: ControlOptions;

    // Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
    constructor(jsonData: any) {
        this.alterExistingFieldMetadata = jsonData.alterExistingFieldMetadata;
        this.fieldGroup = jsonData.fieldGroup;
        this.columnHint = jsonData.columnHint;
        this.indentHint = jsonData.indentHint;
        this.required = jsonData.required;
        this.readOnly = jsonData.readOnly;
        this.visible = jsonData.visible;
        this.label = jsonData.label;
        this.ariaLabel = jsonData.ariaLabel;
        this.fieldInfo = jsonData.fieldInfo;
        this.control = controlTypeFromString(jsonData.control);
        this.canDirtyNextSections = jsonData.canDirtyNextSections;
        this.controlOptions = !!jsonData.controlOptions ? new ControlOptions(jsonData.controlOptions) : undefined;
    }

    // User defined type guard to verify data is a valid FieldMetadata.
    public isSchemaCompliant(): boolean {
        let isCompliant: boolean = true;

        // Make sure all non-nullable properties are present.
        // All properties are nullable as:
        //   - Some controls, like html, don't use some of these like label, ariaLabel
        //   - When the alterExistingFieldMetadata property is true, then all these values can be null.

        // Call isSchemaCompliant on child objects.
        if (isCompliant && !!this.controlOptions) {
            isCompliant = this.controlOptions.isSchemaCompliant();
        }

        return isCompliant;
    }
}

import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { LocalizationManagerService } from 'app/common/services/localization-manager.service';
import { SessionManagerService } from 'app/common/services/session-manager.service';
import { BasePageComponent } from 'app/pages/base-page.component';

@Component({
    selector: 'app-invalid-route',
    templateUrl: './invalid-route.component.html',
    styleUrls: ['./invalid-route.component.css']
})
export class InvalidRouteComponent extends BasePageComponent implements OnInit {
    // Constructor for InvalidRouteComponent.
    constructor(
        private sessionManagerService: SessionManagerService,
        public lms: LocalizationManagerService /* Using short name on purpose for data binding. */,
        location: Location) {
        super(location);
    }

    // Angular OnInit handler. Part of the lifecycle hooks: https://angular.io/guide/lifecycle-hooks
    ngOnInit() {
        this.sessionManagerService.loadSessionDataIfNeeded(false);
    }
}

import { Injectable } from '@angular/core';
import { LocalizationManagerService } from 'app/common/services/localization-manager.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AppSharedStateService, HeaderFooterType } from 'app/common/services/app-shared-state.service';
import { AppSharedUtilityService } from 'app/common/services/app-shared-utility.service';
import { MetadataApiService } from 'app/common/services/metadata-api.service';
import { MessageData } from 'app/common/components/message-display/message-data';
import { SessionData } from 'app/common/metadata-models/sessionData';
import { MiscUtil } from 'app/common/utility/miscUtil';
import { AlertType } from 'app/common/components/message-display/alert-type';
import { HttpErrorResponse } from '@angular/common/http';

// Session manager service.
@Injectable()
export class SessionManagerService {
    private intervalId: number;
    private userSessionExpiryDate: Date = new Date();
    private userSessionTimeRemaining: number;
    private millisecondsToExpiration: number;
    private sessionWarningShown: boolean = false;
    private sessionNoticeShown: boolean = false;
    public displayExpiryDate: string;
    public profileInProgress: boolean;

    // SessionManagerService constructor.
    constructor(
        public appSharedStateService: AppSharedStateService, // Public so it can be data bound in html.
        public appSharedUtilityService: AppSharedUtilityService, // Public so it can be data bound in html.
        private metadataApiService: MetadataApiService,
        private domSanitizer: DomSanitizer,
        public lms: LocalizationManagerService /* Using short name on purpose for data binding. */
        ) {
    }

    // Loads session data if not yet loaded, or if forceRefresh is true.
    public loadSessionDataIfNeeded(forceRefresh: boolean) {
        if (!this.appSharedStateService.sessionData || forceRefresh) {
            this.getSessionData();
        }
    }

    // Get session data. This is used to determine optional cobranded header/footer, return uri, culture, and possibly
    // other session data.
    private getSessionData() {
        if (MiscUtil.isNullOrUndefinedOrEmptyString(this.appSharedStateService.sessionId)) {
            this.useDefaultSessionData();
            return;
        }

        // Get session data.
        this.metadataApiService.getSessionData().subscribe((sessionData: SessionData) => {
            // If return uri was missing, then infer it from document.referrer (as long as referrer is not the same url as current page).
            if (!sessionData.returnUri && (!!sessionData.returnUri && sessionData.returnUri !== window.location.href)) {
                sessionData.returnUri = document.referrer;
            }

            // Ensure lowercase headerUri.
            if (!!sessionData && !!sessionData.headerUri) {
                sessionData.headerUri = sessionData.headerUri.toLowerCase();
            }
            // Ensure lowercase footerUri.
            if (!!sessionData && !!sessionData.footerUri) {
                sessionData.footerUri = sessionData.footerUri.toLowerCase();
            }

            // Store the user session expiration time/date value
            if (!!sessionData && !!sessionData.userSessionExpiryDate) {              

                // Moving the below calculation of milliseconds from the handleSessionExpiration to here
                // allows it so that the timer doesn't change up or down until after a page refresh happens
                // userSessionTimeRemaining is in minutes - translating it to milliseconds here
                this.millisecondsToExpiration = sessionData.userSessionTimeRemaining * 60000;

                // Set up an interval to handle session expiration.
                // The cast to any is to work around nodejs type definition for setInterval (which is a Timer).
                if (!!this.userSessionExpiryDate) {
                    if (!!this.intervalId) {
                        clearInterval(this.intervalId);
                    }
                    
                    this.intervalId = <any>setInterval(() => {
                        this.handleSessionExpiration(this.intervalId);
                    }, 1000);

                    // If user leaves page, the timer will slow down
                    // To stop this from happening, when the user comes back to the page
                    // the session will be renewed - the timer will reset to the given session length minutes
                    var self: SessionManagerService = this;
                    window.addEventListener("focus", function() {

                        // For this specific getSessionData call, a false parameter is sent with it
                        // so that when getting the session, and the session has expired, a expiration session message
                        // is not displayed. Below this call, a expiration message is displayed and when it is closed, 
                        // it will redirect the user to the return uri
                        self.metadataApiService.getSessionData(false).subscribe((sessionData: SessionData) => {

                            // clear current timer because new timer will start
                            clearInterval(self.intervalId);

                            // only reset timer if user hasn't been inactive for more than the given session length
                            if(self.millisecondsToExpiration > 0){
                                self.millisecondsToExpiration = sessionData.userSessionTimeRemaining * 60000;                        
                                self.intervalId = <any>setInterval(() => {
                                    self.handleSessionExpiration(self.intervalId);
                                }, 1000);              
                            }          

                        }, (error: any) => {

                            if(error instanceof HttpErrorResponse && error.status == 401){

                                // Either session was not retrieved or it has expired so the timer is stopped
                                clearInterval(self.intervalId);

                                // Zero out the timer manually since stopping it wont display to the user that the session has expired
                                self.displayExpiryDate = "0:00";

                                // Display and reroute once session expires
                                self.sessionNoticeShown = true;
                                const messageData: MessageData = {
                                    message: self.lms.get('SESSION_TIMEOUT_NOTICE'),
                                    alertType: AlertType.Error,
                                    closedCallback: () => {
                                        // Navigate back to the return uri.
                                        self.appSharedUtilityService.navigateToReturnUri();
                                    }
                                };
                                self.appSharedUtilityService.displayMessage(messageData);       

                            } else{

                                // The call to get session data failed. The CustomErrorHandlerService will cause an error to display.
                                self.useDefaultSessionData();     

                            }          

                        })
                    })
                }
            }

            if (!!sessionData && !!sessionData.profileInProgress) {
                this.profileInProgress = sessionData.profileInProgress;
            }

            // Determine what to do with header.
            this.appSharedStateService.headerType = HeaderFooterType.BuiltIn;

            /* Temporarily disable of co-branding until PX3 (and others) provide compatable header/footer
            if (!sessionData || !sessionData.headerUri || this.checkIfHeaderFooterIsMpcNvaOrPopMockPortal(sessionData.headerUri)) {
                // If the sessionData or headerUri is null, undefined, or empty, or if it came from MPC NVA or POP mock portal - then use built in header.
                this.appSharedStateService.headerType = HeaderFooterType.BuiltIn;
            } else if (sessionData.headerUri.endsWith('.js') || sessionData.headerUri.endsWith('.cshtml')) {
                // If the headerUri ends with .js or .cshtml then use cobrand remote javascript.
                this.appSharedStateService.headerType = HeaderFooterType.CobrandRemoteJavascript;
            } else if (sessionData.headerUri.endsWith('.html') || sessionData.headerUri.endsWith('.htm')) {
                // If the headerUri ends with .html or .htm - Perform a basic jquery based http get call
                // to download the html file and get its contents.
                $.get(sessionData.headerUri, (data) => {
                    this.appSharedStateService.cobrandHeaderHtml = this.domSanitizer.bypassSecurityTrustHtml(data);
                }).then((data) => {
                    // Call was successful, so set the header type to cobrand remote html.
                    this.appSharedStateService.headerType = HeaderFooterType.CobrandRemoteHtml;
                }, (err) => {
                    // If there was an error downloading the file, default to built in header.
                    this.appSharedStateService.headerType = HeaderFooterType.BuiltIn;
                });
            }
            */

            // Determine what to do with footer.
            this.appSharedStateService.footerType = HeaderFooterType.BuiltIn;

            /* Temporarily disable of co-branding until PX3 (and others) provide compatable header/footer
            if (!sessionData || !sessionData.footerUri || this.checkIfHeaderFooterIsMpcNvaOrPopMockPortal(sessionData.footerUri)) {
                // If the sessionData or headerUri is null, undefined, or empty, or if it came from MPC NVA or POP mock portal - then use built in footer.
                this.appSharedStateService.footerType = HeaderFooterType.BuiltIn;
            } else if (sessionData.footerUri.endsWith('.js') || sessionData.footerUri.endsWith('.cshtml')) {
                // If the footerUri ends with .js or .cshtml then use cobrand remote javascript.
                this.appSharedStateService.footerType = HeaderFooterType.CobrandRemoteJavascript;
            } else if (sessionData.footerUri.endsWith('.html') || sessionData.footerUri.endsWith('.htm')) {
                // If the footerUri ends with .html or .htm - Perform a basic jquery based http get call
                // to download the html file and get its contents.
                $.get(sessionData.footerUri, (data) => {
                    this.appSharedStateService.cobrandFooterHtml = this.domSanitizer.bypassSecurityTrustHtml(data);
                }).then((data) => {
                    // Call was successful, so set the footer type to cobrand remote html.
                    this.appSharedStateService.footerType = HeaderFooterType.CobrandRemoteHtml;
                }, (err) => {
                    // If there was an error downloading the file, default to built in footer.
                    this.appSharedStateService.footerType = HeaderFooterType.BuiltIn;
                });
            }
            */

            // Store session data in this shared state service so other components can use it.
            this.appSharedStateService.sessionData = sessionData;
        }, (error: any) => {
            // The call to get session data failed. The CustomErrorHandlerService will cause an error to display.
            this.useDefaultSessionData();
        });
    }

    // Prepare and use default session data. This should only be used in the event of a failed call to get session data
    // or if for some reason the sessionId is missing from the query string.
    private useDefaultSessionData() {
        // Set the session data object to default/empty object, except infer the returnUri to be the document.referrer
        // (as long as referrer is not the same url as current page).
        this.appSharedStateService.sessionData = new SessionData({
            headerUri: null,
            footerUri: null,
            returnUri: document.referrer !== window.location.href ? document.referrer : null,
            locale: null,
            email: null
        });

        // Set the header type to use built in.
        this.appSharedStateService.headerType = HeaderFooterType.BuiltIn;
        this.appSharedStateService.footerType = HeaderFooterType.BuiltIn;
    }

    
    // Handle session expiration.
    private handleSessionExpiration(intervalId: number) {
        // Countdown timer example: https://stackoverflow.com/questions/20618355/the-simplest-possible-javascript-countdown-timer

        // Don't display the time if it is below 0 seconds
        if(this.millisecondsToExpiration <= 0){
            return;
        }

        // This method is called every second
        this.millisecondsToExpiration = this.millisecondsToExpiration - 1000;
        
        // TEST ONLY: reduce time to force pop-up messages (subtract 880 for 5:20 remaining, or 1180 for :20 remaining)
        // this.millisecondsToExpiration = this.millisecondsToExpiration - 880;

        const minutes: number = (this.millisecondsToExpiration / 1000) / 60 | 0;
        const seconds: number = (this.millisecondsToExpiration / 1000) % 60 | 0;
        this.displayExpiryDate = minutes.toString()  + ':' + (seconds < 10 ? '0' + seconds.toString() : seconds.toString());

        // 5 minute warning to user
        if (this.millisecondsToExpiration <= 300000 && !this.sessionWarningShown) {
            this.sessionWarningShown = true;
            const messageData: MessageData = {
                message: this.lms.get('SESSION_TIMEOUT_WARNING'),
                alertType: AlertType.Information
            };
            this.appSharedUtilityService.displayMessage(messageData);
        }

        // Display and reroute once session expires
        if (this.millisecondsToExpiration <= 0 && !this.sessionNoticeShown) {
            clearInterval(intervalId);
            this.sessionNoticeShown = true;
            this.displayExpiryDate = null;
            const messageData: MessageData = {
                message: this.lms.get('SESSION_TIMEOUT_NOTICE'),
                alertType: AlertType.Error,
                closedCallback: () => {
                    // Navigate back to the return uri.
                    this.appSharedUtilityService.navigateToReturnUri();
                }
            };
            this.appSharedUtilityService.displayMessage(messageData);
        }
    }

    // Check if the header/footer url comes from one of the known MPC/NVA domains, or POP mock portal.
    private checkIfHeaderFooterIsMpcNvaOrPopMockPortal(url: string): boolean {
        if (!url) {
            return false;
        }

        url = url.toLowerCase();

        if (url.indexOf('paymentcentral.microsoft.com' /* Production */) > -1 ||
            url.indexOf('dev30epportal.cloudapp.net' /* DEV30 */) > -1 ||
            url.indexOf('uatepportal.cloudapp.net' /* UAT */) > -1 ||
            url.indexOf('devepportal.cloudapp.net' /* DIT */) > -1 ||
            url.indexOf('testepportal.cloudapp.net' /* SIT */) > -1 ||
            url.indexOf('localhost:8082' /* POP mock portal default header/footer */) > -1) {
            return true;
        }

        return false;
    }
}

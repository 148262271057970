import { Section } from 'app/common/metadata-models/section';

// Mock data used for development and testing.
export class MockSections {
    public sections_Supplier: Section[] = [
        new Section({
            id: 'BankProfileDetails',
            sectionName: 'Details',
            isSaved: true,
            alwaysSave: false
        }),
        new Section({
            id: 'BankAccountDetails',
            sectionName: 'Bank account',
            isSaved: false,
            alwaysSave: false
        })
    ];

    public sections_Partner: Section[] = [
        new Section({
            id: 'BankProfileDetails',
            sectionName: 'Details',
            isSaved: true,
            alwaysSave: false
        }),
        new Section({
            id: 'BankAccountDetails',
            sectionName: 'Bank account',
            isSaved: false,
            alwaysSave: false
        }),
        new Section({
            id: 'BeneficiaryDetails',
            sectionName: 'Beneficiary',
            isSaved: false,
            alwaysSave: true
        })
    ];
}

<!-- Wait for pageMetadataState to be set prior to processing html. -->
<div *ngIf="!!pageMetadataState">
    <!-- Accessibility use: hidden div to receive htmlAlert component content. This element must be in the page dom when loaded in order for the
    announcement of text to work. -->
    <div id="alertAnnounce" role="alert" class="alertAnnounce"></div>

    <div *ngFor="let displaySection of pageMetadataState.displaySections; let sectionIndex = index;" id="section_{{displaySection.id}}">
        <div class="container">

            <!-- Section header: Includes assignment of section name and section status. -->
            <div class="row" [ngClass]="{'section-enabled': sectionIndex === pageMetadataState.activeSectionIndex, 'section-disabled': sectionIndex !== pageMetadataState.activeSectionIndex}">
                <div class="col" role="heading" aria-level="7">
                    <h2>{{displaySection.sectionName}}</h2>
                </div>
                <div class="col-sm-3" [ngSwitch]="pageMetadataState.getSectionStatus(displaySection)">
                    <img *ngSwitchCase="SectionStatusRef.InProgress" [src]="sectionStatusImage.inProgress" alt="{{lms.get('IN_PROGRESS')}}" />
                    <img *ngSwitchCase="SectionStatusRef.InputPending" [src]="sectionStatusImage.inputPending" alt="{{lms.get('INPUT_PENDING')}}" />
                    <img *ngSwitchCase="SectionStatusRef.Complete" [src]="sectionStatusImage.complete" alt="{{lms.get('COMPLETE')}}" />
                    <span *ngSwitchCase="SectionStatusRef.InProgress" class="section-status">{{lms.get('IN_PROGRESS')}}</span>
                    <span *ngSwitchCase="SectionStatusRef.InputPending" class="section-status">{{lms.get('INPUT_PENDING')}}</span>
                    <span *ngSwitchCase="SectionStatusRef.Complete" class="section-status">{{lms.get('COMPLETE')}}</span>
                </div>
            </div>

            <form #sectionForm="ngForm" role="form" [attr.aria-label]="displaySection.sectionName">
                <!-- Section content: Input controls assigned by section objects. -->
                <div class="collapse" [ngClass]="{'show': sectionIndex === pageMetadataState.activeSectionIndex}">
                    <!-- Busy / loading spinner for each section loading. -->
                    <div class="row container-input" *ngIf="!displaySection.checkSectionFieldsLoaded(false)">
                        <div class="col" style="text-align:center;">
                            <span>{{lms.get('LOADING_ELLIPSIS')}}</span>
                            <span class='fas fa-spinner fa-pulse' aria-hidden='true'></span>
                        </div>
                    </div>

                    <!-- Displayed if no section data found. -->
                    <div class="row container-input" *ngIf="displaySection.noDataFound">
                        <div class="col" style="text-align:center;">
                            <i class="fas fa-exclamation-circle" style='color:red;margin-right:5px;'></i>
                            <span>{{lms.get('NO_DATA_FOUND')}}</span>
                        </div>
                    </div>

                    <!-- Note on use of [ngStyle] on line below, use this instead of using *ngIf. Reason is we need the controls to render and -->
                    <!-- have the code in their ngOnInit methods to run. If using *ngIf and it is false, then the controls don't even start to render. -->
                    <!-- Needed especially because controls like dropdown may have dynamic dropdown values that load, and will influence the value -->
                    <!-- of the displaySection.checkSectionFieldsLoaded function (note that function calls checkIfAllDynamicDataLoaded). -->
                    <div *ngFor="let dispRow of displaySection.displayRows" class="row container-input" [ngStyle]="{'display': displaySection.checkSectionFieldsLoaded(false) && rowContainsVisibleFields(dispRow) ? 'flex' : 'none'}">
                        <div *ngFor="let dispCol of dispRow.displayColumns" class="col">
                            <div *ngFor="let field of dispCol.fields">
                                <div *ngIf="debugDisplayFieldJson">{{displayFieldJson(field)}}</div>
                                <div [ngSwitch]="field.metadata.control" [style.padding-left]='(!!field.metadata.indentHint?field.metadata.indentHint*20:0)+"px"' [ngStyle]="{'display': !(field.metadata.visible===false) ? 'block' : 'none'}">
                                    <app-textbox
                                        *ngSwitchCase="ControlTypeRef.textbox"
                                        name="{{field.id}}"
                                        [fieldData]="field"
                                        [form]="sectionForm"
                                        [pageMetadataState]="pageMetadataState"
                                        [(ngModel)]="field.fieldValue"
                                        [required]="!!field.metadata.required ? field.metadata.required : false"
                                        [pattern]="!!field.metadata.controlOptions.regExPattern ? field.metadata.controlOptions.regExPattern : ''"
                                        appInputMustEqualValidator="{{field.metadata.controlOptions.mustEqualFieldId}}">
                                    </app-textbox>
                                    <!-- Regarding required, pattern, appInputMustEqualValidator - see comments in textbox.component.html. -->
                                    <app-dropdown
                                        *ngSwitchCase="ControlTypeRef.dropdown"
                                        name="{{field.id}}"
                                        [fieldData]="field"
                                        [form]="sectionForm"
                                        [pageMetadataState]="pageMetadataState"
                                        [(ngModel)]="field.fieldValue"
                                        [required]="!!field.metadata.required ? field.metadata.required : false">
                                    </app-dropdown>
                                    <app-label
                                        *ngSwitchCase="ControlTypeRef.label"
                                        [fieldData]="field"
                                        [form]="sectionForm"
                                        [pageMetadataState]="pageMetadataState">
                                    </app-label>
                                    <app-html
                                        *ngSwitchCase="ControlTypeRef.html"
                                        [fieldData]="field"
                                        [form]="sectionForm"
                                        [pageMetadataState]="pageMetadataState">
                                    </app-html>
                                    <app-html-alert
                                        *ngSwitchCase="ControlTypeRef.htmlalert"
                                        [fieldData]="field"
                                        [form]="sectionForm"
                                        [pageMetadataState]="pageMetadataState">
                                    </app-html-alert>
                                    <app-html-modal
                                        *ngSwitchCase="ControlTypeRef.htmlmodal"
                                        [fieldData]="field"
                                        [form]="sectionForm"
                                        [pageMetadataState]="pageMetadataState">
                                    </app-html-modal>
                                    <app-checkbox
                                        *ngSwitchCase="ControlTypeRef.checkbox"
                                        name="{{field.id}}"
                                        [fieldData]="field"
                                        [form]="sectionForm"
                                        [(ngModel)]="field.fieldValue"
                                        [pageMetadataState]="pageMetadataState">
                                    </app-checkbox>
                                    <app-radiobutton
                                        *ngSwitchCase="ControlTypeRef.radiobutton"
                                        name="{{field.id}}"
                                        [fieldData]="field"
                                        [form]="sectionForm"
                                        [(ngModel)]="field.fieldValue"
                                        [pageMetadataState]="pageMetadataState">
                                    </app-radiobutton>
                                    <app-upload
                                        *ngSwitchCase="ControlTypeRef.upload"
                                        name="{{field.id}}"
                                        [fieldData]="field"
                                        [form]="sectionForm"
                                        [(ngModel)]="field.fieldValue"
                                        [pageMetadataState]="pageMetadataState">
                                    </app-upload>
                                    <app-iframe
                                        *ngSwitchCase="ControlTypeRef.iframe"
                                        [fieldData]="field"
                                        [form]="sectionForm"
                                        [pageMetadataState]="pageMetadataState">
                                    </app-iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Section navigation buttons: Conditionally displayed based on numerical order of section. -->
                <div class="row section-nav-buttons collapse" [ngClass]="{'show': sectionIndex === pageMetadataState.activeSectionIndex}">
                    <div class="col">
                        <button kendoButton
                            id="nextButton{{sectionIndex}}"
                            attr.data-tracking="{{instrumentationService.trackedElements.nextButton}}"
                            attr.data-trackinginfo="{{pageMetadataState.displaySections[sectionIndex].sectionName}}"
                            *ngIf="pageMetadataState.activeSectionIndex < pageMetadataState.displaySections.length - 1"
                            tabindex="0"
                            class="btn-next"
                            [primary]="true"
                            (click)="onClickNext(sectionIndex)"
                            [attr.aria-label]="lms.get('NEXT')"
                            [disabled]="!sectionForm.valid || !displaySection.checkSectionFieldsLoaded(true) || isSectionSaving(pageMetadataState.activeSectionIndex)">
                            <div *ngIf="!isSectionSaving(pageMetadataState.activeSectionIndex)">
                                <span>{{lms.get('NEXT')}}</span>
                            </div>
                            <div *ngIf="isSectionSaving(pageMetadataState.activeSectionIndex)">
                                <span class='section-button'>{{lms.get('SAVING')}}</span>
                                <span class='fas fa-spinner fa-pulse' aria-hidden='true'></span>
                            </div>
                        </button>
                        <button kendoButton
                            id="finishButton{{sectionIndex}}"
                            attr.data-tracking="{{instrumentationService.trackedElements.finishButton}}"
                            attr.data-trackinginfo="{{pageMetadataState.displaySections[sectionIndex].sectionName}}"
                            *ngIf="pageMetadataState.activeSectionIndex == pageMetadataState.displaySections.length - 1"
                            tabindex="0"
                            [primary]="true"
                            class="btn-finish"
                            (click)="onClickFinish(sectionIndex)"
                            [attr.aria-label]="lms.get('FINISH')"
                            [disabled]="!sectionForm.valid || !displaySection.checkSectionFieldsLoaded(true) || isSectionSaving(pageMetadataState.activeSectionIndex)">
                            <div *ngIf="!isSectionSaving(pageMetadataState.activeSectionIndex)">
                                <span>{{lms.get('FINISH')}}</span>
                            </div>
                            <div *ngIf="isSectionSaving(pageMetadataState.activeSectionIndex)">
                                <span class='section-button'>{{lms.get('SAVING')}}</span>
                                <span class='fas fa-spinner fa-pulse' aria-hidden='true'></span>
                            </div>
                        </button>
                        <button kendoButton
                            id="backButton{{sectionIndex}}"
                            attr.data-tracking="{{instrumentationService.trackedElements.backButton}}"
                            attr.data-trackinginfo="{{pageMetadataState.displaySections[sectionIndex].sectionName}}"
                            *ngIf="pageMetadataState.activeSectionIndex > 0"
                            type="button"
                            tabindex="0"
                            class="btn-back"
                            (click)="onClickBack()"
                            [attr.aria-label]="lms.get('BACK')"
                            [disabled]="!displaySection.checkSectionFieldsLoaded(true) || isSectionSaving(pageMetadataState.activeSectionIndex)">
                            <div>
                                <span>{{lms.get('BACK')}}</span>
                            </div>
                        </button>

                        <!-- Set the following ngIf to true to see form validity (for debug purposes only). -->
                        <div *ngIf="false">Form validity - status: {{sectionForm.status}}, valid: {{sectionForm.valid}}</div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

import { Directive, Input, AfterViewInit } from '@angular/core';
import { NG_VALIDATORS, FormControl, Validator, ValidationErrors } from '@angular/forms';
import { FieldWatcherService } from 'app/common/services/field-watcher.service';
import { SectionField } from 'app/common/metadata-models/sectionField';

export interface InputMustEqualErrorState {
    valid: boolean;
    mustEqualFieldId: string;
}

export interface InputMustEqualValidatorError {
    inputMustEqualValidator: InputMustEqualErrorState;
}

@Directive({
    selector: '[appInputMustEqualValidator]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: InputMustEqualValidatorDirective,
            multi: true
        }
    ]
})
export class InputMustEqualValidatorDirective implements Validator, AfterViewInit {
    // The input name appInputMustEqualValidator must equal the name of the directive selector.
    // Example usage from textbox.component.html: appInputMustEqualValidator="{{fieldData.metadata.controlOptions.mustEqualFieldId}}"
    @Input('appInputMustEqualValidator') mustEqualFieldId: string;
    private formControlInstance: FormControl;

    // Constructor for InputMustEqualValidatorDirective.
    constructor(private fieldWatcherService: FieldWatcherService) {
    }

    // Checks if field values are equal.
    private checkIfFieldsEqual(): boolean {
        // If formControlInstance not yet defined then just return true.
        if (!this.formControlInstance) {
            return true;
        }

        const mustEqualInputElem: HTMLInputElement = <HTMLInputElement>$(`#${this.mustEqualFieldId}`)[0];
        if (mustEqualInputElem.value !== this.formControlInstance.value) {
            return false;
        } else {
            return true;
        }
    }

    // Get the input must equal validator error.
    private get inputMustEqualValidatorError(): InputMustEqualValidatorError {
        return {
            inputMustEqualValidator: {
                valid: false,
                mustEqualFieldId: this.mustEqualFieldId
            }
        };
    }

    // Part of Validator interface.
    // This method allows us to do custom validation above and beyond the built in validation (which includes regex and maxlength checks).
    // The custom validation we do here is to handle when inputs must equal each other.
    validate(formControl: FormControl): ValidationErrors | null {
        // Capture the form control instance here, the first time validate gets called.
        if (!this.formControlInstance) {
            this.formControlInstance = formControl;
        }

        if (!!this.mustEqualFieldId) {
            if (this.checkIfFieldsEqual()) {
                return null;
            } else {
                // ValidationErrors object returned from this validator in the event of validation errors.
                const validationErrors: ValidationErrors = this.inputMustEqualValidatorError;
                return validationErrors;
            }
        }

        return null;
    }

    // Angular AfterViewInit handler. Part of the lifecycle hooks: https://angular.io/guide/lifecycle-hooks
    ngAfterViewInit(): void {
        setTimeout(() => {
            if (!!this.mustEqualFieldId) {
                // Set up a field watcher to watch the mustEqualFieldId control. When it changes this callback
                // will run which will set or clear errors on this control instance based on field equality.
                this.fieldWatcherService.addFieldWatcher(
                    this.mustEqualFieldId,
                    (changedField: SectionField) => {
                        if (!!this.formControlInstance) {
                            if (this.checkIfFieldsEqual()) {
                                this.formControlInstance.setErrors(null);
                            } else {
                                const validationErrors: ValidationErrors = this.inputMustEqualValidatorError;
                                this.formControlInstance.setErrors(validationErrors);
                            }
                        }
                    }
                );
            }
        });
    }
}

import { IsSchemaCompliant } from 'app/common/interfaces/isSchemaCompliant';
import { MiscUtil } from 'app/common/utility/miscUtil';

// DropdownOption models data returned from the API.
// *** Shape should match exactly what the API returns. ***
export class DropdownOption implements IsSchemaCompliant {
    public text: string;
    public value: number|string|boolean;

    // Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
    constructor(jsonData: any) {
        this.text = jsonData.text;
        this.value = jsonData.value;
    }

    // User defined type guard to verify data is a valid DropdownOption.
    public isSchemaCompliant(): boolean {
        let isCompliant: boolean = true;

        // Make sure all non-nullable properties are present.
        isCompliant = !!this.text && !MiscUtil.isNullOrUndefined(this.value);
        if (!isCompliant) {
            console.error('JSON is non-compliant - dropDownOption required properties not found.');

            // Even though the data is not schema compliant (and we do want an error to show in this case)...
            // Supply default values for the missing fields just so other code does not break.
            // The Kendo DropDownList, for example, will break if there is a missing text or value
            // when it tries to data bind.
            if (MiscUtil.isNullOrUndefined(this.text) && MiscUtil.isNullOrUndefined(this.value)) {
                this.text = 'ERROR - MISSING TEXT AND VALUE';
                this.value = 'MISSING';
            } else {
                if (MiscUtil.isNullOrUndefined(this.text)) {
                    this.text = 'ERROR - MISSING TEXT';
                }
                if (MiscUtil.isNullOrUndefined(this.value)) {
                    this.value = 'MISSING';
                    this.text = this.text + ' ERROR - MISSING VALUE';
                }
            }
        }

        return isCompliant;
    }
}

<div *ngIf="!!fieldData">
    <label for="{{fieldData.id}}" class="control-label">
        <span [innerHTML]="htmlTransform(fieldData.metadata.label)"></span>
        <span *ngIf="fieldData.metadata.required" class="required">*</span>
        <img *ngIf="!!fieldData.metadata.fieldInfo" class="tip-icon" src="assets/common/info-icon.svg" placement="right"
            [ngbTooltip]="tipContent" alt="{{fieldData.metadata.fieldInfo}}" tabindex="0" />
    </label>

    <!-- Tooltip content used with above icon. Note this same block of html exists on other control(s) like textbox. -->
    <ng-template #tipContent>
        <div>
            <div *ngIf="!!fieldData.metadata.fieldInfo && fieldData.metadata.fieldInfo.length > 0">
                {{fieldData.metadata.fieldInfo}}</div>
        </div>
    </ng-template>

    <br />
    <div class="control-container">
        <div class="control-spinner-wrapper">
            <!-- http://www.telerik.com/kendo-angular-ui/components/dropdowns/dropdownlist/ -->
            <kendo-dropdownlist #dropDownList [ngStyle]="{'display':
                    (
                        !!fieldData.metadata.controlOptions.dropdownOptionsStatic ||
                        (!!fieldData.metadata.controlOptions.dropdownOptionsDynamic && !!dynamicDrivenByField && (dynamicDrivenByField.fieldValue !== undefined && dynamicDrivenByField.fieldValue !== null && dynamicDrivenByField.fieldValue !== ''))
                    )}" id="{{fieldData.id}}" name="{{fieldData.id}}" class="form-control control-dropdown"
                [data]="dropdownData" [defaultItem]="nothingSelectedText" [textField]="'text'" [valueField]="'value'"
                [valuePrimitive]="true" [attr.aria-label]="fieldData.metadata.ariaLabel + fieldData.metadata.fieldInfo"
                [disabled]="!!fieldData.metadata.readOnly" [required]="!!fieldData.metadata.required"
                [(ngModel)]="fieldData.fieldValue" (close)="onClose($event)" (valueChange)="onValueChange($event)"
                aria-live="polite">
                <ng-template kendoDropDownListItemTemplate let-dataItem>
                    <div>
                        <span class="dropdown-list">{{ dataItem.text }}</span>
                    </div>
                </ng-template>

                <!-- todo: [popupSettings]="{ height: 400, width: 300 }" was causing popup to appear in the wrong place.
             Likely a bug in latest Kendo DropdownList. Try adding it back in the future after upgrading Kendo controls to latest.
             https://www.telerik.com/kendo-angular-ui/components/popup/api/PopupSettings
        -->
                <!-- Regarding:
            [ngStyle] - use this instead of *ngIf here, and below, as we need the control created, just potentially not displayed if condition matches.
            aria-live="assertive" - This is needed to make screen readers (JAWS) read the text when binding to complex JSON data.
            See: https://stackoverflow.com/questions/24299086/jaws-not-reading-select-box-value-when-changing-options-using-keyboard-down-arro#_=_
                http://www.freedomscientific.com/Training/Surfs-Up/AriaLiveRegions.htm
        -->
            </kendo-dropdownlist>
            <div class="spinner-container"
                *ngIf="!!fieldData.metadata.controlOptions.dropdownOptionsStatic ||
                                                  (!!fieldData.metadata.controlOptions.dropdownOptionsDynamic && !!dynamicDrivenByField && (dynamicDrivenByField.fieldValue !== undefined && dynamicDrivenByField.fieldValue !== null && dynamicDrivenByField.fieldValue !== ''))">
                <div
                    *ngIf="(!!dropdownOptionsDynamic && dropdownOptionsDynamic.isLoadingOrReloading) || (!!fieldData.childFieldsDynamic && fieldData.childFieldsDynamic.isReloading) || (!!fieldData.sectionsDynamic && fieldData.sectionsDynamic.isReloading)">
                    <span class='fas fa-spinner fa-pulse' aria-hidden='true'></span>
                </div>
            </div>
        </div>

        <div class="dynamic-dropdown-drivenby-indicator" [ngStyle]="{'display':
                (
                    !!fieldData.metadata.controlOptions.dropdownOptionsDynamic && !!dynamicDrivenByField && (dynamicDrivenByField.fieldValue === undefined || dynamicDrivenByField.fieldValue === null || dynamicDrivenByField.fieldValue === '')
                ) ? 'inline-block' : 'none'}">
            {{lms.get('PLEASE_SELECT')}} {{!!dynamicDrivenByField ? dynamicDrivenByField.metadata.label : ''}}
        </div>
    </div>
</div>

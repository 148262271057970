import * as $ from 'jquery';
import { Component, OnInit, ChangeDetectorRef, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { LocalizationManagerService } from 'app/common/services/localization-manager.service';
import { AppSharedUtilityService } from 'app/common/services/app-shared-utility.service';
import { AppSharedStateService } from 'app/common/services/app-shared-state.service';
import { AlertType, alertTypeFromString } from 'app/common/components/message-display/alert-type';
import { InstrumentationService } from 'app/common/services/instrumentation.service';
import { BaseControl } from 'app/common/components/controls/base-control';
import { FieldWatcherService } from 'app/common/services/field-watcher.service';
import { MetadataApiService } from 'app/common/services/metadata-api.service';
import { SectionField } from 'app/common/metadata-models/sectionField';
import { MiscUtil } from 'app/common/utility/miscUtil';
import { AppConstants } from 'app/common/utility/appConstants';

@Component({
    selector: 'app-html-modal',
    templateUrl: './htmlModal.component.html',
    styleUrls: ['./htmlModal.component.css'],
    providers: [
        {
            // This provider not really needed on this non user input control, but here for consistency
            // and because ControlValueAccessor is implemented in BaseControl.
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => HtmlModalComponent),
            multi: true
        }
    ]
})

export class HtmlModalComponent extends BaseControl implements OnInit {
    // Set refereces to display type so they can be bound in html. 
    public display = 'none';

    // Set references to enum types so they can be data bound in html.
    public alertType = AlertType.Information;

    // Set references to enum types so they can be data bound in html.
    public AlertTypeRef: typeof AlertType = AlertType;

    // MessageDisplayComponent constructor.
    constructor(
        public instrumentationService: InstrumentationService,
        public lms: LocalizationManagerService, /* Using short name on purpose for data binding. */
        protected fieldWatcherService: FieldWatcherService,
        protected metadataApiService: MetadataApiService,
        protected changeDetectorRef: ChangeDetectorRef,
        protected domSanitizer: DomSanitizer,
        protected appSharedStateService: AppSharedStateService,
        protected appSharedUtilityService: AppSharedUtilityService) {

        super(fieldWatcherService, metadataApiService, changeDetectorRef, domSanitizer, appSharedStateService, appSharedUtilityService, lms);
    }

    public openModal() {
        this.display = 'block';
        this.alertType = alertTypeFromString(this.fieldData.metadata.controlOptions.modalAlertType);
    }
    

    public onCloseClicked() {
        this.display = 'none';
    }

    // Angular OnInit handler. Part of the lifecycle hooks: https://angular.io/guide/lifecycle-hooks
    public ngOnInit(): void {
    }

    // Angular AfterViewInit handler. Part of the lifecycle hooks: https://angular.io/guide/lifecycle-hooks
    public ngAfterViewInit(): void {
        setTimeout(() => {
            if (!!this.fieldData.metadata.controlOptions.mustEqualFieldId) {
                // Set up a field watcher to watch the mustEqualFieldId control. When it changes this callback
                // will run which will set or clear errors on this control instance based on field equality.
                this.fieldWatcherService.addFieldWatcher(
                    this.fieldData.metadata.controlOptions.mustEqualFieldId,
                    (changedField: SectionField) => {
                        if (this.checkIfFieldsEqual()) {
                            this.showMustEqualModal();
                        }
                    }
                );
                this.fieldData.isControlReady = true;
            }
        });
    }

    // Transform the html string and bypass sanitization. This is for controls that support html display in the label or value.
    public htmlTransform(htmlString): SafeHtml {
        return this.domSanitizer.bypassSecurityTrustHtml(htmlString);
    }

    private checkIfFieldsEqual(): boolean {
        const mustEqualField: SectionField = this.pageMetadataState.findFieldRecursive(this.fieldData.metadata.controlOptions.mustEqualFieldId);

        if (!MiscUtil.isNullOrUndefinedOrEmptyString(mustEqualField.metadata.controlOptions.mustEqualFieldId)) {
            const mustEqualFieldParent: SectionField = this.pageMetadataState.findFieldRecursive(mustEqualField.metadata.controlOptions.mustEqualFieldId);
            const mustEqualInputElem: HTMLInputElement = <HTMLInputElement>$(`#${mustEqualField.id}`)[0];
            const mustEqualParentInputElem: HTMLInputElement = <HTMLInputElement>$(`#${mustEqualFieldParent.id}`)[0];
            if (mustEqualInputElem.value === mustEqualParentInputElem.value) {
                return true;
            }
        } else {
            if (mustEqualField.fieldValue === AppConstants.PaymentMethod_Check) {
                return true;
            }
        }
        return false;
    }

    // This method determines if a modal should be shown if the inputMustEqualValidator in the textbox control folder
    // validates that the two fields - mustEqualField and mustEqualFieldParent - input values equal eachother.
    public showMustEqualModal(): boolean {
        const mustEqualFieldId: string = this.fieldData.metadata.controlOptions.mustEqualFieldId;
        if (!MiscUtil.isNullOrUndefinedOrEmptyString(mustEqualFieldId)) {
            const mustEqualField: SectionField = this.pageMetadataState.findFieldRecursive(mustEqualFieldId);
            if (!MiscUtil.isNullOrUndefinedOrEmptyString(mustEqualField.metadata.controlOptions.mustEqualFieldId)) {
                const mustEqualFieldParent: SectionField = this.pageMetadataState.findFieldRecursive(mustEqualField.metadata.controlOptions.mustEqualFieldId);
                if (!MiscUtil.isNullOrUndefinedOrEmptyString(mustEqualField.fieldValue) && !MiscUtil.isNullOrUndefinedOrEmptyString(mustEqualFieldParent.fieldValue)) { // Only if both fields are not empty.
                    this.openModal();
                    return true;
                }
            } else {
                if (mustEqualField.fieldValue === AppConstants.PaymentMethod_Check) {
                    this.openModal();
                    return true;
                }
            }
        }
        return false;
    }
}

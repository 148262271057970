import * as $ from 'jquery';
import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { environment } from 'environments/environment';
import { LocalizationManagerService } from 'app/common/services/localization-manager.service';
import { InstrumentationService } from 'app/common/services/instrumentation.service';
import { AppSharedStateService } from 'app/common/services/app-shared-state.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
    public displayDevTools: boolean = false;

    // Constructor for AppComponent.
    constructor(
        private instrumentationService: InstrumentationService,
        private appSharedStateService: AppSharedStateService,
        public lms: LocalizationManagerService /* Using short name on purpose for data binding. */) {
        if (environment.displayDevTools) {
            this.displayDevTools = true;
        }
    }

    // Angular OnInit handler. Part of the lifecycle hooks: https://angular.io/guide/lifecycle-hooks
    public ngOnInit(): void {
        // Hide the page loading indicator in the index.html.
        $('#page-loading-indicator').hide();

    }

    // Angular OnAfterInit handler. Part of the lifecycle hooks: https://angular.io/guide/lifecycle-hooks
    public ngAfterViewInit(): void {
        setTimeout(() => {
            this.instrumentationService.registerElementEventHandlers(this.appSharedStateService.referenceId, this.appSharedStateService.userKey);
        });
    }
}

import { Component, ElementRef, ViewChild, Input, AfterViewInit } from '@angular/core';

// From: https://stackoverflow.com/questions/42458346/need-to-insert-script-tag-in-angular-2

@Component({
    selector: 'app-script-inject',
    templateUrl: './script-inject.component.html'
})
export class ScriptInjectComponent implements AfterViewInit {
    @Input() src: string;
    @Input() type: string;
    @ViewChild('script', { static: true }) script: ElementRef;

    // Angular AfterViewInit handler. Part of the lifecycle hooks: https://angular.io/guide/lifecycle-hooks
    public ngAfterViewInit(): void {
        setTimeout(() => {
            this.convertToScript();
        });
    }

    // Create and inject script element.
    private convertToScript(): void {
        const element = this.script.nativeElement;
        const script = document.createElement('script');
        script.type = this.type ? this.type : 'text/javascript';
        if (this.src) {
            script.src = this.src;
        }
        if (element.innerHTML) {
            script.innerHTML = element.innerHTML;
        }
        const parent = element.parentElement;
        parent.parentElement.replaceChild(script, parent);
    }
}

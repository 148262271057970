import { ChildFieldsDynamic } from './childFieldsDynamic';
import { SectionsDynamic } from './sectionsDynamic';
import { FieldMetadata } from './fieldMetadata';
import { IsSchemaCompliant } from 'app/common/interfaces/isSchemaCompliant';

// Interface representing minimal section field properties. Used for save purposes.
export interface MinSectionField {
    id: string;
    fieldValue: number|string|boolean;
    fieldValueInternal?: string;
    childFields?: MinSectionField[];
}

// SectionField models data returned from the API.
// *** Shape should match exactly what the API returns. ***
export class SectionField implements MinSectionField, IsSchemaCompliant {
    public id: string;
    public fieldValue: number|string|boolean;
    public fieldValueInternal?: string; // Internally used field. Not for display. Services can use for data in transit between server and client and back.
    public metadata?: FieldMetadata; // Nullable, as we leave this null during save, no need to send this back.
    public childFieldsDynamic?: ChildFieldsDynamic;
    public sectionsDynamic?: SectionsDynamic;
    public childFields?: SectionField[];

    // Not part of data returned from API.
    public isControlReady: boolean = false;

    // Callback function to be called when child fields collection was modified.
    public childFieldsModifiedCallback: () => void;

    // Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
    constructor(jsonData: any) {
        this.id = jsonData.id;
        this.fieldValue = jsonData.fieldValue;
        this.fieldValueInternal = jsonData.fieldValueInternal;
        this.metadata = !!jsonData.metadata ? new FieldMetadata(jsonData.metadata) : undefined;
        this.childFieldsDynamic = !!jsonData.childFieldsDynamic ? new ChildFieldsDynamic(jsonData.childFieldsDynamic) : undefined;
        this.sectionsDynamic = !!jsonData.sectionsDynamic ? new SectionsDynamic(jsonData.sectionsDynamic) : undefined;
        this.childFields = !!jsonData.childFields ? [] : undefined;
        if (!!jsonData.childFields) {
            for (let i: number = 0; i < jsonData.childFields.length; i++) {
                this.childFields.push(new SectionField(jsonData.childFields[i]));
            }
        }
    }

    // Notify that child fields collection got modified. Calls the childFieldsModifiedCallback.
    public notifyChildFieldsModified(): void {
        if (!!this.childFieldsModifiedCallback) {
            this.childFieldsModifiedCallback();
        }
    }

    // User defined type guard to verify data is a valid SectionField.
    public isSchemaCompliant(): boolean {
        let isCompliant: boolean = true;

        // Make sure all non-nullable properties are present.
        // Do not do a check on fieldValue as it is legal for it to be null, undefined, or empty if there is no value yet.
        // metadata is required during during load, but not required during save (which is why it is nullable).
        // This schema compliant check is only used during load.
        isCompliant = !!this.id && !!this.metadata;
        if (!isCompliant) {
            console.error('JSON is non-compliant - SectionField required properties not found.');
        }

        if (isCompliant) {
            isCompliant = this.metadata.isSchemaCompliant();
        }

        // Call isSchemaCompliant on child objects.
        if (isCompliant && !!this.childFieldsDynamic) {
            isCompliant = this.childFieldsDynamic.isSchemaCompliant();
        }
        if (isCompliant && !!this.sectionsDynamic) {
            isCompliant = this.sectionsDynamic.isSchemaCompliant();
        }

        if (isCompliant && !!this.childFields) {
            for (let i = 0; i < this.childFields.length; i++) {
                isCompliant = this.childFields[i].isSchemaCompliant();
                if (!isCompliant) {
                    break;
                }
            }
        }

        return isCompliant;
    }

    // Return a minimal object from this field data for save purposes. No need to send all the properties
    // over the wire.
    public minForSave(): MinSectionField {
        return {
            id: this.id,
            fieldValue: this.fieldValue,
            fieldValueInternal: this.fieldValueInternal,
            childFields: null
        };
    }
}

<div class="control-group" *ngIf="!!fieldData">
    <div class="modal" tabindex="-1" role="alertdialog" data-backdrop="false" data-keyboard="true" [ngStyle]="{'display':display}" [attr.aria-label]="lms.get('ALERT_HEADING')" aria-describedby="alertMessage">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="alert alert-full-width" 
                    [ngClass]="{'alert-warning': alertType == AlertTypeRef.Warning, 'alert-info': alertType == AlertTypeRef.Information, 'alert-danger': alertType == AlertTypeRef.Error, 'alert-success': alertType == AlertTypeRef.Success}"
                    [ngSwitch]="alertType">
                        <strong *ngSwitchCase="AlertTypeRef.Warning">
                            {{lms.get('WARNING_HEADING')}}
                        </strong>
                        <strong *ngSwitchCase="AlertTypeRef.Information">
                            {{lms.get('INFORMATION_HEADING')}}
                        </strong>
                        <strong *ngSwitchCase="AlertTypeRef.Success">
                            {{lms.get('SUCCESS_HEADING')}}
                        </strong>
                        <strong *ngSwitchCase="AlertTypeRef.Error">
                            {{lms.get('ERROR_HEADING')}}
                        </strong>
                    </div>
                </div>
                <div class="modal-body">
                    <p id="alertMessageHtmlModal" [innerHTML]="htmlTransform(fieldData.metadata.label)"></p>
                </div>
                <div class="modal-footer">
                    <button 
                        id='htmlModalCloseButton' 
                        attr.data-tracking="{{instrumentationService.trackedElements.infoCloseButton}}"
                        attr.data-trackinginfo="Info dialog: {{fieldData.metadata.label}}"
                        type="button" 
                        class="btn btn-default" 
                        (click)="onCloseClicked()">{{lms.get('CLOSE')}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

import * as $ from 'jquery';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CustomErrorHandlerService, ErrorClassification, HandledError } from 'app/common/services/custom-error-handler.service';
import { MiscUtil } from 'app/common/utility/miscUtil';
import { environment } from 'environments/environment';
import { LocalizationManagerService } from 'app/common/services/localization-manager.service';
import { InstrumentationService } from 'app/common/services/instrumentation.service';
import { AppSharedStateService } from 'app/common/services/app-shared-state.service';

export interface FriendlyErrorMessageMap {
    // Must specify string index key here, index key cannot be an enum, but our ErrorClassification
    // enum is backed by strings so this works.
    [key: string]: string;
}

// Friendly error messages per error classification. They will be populated in localizeFriendlyErrorMessages().
export let friendlyErrorMessagesMap: FriendlyErrorMessageMap = {
    [ErrorClassification.Unauthorized]: '',
    [ErrorClassification.Forbidden]: '',
    [ErrorClassification.ResourceNotFound]: '',
    [ErrorClassification.JsonSchemaValidation]: '',
    [ErrorClassification.General]: ''
};

@Component({
    selector: 'app-error-display',
    templateUrl: './error-display.component.html',
    styleUrls: ['./error-display.component.css']
})

export class ErrorDisplayComponent implements OnInit {
    public customErrorHandlerService: CustomErrorHandlerService;
    public displayDetails: boolean = false; // Default this to false.
    public detailsExpanded: boolean = false;
    public display = 'none';

    // Gets prettified error JSON.
    public get errorJson(): string {
        return MiscUtil.prettifyJson(this.customErrorHandlerService.errors, true).replace(/\\n/g, '\x0d\x0a');
    }

    // Determine an appropriate friendly error message to display based on the error(s).
    public get friendlyErrorMsg(): string {
        this.localizeFriendlyErrorMessages();

        // Default the error message to the general error message.
        let errorMsg: string = friendlyErrorMessagesMap[ErrorClassification.General];
        // If there are more than one error then just use the first error's classification to determine the
        // friendly error message.
        if (this.customErrorHandlerService.errors.length > 0) {
            const errorClassification: ErrorClassification = this.customErrorHandlerService.errors[0].errorClassification;
            // Use the friendlyErrorMessagesMap exported from the custom-error-handler.service.ts.
            errorMsg = friendlyErrorMessagesMap[errorClassification];
        }
        return errorMsg;
    }

    // Get the correlationId from the error. If multiple errors just use the first correlationId found in the errors.
    // For web api calls... there may be cases where multiple api calls are outbound at one time, then all could fail
    // but each api call would have a different correlationId.
    public get correlationId(): string {
        for (let i: number = 0; i < this.customErrorHandlerService.errors.length; i++) {
            const handledError: HandledError = this.customErrorHandlerService.errors[i];
            if (!!handledError.correlationId) {
                return handledError.correlationId;
            }
        }
        return '';
    }

    // Get the reference id.
    public get referenceId(): string {
        return this.appSharedStateService.referenceId;
    }

    // ErrorDisplayComponent constructor.
    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private appSharedStateService: AppSharedStateService,
        public instrumentationService: InstrumentationService,
        public lms: LocalizationManagerService /* Using short name on purpose for data binding. */) {
        // Store the custom error handler service instance so it can be bound in the html.
        this.customErrorHandlerService = CustomErrorHandlerService.instance;

        // See comments in CustomErrorHandlerService for emitChangeNotification().
        this.customErrorHandlerService.changeDetectionEmitter.subscribe(() => {
            // See: https://stackoverflow.com/questions/34827334/triggering-angular2-change-detection-manually
            this.changeDetectorRef.detectChanges();
            this.openErrorModal();
        });

        // If not production, display details expander link.
        if (!environment.production) {
            this.displayDetails = true;
        }
    }

    // Open error popup.
    public openErrorModal() {
        setTimeout(() => {
            this.display = 'block';
            // Put below code on UI thread after display has been set to 'block'. It takes a moment for the
            // bootstrap UI to render the modal. Set focus to close button so user can press return to close it.
            setTimeout(() => {
                $('#errorDisplayCloseButton').focus();
            }, 0);
        }, 200);
    }

    // Close clicked handler.
    public onErrorCloseClicked() {
        this.display = 'none';
        this.customErrorHandlerService.clearErrors();
    }

    // Angular OnInit handler. Part of the lifecycle hooks: https://angular.io/guide/lifecycle-hooks
    public ngOnInit(): void {
    }

    // Set up localized strings for the friendly error messages.
    private localizeFriendlyErrorMessages(): void {
        friendlyErrorMessagesMap[ErrorClassification.Unauthorized] = this.lms.get('ERROR_MSG_UNAUTHORIZED');
        friendlyErrorMessagesMap[ErrorClassification.Forbidden] = this.lms.get('ERROR_MSG_FORBIDDEN');
        friendlyErrorMessagesMap[ErrorClassification.ResourceNotFound] = this.lms.get('ERROR_MSG_RESOURCE_NOT_FOUND');
        friendlyErrorMessagesMap[ErrorClassification.JsonSchemaValidation] = this.lms.get('ERROR_MSG_JSON_SCHEMA_VALIDATION');
        friendlyErrorMessagesMap[ErrorClassification.General] = this.lms.get('ERROR_MSG_GENERAL');
    }

    // Toggle error display expansion.
    public expandToggle(): void {
        this.detailsExpanded = !this.detailsExpanded;
        // This is a bit odd but the next line is needed. I think because this error display component initially appears due to how
        // the customErrorHandlerService.changeDetectionEmitter is used. Subsequent clicking on the expand toggle span seems to not
        // cause change detection on it's own. Unclear why. But forcing a change detection here fixes it.
        this.changeDetectorRef.detectChanges();
    }
}

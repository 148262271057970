<div class="container">
    <div class="row section-title-message">
        <div class="col">
            {{lms.get('LOGGED_OUT')}}
        </div>
    </div>
    
    <div class="row container-message img-responsive">
        <div class="col col-nopadding">
            <img class="img-responsive" src="assets/common/message_splash.jpg" />
        </div>
    </div>

    <div class="row section-title-footer">
        <div class="col">
        </div>
    </div>
</div>
    
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppSharedStateService } from 'app/common/services/app-shared-state.service';
import { PageMetadataState, IPageMetadataState } from 'app/common/utility/page-metadata-state';
import { MetadataRenderComponent } from 'app/common/components/metadata-render/metadata-render.component';
import { MetadataApiService, SupportedMicroservice } from 'app/common/services/metadata-api.service';
import { ComplianceMockDataProviderService, ComplianceMockData } from 'app/pages/compliance/mockData/complianceMockDataProvider.service';
import { MetadataPageWrapperComponent } from 'app/common/components/metadata-page-wrapper/metadata-page-wrapper.component';
import { environment } from 'environments/environment';
import { LocalizationManagerService } from 'app/common/services/localization-manager.service';
import { SessionManagerService } from 'app/common/services/session-manager.service';
import { BasePageComponent } from 'app/pages/base-page.component';

@Component({
    selector: 'app-compliance',
    templateUrl: './compliance.component.html',
    styleUrls: ['./compliance.component.css']
})
export class ComplianceComponent extends BasePageComponent implements IPageMetadataState, OnInit, OnDestroy {
    @ViewChild('metadataRender', { static: true }) public metadataRender: MetadataRenderComponent;
    @ViewChild('metadataPageWrapper', { static: true }) public metadataPageWrapper: MetadataPageWrapperComponent;
    public get heading(): string { return this.lms.get('COMPLIANCE'); }
    public pageMetadataState: PageMetadataState = new PageMetadataState();
    private navigationSubscription: Subscription;

    // Constructor for ComplianceComponent.
    constructor(
        private sessionManagerService: SessionManagerService,
        private appSharedStateService: AppSharedStateService,
        private metadataApiService: MetadataApiService,
        private complianceMockDataProviderService: ComplianceMockDataProviderService,
        private router: Router,
        public lms: LocalizationManagerService /* Using short name on purpose for data binding. */,
        location: Location) {
        super(location);

        // Configure the metadataApiService to use mock data for this page.
        if (environment.useLocalMockData) {
            // Default mock data set here. Can also use dev tools component to change mock data.
            this.complianceMockDataProviderService.changeMockData(ComplianceMockData.GeneralExample, false);
            this.metadataApiService.mockDataProvider = this.complianceMockDataProviderService;
        }

        // This page supports reloading if the same route is navigated to.
        // See: https://medium.com/engineering-on-the-incline/reloading-current-route-on-click-angular-5-1a1bfc740ab2
        this.navigationSubscription = this.router.events.subscribe((e: any) => {
            // If it is a NavigationEnd event re-initalise the component.
            if (e instanceof NavigationEnd) {
                // Configure service for compliance service. Do this in ngOnInit so it happens each time the user switches back to this page.
                this.metadataApiService.configureService(SupportedMicroservice.Compliance, environment.complianceServiceBaseUrl, 'api-version', '2017-08-24');
                // Run this after metadataApiService is configured.
                this.sessionManagerService.loadSessionDataIfNeeded(true);
                // Store the page metadata state object in appSharedStateService. For use by other components that need to see it like DevToolsComponent.
                this.appSharedStateService.activePageMetadataState = this.pageMetadataState;
                this.loadOrReload();
            }
        });

        // When localization changes, reload the component. This will cause load calls to the metadata apis to run again
        // but with a different Accept-Language header for the new selected language to get localized metadata (labels, etc).
        this.lms.localizationChangedEmitter.subscribe(() => {
            this.loadOrReload();
        });
    }

    // Angular OnInit handler. Part of the lifecycle hooks: https://angular.io/guide/lifecycle-hooks
    public ngOnInit(): void {
    }

    // Angular OnDestroy handler. Part of the lifecycle hooks: https://angular.io/guide/lifecycle-hooks
    public ngOnDestroy(): void {
        // Clear out the active page metadata state in appSharedStateService.
        this.appSharedStateService.activePageMetadataState = null;

        if (this.navigationSubscription) {
            this.navigationSubscription.unsubscribe();
        }
    }

    // Load or reload the page.
    public loadOrReload(): void {
        this.pageMetadataState.reset();
        this.metadataRender.reset();
        this.metadataRender.loadSections();
    }
}

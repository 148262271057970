import { Section } from 'app/common/metadata-models/section';
import { SectionField } from 'app/common/metadata-models/sectionField';
import { DisplaySection, SectionStatus } from 'app/common/metadata-models/displaySection';
import { ControlType } from 'app/common/metadata-models/controlType';
import { MiscUtil } from 'app/common/utility/miscUtil';

// To be implemented by metadata driven pages.
export interface IPageMetadataState {
    pageMetadataState: PageMetadataState;
}

interface SectionToBeAdded {
    section: Section;
    addIndex: number;
}

// Page metadata state object. This is intended to be created on each metadata driven page that uses it (ex: bank, tax).
// It exists as a standard class object and not an @Injectable() service (as those are singleton) and we want a seperate
// instance of this on each page. An instance of this object is also passed to controls as an input parameter (see BaseControl).
// This object is intended to hold any shared state and functionality needed by metadata driven UI's and any child components
// (like the metadata generated controls).
export class PageMetadataState {
    public sectionFieldsLoading: boolean = true;
    public displaySections: DisplaySection[] = [];
    public displaySectionsLoaded: boolean = false;

    // Used to track which section is currently active for editing. When page loads, this is set to first item
    // in section array by default.
    public activeSectionIndex: number = 0;

    // Used to determine which section, if any, is currently saving. Null if nothing is saving.
    public savingSectionIndex?: number = null;

    // Constructor for PageMetadataState
    constructor() {
    }

    // Reset the page metadata state.
    public reset(): void {
        this.sectionFieldsLoading = true;
        this.displaySections = [];
        this.activeSectionIndex = 0;
        this.savingSectionIndex = null;
    }

    // Get the active display section.
    public get activeSection(): DisplaySection {
        return this.displaySections[this.activeSectionIndex];
    }

    // Get the section id the fieldId is in (searches across all sections).
    public sectionIdForField(fieldId: string): string {
        for (let s = 0; s < this.displaySections.length; s++) {
            if (this.findFieldRecursive(fieldId, this.displaySections[s].sectionFields) !== null) {
                return this.displaySections[s].id;
            }
        }
        return null;
    }

    // Find a field given its id, recursively through children.
    public findFieldRecursive(fieldId: string, fields?: SectionField[], foundField: SectionField = null): SectionField {
        if (!!foundField) {
            return foundField;
        }

        if (!fields) {
            // If fields was not supplied then loop through each section and recurse fields in each.
            for (let i = 0; i < this.displaySections.length; i++) {
                foundField = this.findFieldRecursive(fieldId, this.displaySections[i].sectionFields, foundField);
                if (!!foundField) {
                    break;
                }
            }
        } else {
            for (let f = 0; f < fields.length; f++) {
                if (fields[f].id === fieldId) {
                    foundField = fields[f];
                    break;
                }
                if (foundField === null && !!fields[f].childFields) {
                    foundField = this.findFieldRecursive(fieldId, fields[f].childFields, foundField);
                    if (!!foundField) {
                        break;
                    }
                }
            }
        }

        return foundField;
    }

    // Run the callback once all controls are ready for the active section.
    // A control is considered ready once it is rendered and has registered field watchers.
    public runWhenControlsReadyForActiveSection(callback: (() => void)) {
        let isReady: boolean = true;

        if (!this.activeSection.sectionFields) {
            isReady = false;
        } else {
            for (let i: number = 0; i < this.activeSection.sectionFields.length; i++) {
                // If control is not ready.
                if (!this.activeSection.sectionFields[i].isControlReady) {
                    isReady = false;
                    break;
                }
            }
        }

        if (isReady) {
            callback();
        } else {
            setTimeout(() => {
                this.runWhenControlsReadyForActiveSection(callback);
            }, 100);
        }
    }

    // Find all fields for the control type in the active section.
    public findAllFieldsForControlTypeInActiveSection(controlType: ControlType) {
        const matchingSectionFields: SectionField[] = [];
        const activeSection = this.displaySections[this.activeSectionIndex];
        for (let r: number = 0; r < activeSection.displayRows.length; r++) {
            for (let c: number = 0; c < activeSection.displayRows[r].displayColumns.length; c++) {
                for (let f: number = 0; f < activeSection.displayRows[r].displayColumns[c].fields.length; f++) {
                    if (activeSection.displayRows[r].displayColumns[c].fields[f].metadata.control === controlType) {
                        matchingSectionFields.push(activeSection.displayRows[r].displayColumns[c].fields[f]);
                    }
                }
            }
        }
        return matchingSectionFields;
    }

    // Check to see if fieldId is a descendant of descendantOfFieldId in the active section.
    public isFieldDescendantOf(fieldId: string, descendantOfFieldId: string): boolean {
        // Find the descendantOfFieldId by recursively looking through the active sections fields.
        const descendantOfField: SectionField = this.findFieldRecursive(descendantOfFieldId, this.activeSection.sectionFields);

        if (!!descendantOfField && !!descendantOfField.childFields && descendantOfField.childFields.length > 0) {
            // Try and find the fieldId by recursively looking through the descendandOfField children fields.
            // Note this looks through already loaded children - not children yet to be loaded.
            const field = this.findFieldRecursive(fieldId, descendantOfField.childFields);
            if (!!field) {
                return true;
            }
        }

        return false;
    }

    // Get section status.
    public getSectionStatus(displaySection: DisplaySection): SectionStatus {
        const isThisSectionActive: boolean = displaySection === this.activeSection;

        // If the service did not return a value for isSaved and the section is not dirty then return
        // a section status of undetermined.
        if (MiscUtil.isNullOrUndefined(displaySection.isSaved)) {
            if (isThisSectionActive && displaySection.isDirty) {
                return SectionStatus.InProgress;
            } else {
                return SectionStatus.Undetermined;
            }
        }

        // section.isSaved is not null or undefined. Proceed to handle isSaved false/true cases.
        if (!displaySection.isSaved) {
            // If the section is not flagged as isSaved true.
            if (!isThisSectionActive) {
                return SectionStatus.InputPending;
            } else {
                return SectionStatus.InProgress;
            }
        }

        if (displaySection.isSaved) {
            // If the section is flagged as isSaved true.
            if (!isThisSectionActive) {
                return SectionStatus.Complete;
            } else {
                if (displaySection.isDirty) {
                    return SectionStatus.InProgress;
                } else {
                    return SectionStatus.Complete;
                }
            }
        }

        return SectionStatus.Undetermined;
    }

    // Modify dynamic sections.
    public modifyDynamicSections(dynamicSections: Section[], callbackForRemovedField?: (fieldId: string) => void): void {
        if (!dynamicSections || dynamicSections.length === 0) {
            return;
        }

        const sectionsToBeAdded: SectionToBeAdded[] = [];
        const sectionsToBeRemoved: Section[] = [];

        // Compare the sections already loaded with the incoming sections. Prepare to add any section that is missing.
        // Only process sections after the active section. That is, disallow adding of sections above the active section.
        // Doing so would break UI logic, such as activeSectionIndex becoming invalid if that was done.
        let activeSectionFound: boolean = false;
        let n: number = this.activeSectionIndex; // n keeps track of the index to add after the active section index.
        for (let i: number = 0; i < dynamicSections.length; i++) {
            const incomingSection: Section = dynamicSections[i];
            let found: boolean = false;
            for (let s: number = 0; s < this.displaySections.length; s++) {
                const existingSection = this.displaySections[s];
                if (incomingSection.id === existingSection.id) {
                    found = true;
                    if (!activeSectionFound) {
                        if (incomingSection.id === this.activeSection.id) {
                            activeSectionFound = true;
                        }
                    }
                    break;
                }
            }
            if (!activeSectionFound || incomingSection.id === this.activeSection.id) {
                continue;
            }

            // Increment n.
            n++;

            if (!found) {
                // Not found, so prepare for it to be added at position i. Don't add it now, as it will break the for loop. To be added below.
                const sectionToBeAdded: SectionToBeAdded = { section: incomingSection, addIndex: n };
                sectionsToBeAdded.push(sectionToBeAdded);
            }
        }

        if (!activeSectionFound) {
            // If active section was not found in the incoming dynamic section data, then throw an error.
            throw new Error('Error processing dynamic sections. Active section not found in incoming sections.');
        }

        // Compare the sections already loaded with the incoming sections. Prepare to remove any section no longer present.
        for (let s: number = 0; s < this.displaySections.length; s++) {
            const existingSection = this.displaySections[s];
            let found: boolean = false;
            for (let i: number = 0; i < dynamicSections.length; i++) {
                const incomingSection: Section = dynamicSections[i];
                if (incomingSection.id === existingSection.id) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                // Not found, so prepare for it to be removed. Don't remove it now, as it will break the for loop. To be removed below.
                sectionsToBeRemoved.push(existingSection);
            }
        }

        // Add missing sections.
        for (let i: number = 0; i < sectionsToBeAdded.length; i++) {
            const displaySection: DisplaySection = new DisplaySection(sectionsToBeAdded[i].section);
            this.displaySections.splice(sectionsToBeAdded[i].addIndex, 0, displaySection);
        }

        // Remove any sections no longer present.
        for (let i: number = 0; i < sectionsToBeRemoved.length; i++) {
            // When removing sections... need to check if the section was already loaded,
            // and if so, remove all fields and remove all associated data like field watchers.
            for (let s: number = 0; s < this.displaySections.length; s++) {
                if (this.displaySections[s].id === sectionsToBeRemoved[i].id) {
                    // If this section to be removed was loaded already, then it may have field watchers set up, so need to clear those out
                    // prior to removing the section.
                    if (this.displaySections[s].sectionInitialLoadComplete) {
                        for (let z: number = 0; z < this.displaySections[s].sectionFields.length; z++) {
                            callbackForRemovedField(this.displaySections[s].sectionFields[z].id);
                        }
                    }
                    this.displaySections.splice(s, 1);
                }
            }
        }
    }
}

import { Section } from 'app/common/metadata-models/section';

// Mock data used for development and testing.
export class MockSections {
    public sections_sample: Section[] = [
        new Section({
            id: 'SampleA',
            sectionName: 'sample a',
            isSaved: false,
            alwaysSave: false
        }),
        new Section({
            id: 'SampleB',
            sectionName: 'sample b',
            isSaved: false,
            alwaysSave: false
        })
    ];
}

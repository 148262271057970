<header class='header-component' role="banner" *ngIf="appSharedStateService.headerType === 'BuiltIn'">
    <div class="container content">
        <div class="row containerDisplayBlock">
            <div class="title col-md-6 col-xs-6 cotainerLeft">
                <h1>{{lms.get('PAYMENT_CENTRAL')}}</h1>
            </div>
            <div class="user-area col-md-6 col-xs-6 containerRight">
                <div class="containerRight" *ngIf="!useAdalAuth && !!appSharedStateService.sessionData && !!appSharedStateService.sessionData.email">
                    <span class="user-welcome">{{lms.get('WELCOME_COMMA')}}
                        {{appSharedStateService.sessionData.email}}</span>
                </div>
                <div class="containerRight" *ngIf="!!appSharedStateService.user && appSharedStateService.user.authenticated && useAdalAuth">
                    <span class="user-welcome">{{lms.get('WELCOME_COMMA')}}
                        {{appSharedStateService.user.profile.given_name + ' (' + appSharedStateService.user.userName +
                        ')'}}</span>
                    <div (click)="signOutClicked()" class="signout">
                        <span class="far fa-user"></span>
                        <span>&nbsp;{{lms.get('SIGN_OUT')}}</span>
                    </div>
                </div>
                <div class="tools-container">
                    <div class="tool-item" *ngIf="displayLanguageSelector">
                        <span style="margin-right:6px;">{{lms.get('LANGUAGE_COLON')}}</span>
                        <kendo-dropdownlist #dropDownListLanguageSelector class="language-dropdown"
                            [data]="supportedLanguages" [textField]="'name'" [disabled]="isBusy" [valueField]="'code'"
                            [valuePrimitive]="true" [attr.aria-label]="lms.get('LANGUAGE_SELECTION')"
                            [(ngModel)]="lms.selectedLanguageCode" (valueChange)="onValueChange($event)"
                            aria-live="assertive">
                            <ng-template kendoDropDownListItemTemplate let-dataItem>
                                <div>
                                    <span class="dropdown-list">{{ dataItem.name }}</span>
                                </div>
                            </ng-template>
                        </kendo-dropdownlist>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>

<header role="banner" *ngIf="appSharedStateService.headerType === 'CobrandRemoteJavascript'">
    <app-script-inject [src]="appSharedStateService.sessionData.headerUri" type="text/javascript"></app-script-inject>
</header>

<header role="banner" *ngIf="appSharedStateService.headerType === 'CobrandRemoteHtml'">
    <div [innerHtml]="appSharedStateService.cobrandHeaderHtml"></div>
</header>

import { IsSchemaCompliant } from 'app/common/interfaces/isSchemaCompliant';

// Section models data returned from the API.
// *** Shape should match exactly what the API returns. ***
export class Section implements IsSchemaCompliant {
    public id: string;
    public sectionName: string;
    public isSaved?: boolean;
    public alwaysSave?: boolean;

    // Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
    constructor(jsonData: any) {
        this.id = jsonData.id;
        this.sectionName = jsonData.sectionName;
        this.isSaved = jsonData.isSaved;
        this.alwaysSave = jsonData.alwaysSave;
    }

    // User defined type guard to verify data is a valid Section.
    public isSchemaCompliant(): boolean {
        let isCompliant: boolean = true;

        // Make sure all non-nullable properties are present.
        isCompliant = !!this.id && !!this.sectionName;
        if (!isCompliant) {
            console.error('JSON is non-compliant - Section required properties not found.');
        }

        return isCompliant;
    }
}

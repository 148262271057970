import { ChildFieldsDynamic } from 'app/common/metadata-models/childFieldsDynamic';
import { ControlOptions } from 'app/common/metadata-models/controlOptions';
import { FieldMetadata } from 'app/common/metadata-models/fieldMetadata';
import { SectionField } from 'app/common/metadata-models/sectionField';
import { ControlType } from 'app/common/metadata-models/controlType';
import { DropdownOption } from 'app/common/metadata-models/dropdownOption';

// Mock data used for development and testing.
export class MockSectionFields {
    public sectionFields_Setup: SectionField[] = [
        new SectionField({
            id: 'SetupHeading',
            metadata: new FieldMetadata({
                fieldGroup: 'SetupHeading',
                columnHint: 1,
                label: 'Complete the form below so we can verify the tax status of the party contracting with Microsoft:',
                control: ControlType.html
            })
        }),
        new SectionField({
            id: 'OnBehalfOfOrgRadio1',
            fieldValue: 'OnBehalfOfOrgRadio1',
            metadata: new FieldMetadata({
                fieldGroup: 'OnBehalfOfOrg',
                columnHint: 1,
                required: true,
                label: 'If you are filling this out on behalf of your organization, select if any are true:<ul style="font-weight:600;margin: 0 0 6px 0;"><li>You are a corporation that was incorporated in the USA</li><li>You believe that you should fill out a Form W-9 (tax form)</li></ul>OR<ul style="font-weight:600;margin: 6px 0 0 0;"><li>If you are filling this out as an individual, select if any are true:</li><li>You are a US citizen</li><li>You were born in the USA</li><li>You are a US resident (you have a green card)</li><li>You file a joint tax return with a US taxpayer</li><li>You have been in the USA more than 183 days in the past 3 years</li><li>You believe that you should fill out a Form W-9 (tax form)',
                readOnly: false,
                ariaLabel: 'tbd',
                fieldInfo: null,
                control: ControlType.radiobutton,
                controlOptions: new ControlOptions({
                    groupName: 'OnBehalfOfOrgRadio'
                })
            })
        }),
        new SectionField({
            id: 'OnBehalfOfOrgRadio2',
            fieldValue: null,
            childFieldsDynamic: new ChildFieldsDynamic({
                httpVerb: 'GET',
                api: 'Sections/{sectionId}/Fields/{fieldId}/ChildFields?fieldValue={fieldValue}'
            }),
            metadata: new FieldMetadata({
                fieldGroup: 'OnBehalfOfOrg',
                columnHint: 2,
                required: true,
                label: 'Select if none of these apply to you or your organization',
                readOnly: false,
                ariaLabel: 'tbd',
                fieldInfo: null,
                control: ControlType.radiobutton,
                controlOptions: new ControlOptions({
                    groupName: 'OnBehalfOfOrgRadio'
                })
            })
        })
    ];

    public sectionFields_TaxStatus: SectionField[] = [
        new SectionField({
            id: 'TaxTypeId',
            fieldValue: null,
            childFieldsDynamic: new ChildFieldsDynamic({
                httpVerb: 'GET',
                api: 'Sections/{sectionId}/Fields/{fieldId}/ChildFields?fieldValue={fieldValue}'
            }),
            metadata: new FieldMetadata({
                fieldGroup: '',
                columnHint: 1,
                required: true,
                label: 'Tax ID type',
                readOnly: false,
                ariaLabel: 'tbd',
                fieldInfo: 'Select a tax ID type that is applicable for your region or country. If a type is not selected, your tax profile will be incomplete and will delay processing.',
                control: ControlType.dropdown,
                controlOptions: new ControlOptions({
                    nullSelectionText: 'Select',
                    dropdownOptionsStatic: [
                        new DropdownOption({ text: 'CUIT number', value: 1 }),
                        new DropdownOption({ text: 'NIP or CM number', value: 2 }),
                    ]
                })
            })
        })
    ];

    public sectionFields_AdditionalDocumentation: SectionField[] = [
        new SectionField({
            id: 'TaxDocHeading',
            metadata: new FieldMetadata({
                fieldGroup: 'TaxDocHeading',
                columnHint: 1,
                label: 'Include the following required documentation:',
                control: ControlType.html
            })
        }),
        new SectionField({
            id: 'TaxDoc1',
            fieldValue: '',
            metadata: new FieldMetadata({
                fieldGroup: '',
                columnHint: 1,
                required: false,
                label: 'Copia de comprobante de Inscripción en la AFIP, firmado por apoderado',
                readOnly: false,
                ariaLabel: 'tbd',
                fieldInfo: 'Supported file types are: .jpeg, .jpg, .png, .bmp, .docx, .doc, .pdf',
                control: ControlType.upload,
                controlOptions: new ControlOptions({
                    acceptedFileExtensions: 'jpeg,jpg,png,bmp,docx,doc,pdf',
                    maxFileSize: 5000000,
                    uploadFileUrl: 'https://some/upload/url'
                })
            })
        }),
        new SectionField({
            id: 'TaxDoc2',
            fieldValue: '',
            metadata: new FieldMetadata({
                fieldGroup: '',
                columnHint: 1,
                required: false,
                label: 'Copia de comprobante de Inscripción de ingresos brutos sellado por Rentas',
                readOnly: false,
                ariaLabel: 'tbd',
                fieldInfo: 'Supported file types are: .jpeg, .jpg, .png, .bmp, .docx, .doc, .pdf',
                control: ControlType.upload,
                controlOptions: new ControlOptions({
                    acceptedFileExtensions: 'jpeg,jpg,png,bmp,docx,doc,pdf',
                    maxFileSize: 5000000,
                    uploadFileUrl: 'https://some/upload/url'
                })
            })
        }),
        new SectionField({
            id: 'TaxDoc3',
            fieldValue: '',
            metadata: new FieldMetadata({
                fieldGroup: '',
                columnHint: 1,
                required: false,
                label: 'Copia de comprobante de excención de impuesto si correspondiere (Ganancias e IIBB)',
                readOnly: false,
                ariaLabel: 'tbd',
                fieldInfo: 'Supported file types are: .jpeg, .jpg, .png, .bmp, .docx, .doc, .pdf',
                control: ControlType.upload,
                controlOptions: new ControlOptions({
                    acceptedFileExtensions: 'jpeg,jpg,png,bmp,docx,doc,pdf',
                    maxFileSize: 5000000,
                    uploadFileUrl: 'https://some/upload/url'
                })
            })
        }),
        new SectionField({
            id: 'TaxDoc4',
            fieldValue: '',
            metadata: new FieldMetadata({
                fieldGroup: '',
                columnHint: 1,
                required: false,
                label: 'Constancia de Inscripción como agente de recaudacion de IIBB de la Ciudad Autónoma de Buenos Aires - si correspondiere',
                readOnly: false,
                ariaLabel: 'tbd',
                fieldInfo: 'Supported file types are: .jpeg, .jpg, .png, .bmp, .docx, .doc, .pdf',
                control: ControlType.upload,
                controlOptions: new ControlOptions({
                    acceptedFileExtensions: 'jpeg,jpg,png,bmp,docx,doc,pdf',
                    maxFileSize: 5000000,
                    uploadFileUrl: 'https://some/upload/url'
                })
            })
        })
    ];
}

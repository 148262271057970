import { Injectable } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { PageMetadataState } from 'app/common/utility/page-metadata-state';
import { environment } from 'environments/environment';
import { SessionData } from 'app/common/metadata-models/sessionData';
import { MiscUtil } from 'app/common/utility/miscUtil';

// Header/Footer type using string backed enum.
export enum HeaderFooterType {
    Undetermined = 'Undetermined',                       // The initial default value, until it is determined to be one of the below.
    BuiltIn = 'BuiltIn',                                 // The built in default header/footer.
    CobrandRemoteJavascript = 'CobrandRemoteJavascript', // A javascript based header, typically as generated from a ASP.NET MVC .cshtml file.
    CobrandRemoteHtml = 'CobrandRemoteHtml'              // A html based header/footer.
}

// App shared state service.
// This object is intended to hold any shared state needed by the entire app.
@Injectable()
export class AppSharedStateService {
    public storedSessionId: string;
   // public storedUser: adal.User;
    public activePageMetadataState: PageMetadataState; // Used with metadata driven pages (ex: bank, tax).
    public sessionData: SessionData;
    public headerType: HeaderFooterType = HeaderFooterType.Undetermined;
    public footerType: HeaderFooterType = HeaderFooterType.Undetermined;
    public cobrandHeaderHtml: SafeHtml;
    public cobrandFooterHtml: SafeHtml;

    // The userKey is used to represent a unique user Id. This is passed to every API call in headers along with sessionId.
    // This is intended to prevent the session from being used by users other than the original receipient.
    public userKey: string;

    // The referenceId is used to represent a unique session Id. Conceptually it is the same as the Application Insights "session id"
    // but is something we can have more control over - and we call it "referenceId" to disambiguate from the AI session id. The
    // referenceId will survive a F5 page refresh as just like userKey it will be saved in local HTML5 session storage.
    // This id, along with userKey and correlationId (per api call generated guid) will be passed to every API call in the headers.
    public referenceId: string;

    // Constructor for AppSharedStateService.
    constructor() {
        this.loadUserKey();
        this.loadReferenceId();
    }

    // Get the SessionId from the query string.
    // The SessionId query string parameter passed to us from the NVA site redirection.
    public get sessionId(): string {
        if (!this.storedSessionId) {
            // Using utility method to get query string value and NOT: this.route.snapshot.queryParams['SessionId']
            // This is because getting this query string value may occur prior to a route navigation being completed.
            this.storedSessionId = MiscUtil.getQueryStringValue('SessionId');
        }
        // Return the sessionId or an empty string if not found on the query string.
        return this.storedSessionId || '';
    }

    // Load userKey from sessionStorage if present. If not present, generate and store in sessionStorage.
    private loadUserKey(): void {
        if (!sessionStorage.userKey) {
            sessionStorage.userKey = MiscUtil.createGuid();
        }
        this.userKey = sessionStorage.userKey;
    }

    // Load referenceId from sessionStorage if present. If not present, generate and store in sessionStorage.
    private loadReferenceId(): void {
        if (!sessionStorage.referenceId) {
            sessionStorage.referenceId = MiscUtil.createGuid();
        }
        this.referenceId = sessionStorage.referenceId;
    }
}

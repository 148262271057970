import { Component, OnInit, AfterViewInit, ChangeDetectorRef, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { FieldWatcherService } from 'app/common/services/field-watcher.service';
import { BaseControl } from 'app/common/components/controls/base-control';
import { MetadataApiService } from 'app/common/services/metadata-api.service';
import { LocalizationManagerService } from 'app/common/services/localization-manager.service';
import { AppSharedStateService } from 'app/common/services/app-shared-state.service';
import { AppSharedUtilityService } from 'app/common/services/app-shared-utility.service';

@Component({
    selector: 'app-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UploadComponent),
            multi: true
        }
    ]
})
export class UploadComponent extends BaseControl implements OnInit, AfterViewInit {
    public fileTypeRestrictions: FileRestrictions;
    public saveUrl: string;

    // Gets file type display string.
    public get fileTypesDisplay(): string {
        if (!!this.fieldData.metadata.controlOptions && !!this.fieldData.metadata.controlOptions.acceptedFileExtensions) {
            return this.fieldData.metadata.controlOptions.acceptedFileExtensions.replace(/[.,;-]/g, ' ');
        }
        return '';
    }

    // Constructor for UploadComponent.
    constructor(
        protected fieldWatcherService: FieldWatcherService,
        protected metadataApiService: MetadataApiService,
        protected changeDetectorRef: ChangeDetectorRef,
        protected domSanitizer: DomSanitizer,
        protected appSharedStateService: AppSharedStateService,
        protected appSharedUtilityService: AppSharedUtilityService,
        public lms: LocalizationManagerService /* Using short name on purpose for data binding. */) {
        super(fieldWatcherService, metadataApiService, changeDetectorRef, domSanitizer, appSharedStateService, appSharedUtilityService, lms);
    }

    // Angular OnInit handler. Part of the lifecycle hooks: https://angular.io/guide/lifecycle-hooks
    public ngOnInit(): void {
        if (!!this.fieldData.metadata && !!this.fieldData.metadata.controlOptions && !!this.fieldData.metadata.controlOptions.acceptedFileExtensions) {
            const delimeters: RegExp = /[.,;-]/; // Allow comma or semicolon as delimeters.
            this.fileTypeRestrictions = {
                allowedExtensions: this.fieldData.metadata.controlOptions.acceptedFileExtensions.split(delimeters),
                maxFileSize: this.fieldData.metadata.controlOptions.maxFileSize
            };
            this.saveUrl = this.fieldData.metadata.controlOptions.uploadFileUrl;
        }
    }

    // Angular AfterViewInit handler. Part of the lifecycle hooks: https://angular.io/guide/lifecycle-hooks
    public ngAfterViewInit(): void {
        // This is done in a setTimeout so it defers running until a data binding pass is done first.
        setTimeout(() => {
            // Adding field watchers must be done in ngAfterViewInit.
            this.addCommonFieldWatchers();

            // Mark this control as ready after the field watchers have been set.
            this.fieldData.isControlReady = true;
        });
    }
}

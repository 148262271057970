import { DropdownOption } from 'app/common/metadata-models/dropdownOption';

// Mock data used for development and testing.
export class MockDynamicDropdownData {
    public nothingNeededYet: DropdownOption[] = [
        new DropdownOption({
            text: 'na',
            value: 'na'
        })
    ];
}

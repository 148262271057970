import { Injectable } from '@angular/core';
// Note: We are using the newer HttpClient and related objects from '@angular/common/http' and not the
// older Http and related objects from '@angular/http' which still ship as part of Angular but are considered older.
// See: https://angular.io/guide/http
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { throwError as observableThrowError, Observable, Subscribable, BehaviorSubject } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { DropdownOptionDynamic } from 'app/common/metadata-models/dropdownOptionDynamic';
import { Section } from 'app/common/metadata-models/section';
import { SectionField, MinSectionField } from 'app/common/metadata-models/sectionField';
import { DropdownOption } from 'app/common/metadata-models/dropdownOption';
import { ChildFieldsDynamic } from 'app/common/metadata-models/childFieldsDynamic';
import { SectionsDynamic } from 'app/common/metadata-models/sectionsDynamic';
import { JsonObjectFactory } from 'app/common/utility/jsonObjectFactory';
import { IsSchemaCompliant } from 'app/common/interfaces/isSchemaCompliant';
import { MetadataJsonSchemaValidatorService } from 'app/common/services/metadata-json-schema-validator.service';
import { CustomErrorHandlerService } from 'app/common/services/custom-error-handler.service';
import { InstrumentationService } from 'app/common/services/instrumentation.service';
import { LocalizationManagerService } from 'app/common/services/localization-manager.service';
import { PageMetadataState } from '../utility/page-metadata-state';
import { environment } from 'environments/environment';
import { SessionData } from 'app/common/metadata-models/sessionData';
import { MiscUtil } from 'app/common/utility/miscUtil';

export enum SupportedMicroservice {
    Bank, Tax, Basic, Compliance
}

// Interface for MetadataApiService. Used for implementation on the service and also the mock service and unit tests.
export interface IMetadataApiService {
    configureService(microService: SupportedMicroservice, baseUrl: string, apiVersionParam: string, apiVersionValue: string);
    getSections(): Observable<Section[]>;
    getSectionFields(sectionId: string): Observable<SectionField[]>;
    putSectionFields(sectionId: string, minSectionFields: MinSectionField[]): Observable<Object>;
    getDynamicDropdownData(dropdownOptionsDynamic: DropdownOptionDynamic, fieldId: string, changedFieldId: string,
        changedFieldValue: number|string|boolean, pageMetadataState: PageMetadataState): Observable<DropdownOption[]>;
    getDynamicChildFields(childFieldsDynamic: ChildFieldsDynamic, fieldId: string, changedFieldValue: number|string|boolean,
        pageMetadataState: PageMetadataState): Observable<SectionField[]>;
    getDynamicSections(sectionsDynamic: SectionsDynamic, fieldId: string, changedFieldValue: number|string|boolean, pageMetadataState: PageMetadataState): Observable<Section[]>;
    getSessionData(): Observable<SessionData>;
}

// The metadata api service manages all service calls to the bank and tax microservice web apis.
// It also has the ability to return mock data for test purposes.
@Injectable()
export class MetadataApiService implements IMetadataApiService {
    // Following are configured in configureService().
    public microService: SupportedMicroservice;
    private baseUrl: string;
    private apiVersionParam: string;
    private apiVersionValue: string;
    private apiCacheBusterParam: string = 'cb';
    private sectionsApi: string;
    private sectionFieldsApi: string;
    private sessionDataApi: string;
    private bankCountrySelection: string;

    private sectionId: string = '{sectionId}';
    public mockDataProvider: IMetadataApiService;

    // BankDataService constructor.
    constructor(private httpClient: HttpClient, private metadataJsonValidator: MetadataJsonSchemaValidatorService,
        private instrumentationService: InstrumentationService, private localizationManagerService: LocalizationManagerService) {
        // Configure service for bank service by default. This service will be configured in each metadata driven page
        // (bank, tax, basic, ...) initialization. This is just a default initial configuration especially so that when
        // api's such as getSessionData() are called during header construction, or during page views such as for invalid-route
        // page. All metadata driven services should have the same implementation for common api's like getSessionData()
        // and such api's should behave the exact same way regardless of what this service is configured to point to.
        this.configureService(SupportedMicroservice.Bank, environment.bankServiceBaseUrl, 'api-version', '2017-08-24');
    }

    // Gets standard headers.
    public get standardHeaders(): HttpHeaders {
        const httpHeaders: HttpHeaders = new HttpHeaders();
        httpHeaders.append('accept', 'application/json');
        httpHeaders.append('content-type', 'application/json');
        httpHeaders.append('accept-language', this.localizationManagerService.selectedLanguageCode);
        return httpHeaders;
    }

    // Configure service. To be called by page component.
    public configureService(microService: SupportedMicroservice, baseUrl: string, apiVersionParam: string, apiVersionValue: string) {
        this.microService = microService;
        this.baseUrl = baseUrl;
        // If baseUrl is missing trailing / then add it.
        if (!this.baseUrl.endsWith('/')) {
            this.baseUrl += '/';
        }
        this.apiVersionParam = apiVersionParam;
        this.apiVersionValue = apiVersionValue;
        this.sectionsApi = `${this.baseUrl}Sections`;
        this.sectionFieldsApi = `${this.baseUrl}Sections/${this.sectionId}/Fields`;
        this.sessionDataApi = `${this.baseUrl}SessionData`;
    }

    // General purpose error handler.
    // See rxjs catch.d.ts catch function definition. This handleError method is used during catch of http call.
    // The rxjs catch function selector looks like: selector: (err: any, caught: Observable<T>) => ObservableInput<R>
    private handleError(err: any, caught: Observable<any>, correlationId: string, displayAnyErrorMessage = true): Subscribable<any> {
        // Manually call the CustomErrorHandlerService here to add the error. If the api call subscription
        // was not coded to handle errors in the calling code then Angular/rxjs will raise an unhandled app error
        // and will invoke the CustomErrorHandlerService as it is registered as the app-wide error handler (see
        // how it is registered in app.module.ts). But if the error was handled in the calling code then then
        // we need to manually add the error here.
        // See metadata-render.component.ts to see example call and error handler...
        //    this.metadataApiService.getSections().subscribe((sectionArray: Section[]) => { ... }
        //    (error: any) => { }
        CustomErrorHandlerService.instance.addError(err, correlationId, null, displayAnyErrorMessage);

        // If the subscription error was not handled in the calling code, then this throw will raise an app exception.
        return observableThrowError(err || 'Server error');
    }

    // Generic method to GET data array with httpClient.
    // Instantiates object of type T and validates it against the schema.
    private genericGetArray<T extends IsSchemaCompliant>(apiUrl: string, t: {new(jsonData): T}): Observable<T[]> {
        console.log(`GET API call: ${apiUrl}`);
        // Following approach to adding http params seems necessary - where .set is called directly on the new HttpParams.
        const httpParams: HttpParams = new HttpParams()
            .set(this.apiVersionParam, this.apiVersionValue)
            .set(this.apiCacheBusterParam, Math.random().toString());
        const correlationId: string = this.instrumentationService.generateCorrelationId();
        const headers = this.standardHeaders
            .set('correlationid', correlationId);
        return this.httpClient.get<Array<T>>(apiUrl, { headers: headers, observe: 'response', params: httpParams }).pipe(
            map((response: HttpResponse<Array<T>>) => {
                const instanceData: Array<T> = JsonObjectFactory.instantiateFromJsonArray(response.body, t);
                this.metadataJsonValidator.validateJsonSchema<T>(instanceData);
                return instanceData;
            }),
            tap(data => {
                console.log(`Return from GET API call: ${apiUrl}`);
                console.log(`Data: ${JSON.stringify(data)}`);
            }),
            catchError((err: any, caught: Observable<any>) => {
                return this.handleError(err, caught, correlationId);
            }));
    }

    // Generic method to GET data object with httpClient.
    // Instantiates object of type T and validates it against the schema.
    private genericGet<T extends IsSchemaCompliant>(apiUrl: string, t: {new(jsonData): T}, displayAnyErrorMessage = true): Observable<T> {
        console.log(`GET API call: ${apiUrl}`);
        const httpParams: HttpParams = new HttpParams()
            .set(this.apiVersionParam, this.apiVersionValue)
            .set(this.apiCacheBusterParam, Math.random().toString());
        const correlationId: string = this.instrumentationService.generateCorrelationId();
        const headers = this.standardHeaders
            .set('correlationid', correlationId);
        return this.httpClient.get<T>(apiUrl, { headers: headers, observe: 'response', params: httpParams }).pipe(
            map((response: HttpResponse<T>) => {
                const instanceData: T = JsonObjectFactory.instantiateFromJson(response.body, t);
                if (!this.metadataJsonValidator.validateJsonSchema<T>(instanceData)) {
                    return null;
                }
                return instanceData;
            }),
            tap(data => {
                console.log(`Return from GET API call: ${apiUrl}`);
                console.log(`Data: ${JSON.stringify(data)}`);
            }),
            catchError((err: any, caught: Observable<any>) => {
                return this.handleError(err, caught, correlationId, displayAnyErrorMessage);
            }));
    }

    // Get sections.
    public getSections(): Observable<Section[]> {
        if (environment.useLocalMockData) {
            return this.mockDataProvider.getSections();
        } else {
            return this.genericGetArray<Section>(this.sectionsApi, Section);
        }
    }

    // Get section data for a given section.
    public getSectionFields(sectionId: string): Observable<SectionField[]> {
        if (environment.useLocalMockData) {
            return this.mockDataProvider.getSectionFields(sectionId);
        } else {
            const sectionFieldsApi = this.sectionFieldsApi.replace(this.sectionId, sectionId);
            return this.genericGetArray<SectionField>(sectionFieldsApi, SectionField);
        }
    }

    // Put (save) section data for a given section.
    public putSectionFields(sectionId: string, minSectionFields: MinSectionField[]): Observable<Object> {
        if (environment.useLocalMockData) {
            return this.mockDataProvider.putSectionFields(sectionId, minSectionFields);
        } else {
            const sectionFieldsApi = this.sectionFieldsApi.replace(this.sectionId, sectionId);
            console.log(`PUT API call: ${sectionFieldsApi}`);
            const httpParams: HttpParams = new HttpParams().set(this.apiVersionParam, this.apiVersionValue);
            const correlationId: string = this.instrumentationService.generateCorrelationId();
            const headers = this.standardHeaders
                .set('correlationid', correlationId);
            return this.httpClient.put(sectionFieldsApi, minSectionFields, { headers: headers, observe: 'response', params: httpParams, responseType: 'json' }).pipe(
                map((response: HttpResponse<Object>) => {
                    return response;
                }),
                tap(data => {
                    console.log(`Return from PUT API call: ${sectionFieldsApi}`);
                    console.log(`Data: ${JSON.stringify(data)}`);
                }),
                catchError((err: any, caught: Observable<any>) => {
                    return this.handleError(err, caught, correlationId);
                }));
            }
    }

    // Get dynamic dropdown data.
    public getDynamicDropdownData(dropdownOptionsDynamic: DropdownOptionDynamic, fieldId: string, changedFieldId: string,
        changedFieldValue: number|string|boolean, pageMetadataState: PageMetadataState): Observable<DropdownOption[]> {
        if (changedFieldValue === null || changedFieldValue === undefined) {
            changedFieldValue = '';
        }

        // Saving bank country to determine TaxType drop down options for Tax Required countries
        if (dropdownOptionsDynamic.drivenByFieldId === 'BankCountrySelection') {
            this.bankCountrySelection = changedFieldValue.toString();
        }

        // Example API URL that would be in the dropdownOptionsDynamic.api:
        // Sections/{sectionId}/Fields/{fieldId}/DropdownValues?changedFieldId={changedFieldId}&changedFieldValue={changedFieldValue}
        // Assemble the API URL to call by doing:
        //   - Prefix with base url
        //   - Replace {sectionId} with id of the section the dropdown field is in
        //   - Replace {fieldId} with id of dropdown field
        //   - Replace {changedFieldId} with id of the field that changed to cause this dynamic dropdown data to load
        //   - Replace {changedFieldValue} with value of the field that changed to cause this dynamic dropdown data to load
        let api: string = `${this.baseUrl}${dropdownOptionsDynamic.api}`;
        const sectionId: string = pageMetadataState.sectionIdForField(fieldId);
        api = api.replace('{sectionId}', sectionId)
                 .replace('{fieldId}', fieldId)
                 .replace('{changedFieldId}', changedFieldId)
                 .replace('{changedFieldValue}', changedFieldValue.toString())
                 .replace('{bankCountrySelection}', this.bankCountrySelection);

        if (environment.useLocalMockData) {
            return this.mockDataProvider.getDynamicDropdownData(dropdownOptionsDynamic, fieldId, changedFieldId, changedFieldId, pageMetadataState);
        } else {
            switch (dropdownOptionsDynamic.httpVerb.toLowerCase()) {
                case 'get': {
                    return this.genericGetArray<DropdownOption>(api, DropdownOption);
                }
                case 'put': {
                    // todo: When needed...
                    break;
                }
                case 'post': {
                    // todo: When needed...
                    break;
                }
            }
        }
    }

    // Get dynamic child fields.
    public getDynamicChildFields(childFieldsDynamic: ChildFieldsDynamic, fieldId: string, changedFieldValue: number|string|boolean,
        pageMetadataState: PageMetadataState): Observable<SectionField[]> {

        if (environment.useLocalMockData) {
            return this.mockDataProvider.getDynamicChildFields(childFieldsDynamic, fieldId, changedFieldValue, pageMetadataState);
        } else {
            if (changedFieldValue === null || changedFieldValue === undefined) {
                changedFieldValue = '';
            }

            // Example API URL that would be in the childFieldsDynamic.api:
            // Sections/{sectionId}/Fields/{fieldId}/ChildFields?fieldValue={fieldValue}
            // Assemble the API URL to call by doing:
            //   - Prefix with base url
            //   - Replace {sectionId} with id of the section the parent field is in
            //   - Replace {fieldId} with id of parent field
            //   - Replace {fieldValue} with value of the parent field
            let api: string = `${this.baseUrl}${childFieldsDynamic.api}`;
            const sectionId: string = pageMetadataState.sectionIdForField(fieldId);
            api = api.replace('{sectionId}', sectionId)
                     .replace('{fieldId}', fieldId)
                     .replace('{fieldValue}', changedFieldValue.toString());

            switch (childFieldsDynamic.httpVerb.toLowerCase()) {
                case 'get': {
                    return this.genericGetArray<SectionField>(api, SectionField);
                }
                case 'put': {
                    // todo: When needed...
                    break;
                }
                case 'post': {
                    // todo: When needed...
                    break;
                }
            }
        }
    }

    // Get dynamic child fields.
    public getDynamicSections(sectionsDynamic: SectionsDynamic, fieldId: string, changedFieldValue: number|string|boolean,
        pageMetadataState: PageMetadataState): Observable<Section[]> {

        if (environment.useLocalMockData) {
            return this.mockDataProvider.getDynamicSections(sectionsDynamic, fieldId, changedFieldValue, pageMetadataState);
        } else {
            if (changedFieldValue === null || changedFieldValue === undefined) {
                changedFieldValue = '';
            }

            // Example API URL that would be in the sectionsDynamic.api:
            // Sections/{sectionId}/Fields/{fieldId}/ChildFields?fieldValue={fieldValue}
            // Assemble the API URL to call by doing:
            //   - Prefix with base url
            //   - Replace {sectionId} with id of the section the parent field is in
            //   - Replace {fieldId} with id of parent field
            //   - Replace {fieldValue} with value of the parent field
            let api: string = `${this.baseUrl}${sectionsDynamic.api}`;
            const sectionId: string = pageMetadataState.sectionIdForField(fieldId);
            api = api.replace('{sectionId}', sectionId)
                     .replace('{fieldId}', fieldId)
                     .replace('{fieldValue}', changedFieldValue.toString());

            switch (sectionsDynamic.httpVerb.toLowerCase()) {
                case 'get': {
                    return this.genericGetArray<Section>(api, Section);
                }
                case 'put': {
                    // todo: When needed...
                    break;
                }
                case 'post': {
                    // todo: When needed...
                    break;
                }
            }
        }
    }

    // Get session data.
    public getSessionData(displayAnyErrorMessage = true): Observable<SessionData> {
        if (environment.useLocalMockData) {
            // Regarding use of BehaviorSubject, see:
            //   https://dzone.com/articles/how-to-build-angular-2-apps-using-observable-data-1
            //   https://codereview.stackexchange.com/questions/164662/rxjs-observable-with-initial-value
            const bs: BehaviorSubject<SessionData> = new BehaviorSubject<SessionData>(new SessionData({
                headerUri: '', footerUri: '', returnUri: '', locale: '', email: '', userSessionExpiryDate: '', userSessionTimeRemaining: ''
            }));

            const fillNextBs = () => {
                // Check to see if mockDataProvider was set before using it. If it was not set then do nothing.
                if (!!this.mockDataProvider) {
                    this.mockDataProvider.getSessionData().subscribe((sessionData: SessionData) => {
                        bs.next(sessionData);
                    });
                }
            };

            // If mock data provider is not yet present... wait for it to be registered before using it. The mock data provider
            // is set in a page such as bank or tax. Wait up to 2 seconds for the provider to be registered, give up after that.
            if (!this.mockDataProvider) {
                MiscUtil.waitFn(() => {
                    return !!this.mockDataProvider;
                }, fillNextBs);
            } else {
                fillNextBs();
            }

            return bs.asObservable();
        } else {
            return this.genericGet<SessionData>(this.sessionDataApi, SessionData, displayAnyErrorMessage);
        }
    }
}

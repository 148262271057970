import * as $ from 'jquery';
import { Component, OnInit, Input, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FieldWatcherService } from 'app/common/services/field-watcher.service';
import { MetadataApiService } from 'app/common/services/metadata-api.service';
import { AppSharedStateService } from 'app/common/services/app-shared-state.service';
import { AppSharedUtilityService } from 'app/common/services/app-shared-utility.service';
import { PageMetadataState } from 'app/common/utility/page-metadata-state';
import { MiscUtil } from 'app/common/utility/miscUtil';
import { ControlType } from 'app/common/metadata-models/controlType';
import { DisplaySection, DisplayColumn, DisplayRow, SectionStatus } from 'app/common/metadata-models/displaySection';
import { SectionField, MinSectionField } from 'app/common/metadata-models/sectionField';
import { Section } from 'app/common/metadata-models/section';
import { environment } from 'environments/environment';
import { LocalizationManagerService } from 'app/common/services/localization-manager.service';
import { SessionManagerService } from 'app/common/services/session-manager.service';
import { InstrumentationService } from 'app/common/services/instrumentation.service';

@Component({
    selector: 'app-metadata-render',
    templateUrl: './metadata-render.component.html',
    styleUrls: ['./metadata-render.component.css']
})
export class MetadataRenderComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input('pageMetadataState') public pageMetadataState: PageMetadataState;
    @ViewChild('sectionForm') public sectionForm: NgForm;

    private existingPaymentMethod = null;
    private newPaymentMethod = null;
    // Used for debug purposes... displays field JSON on the page.
    public debugDisplayFieldJson: boolean = environment.debugDisplayFieldJson;

    // Used to display section status image.
    public sectionStatusImage = {
        inProgress: 'assets/common/inProgress.png',
        inputPending: 'assets/common/pending.png',
        complete: 'assets/common/complete.png'
    };

    // Set references to enum types so they can be data bound in html.
    public SectionStatusRef: typeof SectionStatus = SectionStatus;
    public ControlTypeRef: typeof ControlType = ControlType;

    // Constructor for MetadataRenderComponent.
    constructor(
        private sessionManagerService: SessionManagerService,
        private metadataApiService: MetadataApiService,
        private fieldWatcherService: FieldWatcherService,
        private appSharedStateService: AppSharedStateService,
        private appSharedUtilityService: AppSharedUtilityService,
        public instrumentationService: InstrumentationService,
        public lms: LocalizationManagerService /* Using short name on purpose for data binding. */) {
    }

    // Reset the metadata render component.
    public reset(): void {
        // Clear field watchers.
        this.fieldWatcherService.clearFieldWatchers();
    }

    // Angular OnInit handler. Part of the lifecycle hooks: https://angular.io/guide/lifecycle-hooks
    public ngOnInit(): void {
    }

    // Angular AfterViewInit handler. Part of the lifecycle hooks: https://angular.io/guide/lifecycle-hooks
    public ngAfterViewInit(): void {
    }

    // Angular OnDestroy handler. Part of the lifecycle hooks: https://angular.io/guide/lifecycle-hooks
    public ngOnDestroy(): void {
        // Clear field watchers.
        this.fieldWatcherService.clearFieldWatchers();
    }

    // Load sections.
    // This should be the first method called by the component this is on. This is available as a separate method intended to be called
    // on purpose by the parent component when the parent is ready to load it. The call to this could be placed in this components ngOnInit
    // but, the intention is to give control to the parent component as to when this loads.
    public loadSections(): void {
        setTimeout(() => {
            this.pageMetadataState.displaySections = [];
            this.metadataApiService.getSections().subscribe((sectionArray: Section[]) => {
                if (!!sectionArray) {
                    for (let i: number = 0; i < sectionArray.length; i++) {
                        const displaySection: DisplaySection = new DisplaySection(sectionArray[i]);
                        this.pageMetadataState.displaySections.push(displaySection);

                        // Get the first section data.
                        if (i === 0) {
                            this.loadSectionFields(displaySection);
                            this.instrumentationService.trackEvent(
                                !displaySection.isSaved ? 'Page load: create scenario' : 'Page load: edit scenario',
                                {
                                    correlationId: this.instrumentationService.generateCorrelationId(),
                                    referenceId: this.appSharedStateService.referenceId,
                                    userKey: this.appSharedStateService.userKey
                                }
                            );
                        }
                    }
                }
                this.pageMetadataState.displaySectionsLoaded = true;
            }, (error: any) => {
                // The call failed. The CustomErrorHandlerService will cause an error to display.
                // Set the loaded flag even though a failure occurred, so the loading spinner disappears.
                this.pageMetadataState.displaySectionsLoaded = true;
            });
        }, environment.useLocalMockData ? environment.useLocalMockDataSimulatedDelay : 0); // If using local mock data, then simulate a delay.
    }

    // Load section data.
    public loadSectionFields(displaySection: DisplaySection): void {
        if (!displaySection.checkSectionFieldsLoaded(false)) {
            this.pageMetadataState.sectionFieldsLoading = true;

            this.metadataApiService.getSectionFields(displaySection.id).subscribe((sectionFields: SectionField[]) => {
                if (!sectionFields) {
                    console.error(`No data returned for section: ${displaySection.id}`);
                    this.pageMetadataState.sectionFieldsLoading = false;
                    displaySection.noDataFound = true;
                } else {
                    console.log(`Section data loaded: ${displaySection.id}`);

                    this.renderSectionFields(displaySection, sectionFields);

                    // Execute the following code in a setTimeout to queue this on the UI thread until after the UI has rendered.
                    setTimeout(() => {
                        for (let i: number = 0; i < sectionFields.length; i++) {
                            // Fire the field watcher notify changes if the field was set.
                            // If the field is null or undefined then no need to do this.
                            if (!MiscUtil.isNullOrUndefined(sectionFields[i].fieldValue)) {

                                if (displaySection.id === 'BankDetails' && sectionFields[i].id === 'PaymentMethod') {
                                    this.existingPaymentMethod = sectionFields[i].fieldValue;
                                }

                                this.fieldWatcherService.notifyChanges(sectionFields[i]);
                            }
                        }

                        // Wait until all dynamic data (dropdownOptionDynamic, childFieldsDynamic) is loaded
                        // before we flag that the section is fully loaded.
                        displaySection.waitForAllDynamicDataLoaded(() => {
                            // Now that all dynamic data has been loaded, we will set the section fields loading flag to false.
                            // But this needs to be done in a setTimeout after a brief delay in order to wait for data binding
                            // of any prior saved data to complete. Otherwise the field change warning popup will show when the
                            // data change notification fires via the field watcher.
                            setTimeout(() => {
                                this.pageMetadataState.sectionFieldsLoading = false;
                                displaySection.noDataFound = false;
                                // Mark the section as not dirty. During load it will be set to dirty because field change notifications
                                // fire during the loading process. User has not interactively changed anything yet, so mark the section
                                // as not dirty.
                                this.pageMetadataState.activeSection.isDirty = false;
                            }, 100);
                        });
                    });
                }
            }, (error: any) => {
                // The call failed. The CustomErrorHandlerService will cause an error to display.
                this.pageMetadataState.sectionFieldsLoading = false;
            });
        }
    }

    // Render section fields.
    private renderSectionFields(displaySection: DisplaySection, renderSectionFields: SectionField[]) {
        // Assign the sectionFields to the fields being rendered.
        // Set the whole array in like this rather than pushing one by one. This lets the displaySection's checkSectionFieldsLoaded
        // function know that the fields are completely set. Other rendering logic uses that checkSectionFieldsLoaded function.
        displaySection.sectionFields = renderSectionFields;
        for (let i: number = 0; i < renderSectionFields.length; i++) {
            // Place field in the correct column.
            displaySection.placeFieldInColumn(renderSectionFields[i]);

            // Set a callback function to be called when child fields get modified.
            renderSectionFields[i].childFieldsModifiedCallback = () => {
                this.childFieldsModified(displaySection, renderSectionFields[i]);
            };
        }
    }

    // Called by SectionField childFieldsModifiedCallback.
    private childFieldsModified(displaySection: DisplaySection, parentField: SectionField) {
        // Filter sectionFields based on alterExistingFieldMetadata flag. If this flag is null, undefined,
        // or false then we render this field.
        // Otherwise, if it is true, then we will look for an existing field anywhere in the section (recursively,
        // through children) with a matching id and copy over changed metadata. This allows us to change a previously
        // hidden field to visible, or a label to a textbox, for example.
        const childRenderFields: SectionField[] = [];
        const childAlterFields: SectionField[] = [];
        if (!!parentField.childFields) {
            for (let c: number = 0; c < parentField.childFields.length; c++) {
                // Add the child fields modified callback on each child field to allow for recursive nesting of fields.
                parentField.childFields[c].childFieldsModifiedCallback = () => {
                    this.childFieldsModified(displaySection, parentField.childFields[c]);
                };

                if (parentField.childFields[c].metadata.alterExistingFieldMetadata === true) {
                    childAlterFields.push(parentField.childFields[c]);
                } else {
                    childRenderFields.push(parentField.childFields[c]);
                }
            }
        }
        this.alterSectionFields(displaySection, childAlterFields);

        // Remove any prior child fields from the display.
        displaySection.removeDisplayedChildFields(parentField.id, (fieldId: string) => {
            this.fieldWatcherService.clearFieldWatchersForField(fieldId);
        });

        // For the fields which were not marked as alterExistingFieldMetadata (that is, normal child fields to render)
        // then place those fields in the proper column.
        displaySection.placeFieldsInColumn(childRenderFields, parentField.id);
    }

    // Alter section fields.
    private alterSectionFields(displaySection: DisplaySection, alterSectionFields: SectionField[]) {
        // Recursively look for controls in the section matching the id. If found, update the metadata if it changed.
        for (let i: number = 0; i < alterSectionFields.length; i++) {
            const foundSectionField: SectionField = this.pageMetadataState.findFieldRecursive(alterSectionFields[i].id, displaySection.sectionFields);
            if (foundSectionField !== null) {
                // Copy over metadata except for the metadata.alterExistingFieldMetadata property.
                foundSectionField.metadata.fieldGroup = alterSectionFields[i].metadata.fieldGroup;
                foundSectionField.metadata.columnHint = alterSectionFields[i].metadata.columnHint;
                foundSectionField.metadata.indentHint = alterSectionFields[i].metadata.indentHint;
                foundSectionField.metadata.required = alterSectionFields[i].metadata.required;
                foundSectionField.metadata.readOnly = alterSectionFields[i].metadata.readOnly;
                foundSectionField.metadata.visible = alterSectionFields[i].metadata.visible;
                foundSectionField.metadata.label = alterSectionFields[i].metadata.label;
                foundSectionField.metadata.ariaLabel = alterSectionFields[i].metadata.ariaLabel;
                foundSectionField.metadata.fieldInfo = alterSectionFields[i].metadata.fieldInfo;
                foundSectionField.metadata.control = alterSectionFields[i].metadata.control;
                foundSectionField.metadata.controlOptions = alterSectionFields[i].metadata.controlOptions;
            }
        }
    }

    // Check if a section is saving.
    public isSectionSaving(activeSectionIndex: number): boolean {
        return this.pageMetadataState.savingSectionIndex === activeSectionIndex;
    }

    // For debug purposes - display field JSON.
    public displayFieldJson(field: SectionField) {
        if (this.debugDisplayFieldJson) {
            return JSON.stringify(field);
        }
    }

    // Decrementing which section is enabled, and changing section states.
    public onClickBack(): void {
        if (this.pageMetadataState.activeSectionIndex > 0) {
            this.pageMetadataState.activeSectionIndex--;
        }
    }

    // Controls validation of current section, incrementing which section is enabled, and changing section states.
    public onClickNext(sectionIndex: number): void {
        this.saveSectionData(sectionIndex, '#nextButton', () => {
            // Get session data (refresh).
            this.sessionManagerService.loadSessionDataIfNeeded(true);
            // Load the next section.
            this.loadNextSection();
        });
    }

    // Finish button click handler.
    // Once all sections are complete, collapse final section and return to calling tenant.
    public onClickFinish(sectionIndex: number): void {
        this.saveSectionData(sectionIndex, '#finishButton', () => {
            // Navigate back to the return uri.
            this.appSharedUtilityService.navigateToReturnUri();
        });
    }

    // Save the section data.
    private saveSectionData(sectionIndex: number, buttonSelector: string, callback: () => void): void {
        // Save the current active section. Send back only required properties.
        const activeSection = this.pageMetadataState.activeSection;
        // Mark this section as successfully saved to reset 'in progress' status, regardless if we actually save.
        this.pageMetadataState.displaySections[sectionIndex].isSaved = true;

        // Get new payment method value to compare against existing payment method that has already
        // been stored in DB to determine if DB needs to be updated with new payment method value
        // If the existingPaymentMethod is null/undefined and isDirty then no need to do this.
        if (activeSection.id === 'BankDetails' && !activeSection.isDirty && !MiscUtil.isNullOrUndefined(this.existingPaymentMethod)) {

            for (let i: number = 0; i < activeSection.sectionFields.length; i++) {

                if (activeSection.sectionFields[i].id === 'PaymentMethod') {
                    this.newPaymentMethod = activeSection.sectionFields[i].fieldValue;
                }
            }

            if (!MiscUtil.isNullOrUndefined(this.newPaymentMethod) && this.newPaymentMethod !== this.existingPaymentMethod) {
                activeSection.isDirty = true;
            }
        }

        if (activeSection.isDirty || activeSection.alwaysSave || this.sessionManagerService.profileInProgress) {
            // Workaround: when saving occurs, the Next and Finish buttons change state (from enabled to disabled, and button text is modified)
            // due to rebinding on the control, which is based the return value of the 'isSaving' method and other conditions. This rebinding is
            // somehow swallowing the click event on the control, and therefore the jQuery click event handler that is in place across the app.
            // is not firing. Therefore, we are specifically calling the function below to caputre the button click event.
            this.instrumentationService.trackButtonClick(<HTMLButtonElement>$(`${buttonSelector}${sectionIndex}`)[0], this.appSharedStateService.referenceId, this.appSharedStateService.userKey);

            // Mark this section as currently saving.
            this.pageMetadataState.savingSectionIndex = sectionIndex;

            const minSectionFields: MinSectionField[] = this.prepareMinimalSectionFieldsForSave(activeSection.sectionFields);
            this.metadataApiService.putSectionFields(activeSection.id, minSectionFields).subscribe((response: Object) => {
                // Mark this section as no longer saving and no longer dirty.
                this.pageMetadataState.savingSectionIndex = null;
                activeSection.isDirty = false;

                callback();
            }, (error: any) => {
                // The call failed. The CustomErrorHandlerService will cause an error to display.
                this.pageMetadataState.savingSectionIndex = null;
                // If save failed, reset this flag so status shown will be 'in progress' to be in sync with save status.
                this.pageMetadataState.displaySections[sectionIndex].isSaved = false;
            });
        } else {
            callback();
        }
    }

    // Prepare minimal section fields data for save.
    // This will strip out all unneeded data from the save operation. This includes all metadata. Only send back
    // what is needed. The data contract is the same as what is returned from the GET method, as it follows the
    // resource based model - in that we GET a section, data bind and modify it, and then PUT that section
    // back (with unneeded stuff stripped out)
    private prepareMinimalSectionFieldsForSave(sectionFields: SectionField[]): MinSectionField[] {
        const minSectionFields: MinSectionField[] = [];

        // Note that when processing childFields this function will be recursed into.
        for (let i: number = 0; i < sectionFields.length; i++) {
            const minSectionField: MinSectionField = {
                id: sectionFields[i].id,
                fieldValue: sectionFields[i].fieldValue,
                fieldValueInternal: sectionFields[i].fieldValueInternal,
                childFields: !!sectionFields[i].childFields ?
                    this.prepareMinimalSectionFieldsForSave(sectionFields[i].childFields) : undefined
            };
            // If fieldValueInternal was not used, just remove it.
            if (!minSectionField.fieldValueInternal) {
                delete minSectionField.fieldValueInternal;
            }
            // If childFields was not used, just remove it.
            if (!minSectionField.childFields) {
                delete minSectionField.childFields;
            }
            minSectionFields.push(minSectionField);
        }

        return minSectionFields;
    }

    // Load the next section.
    private loadNextSection() {
        if (this.pageMetadataState.activeSectionIndex < this.pageMetadataState.displaySections.length - 1) {
            // Increment the active section index.
            this.pageMetadataState.activeSectionIndex++;
            // Get the new active section.
            const activeSection = this.pageMetadataState.activeSection;
            // Load the fields for the active section.
            this.loadSectionFields(activeSection);
        }
    }

    // Checks to see if the row contains any visible fields. If all fields are not visible then return false.
    // This informs the html binding that the row should be visible or not. A row with nothing in it creates a blank space that we don't want.
    public rowContainsVisibleFields(dispRow: DisplayRow): boolean {
        if (!!dispRow.displayColumns) {
            for (let c: number = 0; c < dispRow.displayColumns.length; c++) {
                if (!!dispRow.displayColumns[c].fields) {
                    for (let f: number = 0; f < dispRow.displayColumns[c].fields.length; f++) {
                        // Explicitly checking for false here (not null or undefined or true - as default visibility is true).
                        if (dispRow.displayColumns[c].fields[f].metadata.visible !== false) {
                            return true; // Yep, there is at least one visible field.
                        }
                    }
                }
            }
        }
        return false; // Got here, so no visible fields.
    }
}

import { HttpHandler, HttpRequest, HttpInterceptor, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AppSharedStateService } from 'app/common/services/app-shared-state.service';
import { environment } from 'environments/environment';

// Auth interceptor service which implements the HttpClient/HttpInterceptor interface.
// Uses AdalService/Adal library to acquire the token and add the Authorization header.
// Also adds the authorization header.
@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
    // Constructor for AuthInterceptorService.
    constructor(private appSharedStateService: AppSharedStateService) {
    }

    // HttpInterceptor intercept implementation.
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let resource: string = req.url;
        // By default the "resource" will match the request url. Typically, the "application id uri" is the same as the host name.
        // For example the base URL for the bank service might be:
        //      bankServiceBaseUrl: 'https://payeeonboardingdev.westcentralus.cloudapp.azure.com/BankFabric/BankService/'
        // But the "app id uri" could technically be something different entirely. The "app id uri" is nothing more
        // than a unique string the the Azure Active Directory tenant in the form of a uri.
        //      bankServiceAadAppUri: 'http://payeeonboardingdev.westcentralus.cloudapp.azure.com'
        // or:  bankServiceAadAppUri: 'http://some-unique-bank-service-uri'
        // or:  bankServiceAadAppUri: '249cc3df-0fbd-4f4f-ae0f-d32caedaee3d'
        // The last example being the client id of an application in the directory.
        // The following code will use the request url as the default resource. This is passed to adal.js to get
        // a token for that resource from AAD.
        // If the the request url matches one of the services we support, such as the bank service, then this code will swap out
        // the resource name with the corresponding "app id uri" from the environment config for that service.
        // Future to-do: Add other service urls (like for future tax service) to the below if condition.
        if (req.url.indexOf(environment.bankServiceBaseUrl) > -1) {
            // resource = environment.bankServiceAadAppUri;
        } else if (req.url.indexOf(environment.taxServiceBaseUrl) > -1) {
            // resource = environment.taxServiceAadAppUri;
        } else if (req.url.indexOf(environment.basicProfileServiceBaseUrl) > -1) {
            // resource = environment.basicProfileServiceAadAppUri;
        } else if (req.url.indexOf(environment.complianceServiceBaseUrl) > -1) {
            // resource = environment.complianceServiceAadAppUri;
        } else {
            // If the url is not going to one of the service endpoints, then just do nothing in this interceptor.
            return next.handle(req.clone());
        }

        const httpHeaders: {[name: string]: string | string[]} = {};
        // Add http headers for all api calls. Note that correlationid is another header added in the metadata-api.service.ts which is a per
        // call unique guid. See other comments about these values in the app-shared-state.service.ts.
        httpHeaders['sessionid'] = this.appSharedStateService.sessionId; // The user session id which was passed to us from NVA redirection.
        httpHeaders['referenceid'] = this.appSharedStateService.referenceId; // The reference id represents a guid that lasts for the users session.
        httpHeaders['userkey'] = this.appSharedStateService.userKey; // The user key which will uniquely identify this user.

        // If not using adal auth, return now.
        if (!environment.useAdalAuth) {
            return next.handle(req.clone({setHeaders: httpHeaders}));
        }

    }
}

import { ChildFieldsDynamic } from 'app/common/metadata-models/childFieldsDynamic';
import { SectionsDynamic } from 'app/common/metadata-models/sectionsDynamic';
import { DropdownOptionDynamic } from 'app/common/metadata-models/dropdownOptionDynamic';
import { ControlOptions } from 'app/common/metadata-models/controlOptions';
import { FieldMetadata } from 'app/common/metadata-models/fieldMetadata';
import { SectionField } from 'app/common/metadata-models/sectionField';
import { ControlType } from 'app/common/metadata-models/controlType';
import { DropdownOption } from 'app/common/metadata-models/dropdownOption';

// Mock data used for development and testing.
export class MockSectionFields {
    public sectionFields_BankProfileDetails_1: SectionField[] = [
        new SectionField({
            id: 'profileName',
            fieldValue: 'sample profile name',
            metadata: new FieldMetadata({
                fieldGroup: 'summary',
                columnHint: 1,
                required: true,
                label: 'Profile name',
                readOnly: false,
                ariaLabel: 'Profile name',
                fieldInfo: 'A unique name to identify each payment profile.',
                control: ControlType.textbox,
                canDirtyNextSections: false,
                controlOptions: new ControlOptions({
                    maxLength: 50,
                    regExPattern: '[A-Za-z0-9 ]+',
                    placeholder: 'Enter text',
                    helpText: ''
                })
            })
        }),
        new SectionField({
            id: 'bankAccountLocation',
            fieldValue: 2,
            metadata: new FieldMetadata({
                fieldGroup: 'summary',
                columnHint: 1,
                required: true,
                label: 'Bank account location',
                readOnly: false,
                ariaLabel: 'Bank account location',
                fieldInfo: 'The country in which your bank is located.',
                control: ControlType.dropdown,
                canDirtyNextSections: true,
                controlOptions: new ControlOptions({
                    nullSelectionText: 'Select location',
                    dropdownOptionsStatic: [
                        new DropdownOption({ text: 'Afghanistan', value: 0 }),
                        new DropdownOption({ text: 'Aland Islands', value: 1 }),
                        new DropdownOption({ text: 'Albania', value: 2 }),
                        new DropdownOption({ text: 'Algeria', value: 3 }),
                        new DropdownOption({ text: 'Italy', value: 4 }),
                        new DropdownOption({ text: 'United States', value: 5 }),
                        new DropdownOption({ text: 'India', value: 6 })
                    ]
                })
            })
        }),
        new SectionField({
            id: 'paymentCurrency',
            fieldValue: 'USD',
            metadata: new FieldMetadata({
                fieldGroup: 'summary',
                columnHint: 2,
                required: true,
                label: 'Disbursement currency',
                readOnly: false,
                ariaLabel: 'Disbursement currency',
                fieldInfo: 'The currency that you select will be used to send payments.',
                control: ControlType.dropdown,
                canDirtyNextSections: false,
                controlOptions: new ControlOptions({
                    nullSelectionText: 'Select currency',
                    dropdownOptionsDynamic: new DropdownOptionDynamic({
                        drivenByFieldId: 'bankAccountLocation',
                        httpVerb: 'GET',
                        api: 'Sections/{sectionId}/Fields/{fieldId}/DropdownValues?changedFieldId={changedFieldId}&changedFieldValue={changedFieldValue}'
                    })
                })
            })
        }),
        new SectionField({
            id: 'paymentMethod',
            fieldValue: 'EBT',
            sectionsDynamic: new SectionsDynamic({
                httpVerb: 'GET',
                api: 'DynamicSections/{sectionId}/Fields/{fieldId}/ChildFields?fieldValue={fieldValue}'
            }),
            metadata: new FieldMetadata({
                fieldGroup: 'summary',
                columnHint: 2,
                required: true,
                label: 'Payment method',
                readOnly: false,
                ariaLabel: 'Payment method',
                fieldInfo: 'The payment method Microsoft will use.',
                control: ControlType.dropdown,
                canDirtyNextSections: false,
                controlOptions: new ControlOptions({
                    nullSelectionText: 'Select method',
                    dropdownOptionsStatic: [
                        new DropdownOption({ text: 'Electronic Bank Transfer', value: 'EBT' }),
                        new DropdownOption({ text: 'Check', value: 'CHK' })
                    ]
                })
            })
        })
    ];

    // Make sure the fieldValue for each of these fields is null as the mockSections.ts has isSaved = false for this section.
    public sectionFields_BankAccountDetails_1: SectionField[] = [
        new SectionField({
            id: 'validateIban',
            fieldValue: null,
            childFieldsDynamic: new ChildFieldsDynamic({
                httpVerb: 'GET',
                api: 'Sections/{sectionId}/Fields/{fieldId}/ChildFields?fieldValue={fieldValue}'
            }),
            childFields: null,
            metadata: new FieldMetadata({
                fieldGroup: '',
                columnHint: 1,
                required: true,
                label: 'IBAN number',
                readOnly: false,
                ariaLabel: 'IBAN number',
                fieldInfo: 'The International Bank Account Number (IBAN) is an internationally agreed means of identifying bank accounts across national borders. Example format for United Kingdom: ; GBkkbbbbsssssscccccccc ("country code", "check digit", "bank code", "branch code", "account number").',
                control: ControlType.textbox,
                canDirtyNextSections: false,
                controlOptions: new ControlOptions({
                    maxLength: 28,
                    regExPattern: '^[bB][yY][a-zA-Z0-9]{26}$',
                    placeholder: 'Enter text',
                    helpText: '28-alphanumeric characters. Format: BYkkbbbbaaaacccccccccccccccc'
                })
            })
        }),
        new SectionField({
            id: 'demo',
            fieldValue: null,
            metadata: new FieldMetadata({
                fieldGroup: '',
                columnHint: 1,
                required: true,
                label: 'example 1 2 3 hello there',
                readOnly: false,
                ariaLabel: 'example 1 2 3 hello there',
                fieldInfo: '',
                control: ControlType.htmlalert,
                canDirtyNextSections: false,
                controlOptions: new ControlOptions({
                    cssClass: "validation-error-text"
                })
            })
        }),
        new SectionField({
            id: 'accountNumber',
            fieldValue: null,
            metadata: new FieldMetadata({
                fieldGroup: '',
                columnHint: 1,
                required: true,
                label: 'Account number',
                readOnly: false,
                ariaLabel: 'Account number',
                fieldInfo: '',
                control: ControlType.textbox,
                canDirtyNextSections: false,
                controlOptions: new ControlOptions({
                    maxLength: 22,
                    regExPattern: '[A-Za-z0-9]+',
                    placeholder: 'Enter text',
                    helpText: '',
                    passwordMask: true
                })
            })
        }),
        new SectionField({
            id: 'confirmAccountNumber',
            fieldValue: null,
            metadata: new FieldMetadata({
                fieldGroup: '',
                columnHint: 1,
                required: true,
                label: 'Confirm account number',
                readOnly: false,
                ariaLabel: 'Confirm account number',
                fieldInfo: '',
                control: ControlType.textbox,
                canDirtyNextSections: false,
                controlOptions: new ControlOptions({
                    maxLength: 22,
                    regExPattern: '[A-Za-z0-9]+',
                    placeholder: 'Enter text',
                    helpText: '',
                    mustEqualFieldId: 'accountNumber',
                    passwordMask: true
                })
            })
        })
    ];
}

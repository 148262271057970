<div class="modal" tabindex="-1" role="dialog" data-backdrop="false" data-keyboard="true" [ngStyle]="{'display':display}" [attr.aria-label]="lms.get('ALERT_HEADING')" aria-describedby="alertMessage">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="dialog">
        <div class="modal-content">
            <div class="modal-header">
                <div class="alert alert-full-width"
                    [ngClass]="{'alert-warning': messageData.alertType == AlertTypeRef.Warning, 'alert-info': messageData.alertType == AlertTypeRef.Information, 'alert-danger': messageData.alertType == AlertTypeRef.Error, 'alert-success': messageData.alertType == AlertTypeRef.Success}"
                    [ngSwitch]="messageData.alertType">
                    <strong *ngSwitchCase="AlertTypeRef.Warning">
                        {{lms.get('WARNING_HEADING')}}
                    </strong>
                    <strong *ngSwitchCase="AlertTypeRef.Information">
                        {{lms.get('INFORMATION_HEADING')}}
                    </strong>
                    <strong *ngSwitchCase="AlertTypeRef.Success">
                        {{lms.get('SUCCESS_HEADING')}}
                    </strong>
                    <strong *ngSwitchCase="AlertTypeRef.Error">
                        {{lms.get('ERROR_HEADING')}}
                    </strong>
                </div>
            </div>
            <div class="modal-body">
                <p id="alertMessage" [innerHTML]="htmlTransform(messageData.message)"></p>
            </div>
            <div class="modal-footer">
                <button
                    id='messageDisplayCloseButton'
                    attr.data-tracking="{{instrumentationService.trackedElements.infoCloseButton}}"
                    attr.data-trackinginfo="Info dialog: {{messageData.message}}"
                    type="button"
                    class="btn btn-default"
                    (click)="onCloseClicked()">{{lms.get('CLOSE')}}
                </button>
            </div>
        </div>
    </div>
</div>

export const environment = {
    environmentName: 'PPE',
    production: false,
    useLocalMockData: false,
    useLocalMockDataSimulatedDelay: 500,
    useRemoteMockData: false,
    displayDevTools: true,
    displayLanguageSelector: false,
    routerEnableTracing: true,
    debugDisplayFieldJson: false,
    useAdalAuth: false, // Disabled for now, relying solely on SessionId. Will be re-enabled later.
    appInsightsInstrumentationKey: 'bdbda20e-20e1-4b8c-a9fd-6e189ee5cc6f',
    bankServiceBaseUrl: 'https://bankprofiledev-aeehbdbsgxgrbmbh.z01.azurefd.net',
    taxServiceBaseUrl: 'https://payeeonboardingdev30.westus2.cloudapp.azure.com/TaxFabric/PayeeOnboarding.TaxService/',
    basicProfileServiceBaseUrl: 'https://payeeonboardingdev30.westus2.cloudapp.azure.com/BasicFabric/BasicProfileService/',
    complianceServiceBaseUrl: 'https://payeeonboardingdev30.westus2.cloudapp.azure.com/ComplianceFabric/ComplianceService/',
};

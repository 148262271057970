import { MiscUtil } from 'app/common/utility/miscUtil';

export interface DisplayAlert {
    type: 'information' | 'error' | 'warning' | 'success';
    message: string;
}

// Represents an error returned from the service (bank, tax, others).
//
// Note this is not the same thing as an HttpErrorResponse object which is part of Angular and is returned
// for certain error conditions. As of right now, those conditions include errors relating to Authentication and
// Authorization. Winston says those error conditions happen prior to him being able to handle it in code on the
// server. If he is able to handle it in code in the future then those error objects will change to be these
// ServiceError objects as well. Follow up with Winston later as to the status of this.
//
// Example error:
//   {"code":"Unauthorized","message":"AuthHandler Error","target":"AuthHandler","details":"ReferenceId: ec2989b9-49db-4426-a64a-acb4127268c7,"referenceId":"ec2989b9-49db-4426-a64a-acb4127268c7"}
export class ServiceError {
    public code: string;
    public message: string;
    public target: string;
    public details: string;
    public displayAlert?: DisplayAlert;
    // public referenceId: string; // todo: Add back once Winston adds it on the server.

    // Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
    constructor(jsonData: any) {
        this.code = jsonData.code;
        this.message = jsonData.message;
        this.target = jsonData.target;
        this.details = jsonData.details;
        this.displayAlert = jsonData.displayAlert;
        // this.referenceId = jsonData.referenceId; // todo: Add back once Winston adds it on the server.
    }

    // Check if the jsonData has properties matching this type.
    public static checkIfJsonMatchesType(jsonData: any): boolean {
        if (!jsonData) {
            return false;
        } else if (MiscUtil.hasProperty(jsonData, 'code') &&
            MiscUtil.hasProperty(jsonData, 'message') &&
            MiscUtil.hasProperty(jsonData, 'target') &&
            MiscUtil.hasProperty(jsonData, 'details') /* &&
            MiscUtil.hasProperty(jsonData, 'referenceId') */) /* todo: Add back once Winston adds it on the server. */ {
            return true;
        } else {
            return false;
        }
    }
}

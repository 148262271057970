<div>
    <!-- z-index of this font-awesome icon will be under the below open-close-icon which is at the same absolute position.
         This is done to fix an undesirable effect when mousing over a font-awesome font, the mouse over/leave events were
         firing within the font itself in different spots. -->
    <div class="fas fa-cog open-close-icon" id="fa-gear-icon"></div>
    <div class="open-close-icon" id="open-close-icon" (mouseover)="mouseOverGearIcon()" (mouseout)="mouseOutGearIcon()" (click)="toggleExpandClicked()"></div>
    <div class="legacy-pop-icon" id="legacy-pop-icon" (mouseover)="mouseOverLegacyPop()" (mouseout)="mouseOutLegacyPop()" (click)="legacyPopClicked()" *ngIf="!!legacyPopLink"></div>
</div>

<div id="dev-tools-hover" class="hover-subtext">
    Click to open/close developer tools. This will only show in non-production environments.
</div>

<div id="legacy-pop-hover" class="hover-subtext">
    Click to open this page in legacy POP. Destination: {{legacyPopLink}}
</div>

<div id="dev-tools-content" class="dev-tools-content">
    <!-- Using Kendo controls with Angular, see: https://www.telerik.com/kendo-angular-ui/components -->
    <!-- Note: Not localizing the dev-tools component. -->
    <kendo-tabstrip [ngStyle]="{'height': '300px', 'margin': '10px'}">
        <kendo-tabstrip-tab [selected]="true"> <!-- Tab titles set in code (with icon). -->
            <ng-template kendoTabContent>
                <div>
                    <span><span class="environment-config-text">{{environmentName}}</span> environment config settings</span>
                    <div class="environment-config-container">
                        <kendo-grid [data]="environmentConfigData" [height]="224">
                            <kendo-grid-column field="setting" title="Setting" width="225" [style]="{'padding': '.34rem'}" [headerStyle]="{'padding': '.34rem'}">
                            </kendo-grid-column>
                            <kendo-grid-column field="value" title="Value" [style]="{'padding': '.34rem'}" [headerStyle]="{'padding': '.34rem'}">
                            </kendo-grid-column>
                        </kendo-grid>
                    </div>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab>
            <ng-template kendoTabContent>
                <div *ngIf="!user || (!!user && !user.authenticated)">
                    User is not authenticated.
                </div>
                <div *ngIf="!!user && user.authenticated">
                    <span class="jwt-claims-text">JWT claims for:</span>
                    <kendo-dropdownlist
                        [data]="claimDataDropdownItems"
                        [textField]="'endpoint'"
                        [valueField]="'endpoint'"
                        [(ngModel)]="selectedClaimDataDropdownItem"
                        [valuePrimitive]="false"  
                        (valueChange)="claimDataValueChange($event)"
                        class="jwt-dropdownlist">
                        <ng-template kendoDropDownListItemTemplate let-dataItem>
                            <span class="dropdown-item-template">{{ !!dataItem ? dataItem.resourceName + " - " + dataItem.endpoint : '' }}</span>
                        </ng-template>
                        <ng-template kendoDropDownListValueTemplate let-dataItem>
                            <span class="dropdown-item-template">{{ !!dataItem ? dataItem.resourceName + " - " + dataItem.endpoint : '' }}</span>
                        </ng-template>
                    </kendo-dropdownlist>
                    <span class="adal-disabled-text" *ngIf="!useAdalAuth">NOTE: useAdalAuth is disabled in environment config (no endpoints shown)</span>
                    <div>
                        <span>Bearer token:</span>
                        <input type="text" class="bearer-token-input" readonly value="{{selectedEndpointBearerToken}}"/>
                    </div>
                    <div class="claims-grid-container">
                        <kendo-grid [data]="selectedClaimDataDropdownItem.claims" [height]="190">
                            <kendo-grid-column field="claimKey" title="Claim" width="225" [style]="{'padding': '.34rem'}" [headerStyle]="{'padding': '.34rem'}">
                            </kendo-grid-column>
                            <kendo-grid-column field="value" title="Value" [style]="{'padding': '.34rem'}" [headerStyle]="{'padding': '.34rem'}">
                            </kendo-grid-column>
                        </kendo-grid>
                    </div>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab>
            <ng-template kendoTabContent>
                <div>
                    <!-- Display prettified JSON Metadata for the loaded metadata driven page. -->
                    <div class="metadata-text">Metadata</div>
                    <pre>{{miscUtil.prettifyJson(pageMetadataState.displaySections)}}</pre>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab>
            <ng-template kendoTabContent>
                <div *ngIf="!useLocalMockData">
                    <div>Not configured to use local mock data.</div>
                </div>
                <div *ngIf="useLocalMockData">
                    <div>
                        <div class="mock-data-text">Choose mock data:</div>
                        <kendo-dropdownlist
                            [data]="mockDataDropdownItems"
                            [defaultItem]="mockDataNothingSelected"
                            [textField]="'description'"
                            [valueField]="'id'"
                            [(ngModel)]="selectedMockDataDropdownItem"
                            [valuePrimitive]="false"
                            (valueChange)="mockDataValueChange($event)"
                            class="mock-data-dropdownlist">
                            <ng-template kendoDropDownListItemTemplate let-dataItem>
                                <span class="dropdown-item-template">{{ !!dataItem ? dataItem.description: '' }}</span>
                            </ng-template>
                            <ng-template kendoDropDownListValueTemplate let-dataItem>
                                <span class="dropdown-item-template">{{ !!dataItem ? dataItem.description: '' }}</span>
                            </ng-template>
                        </kendo-dropdownlist>
                    </div>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
    </kendo-tabstrip>
</div>

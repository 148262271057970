import { ChildFieldsDynamic } from 'app/common/metadata-models/childFieldsDynamic';
import { DropdownOptionDynamic } from 'app/common/metadata-models/dropdownOptionDynamic';
import { ControlOptions } from 'app/common/metadata-models/controlOptions';
import { FieldMetadata } from 'app/common/metadata-models/fieldMetadata';
import { SectionField } from 'app/common/metadata-models/sectionField';
import { ControlType } from 'app/common/metadata-models/controlType';
import { DropdownOption } from 'app/common/metadata-models/dropdownOption';

// Mock data used for development and testing.
export class MockSectionFields {
    public sectionFields_Sample_1: SectionField[] = [
        new SectionField({
            id: 'sample',
            fieldValue: 'sample',
            metadata: new FieldMetadata({
                fieldGroup: 'sample',
                columnHint: 1,
                required: true,
                label: 'sample',
                readOnly: false,
                ariaLabel: 'sample',
                fieldInfo: 'sample',
                control: ControlType.textbox,
                controlOptions: new ControlOptions({
                    maxLength: 50,
                    regExPattern: '[A-Za-z0-9 ]+',
                    placeholder: 'Enter text',
                    helpText: ''
                })
            })
        })
    ];
}

<div *ngIf="!!fieldData">
    <label for="{{fieldData.id}}" class="control-label">
        <span [innerHTML]="htmlTransform(fieldData.metadata.label)"></span>
        <span *ngIf="fieldData.metadata.required" class="required">*</span>
        <img *ngIf="!!fieldData.metadata.fieldInfo || !!fieldData.metadata.controlOptions.helpText" class="tip-icon" src="assets/common/info-icon.svg" 
            placement="right" [ngbTooltip]="tipContent" tabindex="0" alt="{{fieldData.metadata.fieldInfo}}{{fieldData.metadata.controlOptions.helpText}}"/>
    </label>
    
    <!-- Tooltip content used with above icon. Note this same block of html exists on other control(s) like upload. -->
    <ng-template #tipContent>
        <div>
            <div *ngIf="!!fieldData.metadata.fieldInfo && fieldData.metadata.fieldInfo.length > 0">{{fieldData.metadata.fieldInfo}}</div>
            <hr *ngIf="!!fieldData.metadata.fieldInfo && fieldData.metadata.fieldInfo.length > 0 && !!fieldData.metadata.controlOptions.helpText && fieldData.metadata.controlOptions.helpText.length > 0" style="margin: 8px 0 8px 0; height: 1px;"> <!-- spacer line between elems if both exist -->
            <div *ngIf="!!fieldData.metadata.controlOptions.helpText && fieldData.metadata.controlOptions.helpText.length > 0">{{fieldData.metadata.controlOptions.helpText}}</div>
        </div>
    </ng-template>

    <br/>
    <div class="control-group">
        <div class="control-spinner-wrapper">
            <!-- https://www.telerik.com/kendo-angular-ui/components/inputs/textbox/ -->
            <input
                #inputElem
                kendoTextBox
                id="{{fieldData.id}}" 
                name="{{fieldData.id}}"
                class="form-control control-input" 
                placeholder="{{fieldData.metadata.controlOptions.placeholder}}"
                type="{{fieldData.metadata.controlOptions.passwordMask ? 'password' : 'text'}}"
                [required]="!!fieldData.metadata.required ? fieldData.metadata.required : false"
                [pattern]="!!fieldData.metadata.controlOptions.regExPattern ? fieldData.metadata.controlOptions.regExPattern : ''"
                appInputMustEqualValidator="{{fieldData.metadata.controlOptions.mustEqualFieldId}}"
                [maxlength]="fieldData.metadata.controlOptions.maxLength"
                [minlength]="fieldData.metadata.controlOptions.minLength"
                [attr.aria-label]="fieldData.metadata.ariaLabel + fieldData.metadata.fieldInfo + fieldData.metadata.controlOptions.helpText"
                [disabled]="!!fieldData.metadata.readOnly"
                [(ngModel)]="fieldData.fieldValue"
                (change)="onChange($event)"
                (keyup)="onKeyUp($event)"
                (blur)="onBlur($event)"
            />

            <!--
                Notes:
                Regarding validation attributes including required, pattern, and the custom appInputMustEqualValidator:
                See that these attributes are referenced HERE on this input element. This will cause THIS input element to go invalid
                or valid. That is, a red box will appear around the input box if it is invalid. However THIS input box is
                not the control on the form itself (we are using Angular template driven forms and not dynamic forms in this
                app). These same validation attributes are ALSO present up on the bank.component.html on the instance of
                the "<app-textbox>" - it is that control (instance of this control) which is on the form which in turn cause
                the form to become valid or invalid, which in turn causes the "Next" button to become enabled/disabled.
            -->

            <div class="spinner-container">
                <div *ngIf="(!!fieldData.childFieldsDynamic && fieldData.childFieldsDynamic.isReloading) || (!!fieldData.sectionsDynamic && fieldData.sectionsDynamic.isReloading)">
                    <span class='fas fa-spinner fa-pulse' aria-hidden='true'></span>
                </div>
            </div>
        </div>

        <div class="must-equal-field-notice" *ngIf="showMustEqualFieldNotice">
            {{lms.get('MUST_EQUAL_FIELD')}} {{mustEqualFieldLabel}}
        </div>
    </div>
</div>

import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MetadataJsonSchemaValidatorService } from 'app/common/services/metadata-json-schema-validator.service';
import { Section } from 'app/common/metadata-models/section';
import { IMetadataApiService, SupportedMicroservice } from 'app/common/services/metadata-api.service';
import { SectionField, MinSectionField } from 'app/common/metadata-models/sectionField';
import { PageMetadataState } from 'app/common/utility/page-metadata-state';
import { ChildFieldsDynamic } from 'app/common/metadata-models/childFieldsDynamic';
import { SectionsDynamic } from 'app/common/metadata-models/sectionsDynamic';
import { DropdownOption } from 'app/common/metadata-models/dropdownOption';
import { DropdownOptionDynamic } from 'app/common/metadata-models/dropdownOptionDynamic';
import { ControlType } from 'app/common/metadata-models/controlType';
import { ControlsExampleMockDataProvider } from 'app/pages/tax/mockData/controlsExample/controlsExampleMockDataProvider';
import { PayFromIrelandResidenceIrelandMockDataProvider } from 'app/pages/tax/mockData/payFromIrelandResidenceIreland/payFromIrelandResidenceIrelandMockDataProvider';
import { PayFromArgentinaResidenceArgentinaMockDataProvider } from 'app/pages/tax/mockData/payFromArgentinaResidenceArgentina/payFromArgentinaResidenceArgentinaMockDataProvider';
import { SessionData } from 'app/common/metadata-models/sessionData';

export enum TaxMockData {
    NothingSelected,
    ControlsExample,
    PayFromIrelandResidenceIreland,
    PayFromArgentinaResidenceArgentina
}

export const TaxMockDataDescription: { [key: string]: string; } = {
    controlsExample: 'Controls example',
    payFromIrelandResidenceIreland: 'Pay from Ireland / residence Ireland',
    payFromArgentinaResidenceArgentina: 'Pay from Argentina / residence Argentina'
};

// Mock data provider for tax data.
@Injectable()
export class TaxMockDataProviderService implements IMetadataApiService {
    public selectedTaxMockData: TaxMockData;
    private mockDataProvider: IMetadataApiService;

    // TaxMockDataProviderService constructor.
    constructor(private metadataJsonValidator: MetadataJsonSchemaValidatorService, private router: Router) {
        this.changeMockData(TaxMockData.NothingSelected);
    }

    // Change mock data.
    public changeMockData(taxMockData: TaxMockData, forceNavigation: boolean = false) {
        const setMockProvider: (taxMockData: TaxMockData) => void = () => {
            this.selectedTaxMockData = taxMockData;
            switch (taxMockData) {
                case TaxMockData.NothingSelected:
                    return;
                case TaxMockData.ControlsExample:
                    this.mockDataProvider = new ControlsExampleMockDataProvider(this.metadataJsonValidator);
                    break;
                case TaxMockData.PayFromIrelandResidenceIreland:
                    this.mockDataProvider = new PayFromIrelandResidenceIrelandMockDataProvider(this.metadataJsonValidator);
                    break;
                case TaxMockData.PayFromArgentinaResidenceArgentina:
                    this.mockDataProvider = new PayFromArgentinaResidenceArgentinaMockDataProvider(this.metadataJsonValidator);
                    break;
            }
        };

        if (forceNavigation) {
            this.router.navigateByUrl('tax').then(() => {
                setMockProvider(taxMockData);
            });
        } else {
            setMockProvider(taxMockData);
        }
    }

    // Configure service.
    public configureService(microService: SupportedMicroservice, baseUrl: string, apiVersionParam: string, apiVersionValue: string) {
        return this.mockDataProvider.configureService(microService, baseUrl, apiVersionParam, apiVersionValue);
    }

    // Get sections.
    public getSections(): Observable<Section[]> {
        if (this.selectedTaxMockData === TaxMockData.NothingSelected) {
            return of([new Section({ sectionName: 'No mock data selected', id: 'x', isSaved: false }) ]);
        }
        return this.mockDataProvider.getSections();
    }

    // Get section data for a given section.
    public getSectionFields(sectionId: string): Observable<SectionField[]> {
        if (this.selectedTaxMockData === TaxMockData.NothingSelected) {
            return of([new SectionField({ id: 'x', fieldValue: '', metadata: {label: 'No mock data selected', control: ControlType.label}}) ]);
        }
        return this.mockDataProvider.getSectionFields(sectionId);
    }

    // Put (save) section data for a given section.
    public putSectionFields(sectionId: string, minSectionFields: MinSectionField[]): Observable<Object> {
        return this.mockDataProvider.putSectionFields(sectionId, minSectionFields);
    }

    // Get dynamic dropdown data.
    public getDynamicDropdownData(dropdownOptionsDynamic: DropdownOptionDynamic, fieldId: string, changedFieldId: string,
        changedFieldValue: number|string|boolean, pageMetadataState: PageMetadataState): Observable<DropdownOption[]> {
        return this.mockDataProvider.getDynamicDropdownData(dropdownOptionsDynamic, fieldId, changedFieldId, changedFieldValue, pageMetadataState);
    }

    // Get dynamic child fields.
    public getDynamicChildFields(childFieldsDynamic: ChildFieldsDynamic, fieldId: string, changedFieldValue: number|string|boolean,
        pageMetadataState: PageMetadataState): Observable<SectionField[]> {
        return this.mockDataProvider.getDynamicChildFields(childFieldsDynamic, fieldId, changedFieldValue, pageMetadataState);
    }

    // Get dynamic child fields.
    public getDynamicSections(sectionsDynamic: SectionsDynamic, fieldId: string, changedFieldValue: number|string|boolean,
        pageMetadataState: PageMetadataState): Observable<Section[]> {
        return of(null);
    }

    // Get session data.
    public getSessionData(): Observable<SessionData> {
        return this.mockDataProvider.getSessionData();
    }
}

import { Input, ChangeDetectorRef, Directive } from '@angular/core';
import { FormControl, NgForm, ControlValueAccessor } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Section } from 'app/common/metadata-models/section';
import { SectionField } from 'app/common/metadata-models/sectionField';
import { DisplaySection } from 'app/common/metadata-models/displaySection';
import { FieldWatcherService } from 'app/common/services/field-watcher.service';
import { MetadataApiService } from 'app/common/services/metadata-api.service';
import { MiscUtil } from 'app/common/utility/miscUtil';
import { PageMetadataState } from 'app/common/utility/page-metadata-state';
import { environment } from 'environments/environment';
import { LocalizationManagerService } from 'app/common/services/localization-manager.service';
import { AppSharedStateService } from 'app/common/services/app-shared-state.service';
import { AppSharedUtilityService } from 'app/common/services/app-shared-utility.service';
import { MessageData } from 'app/common/components/message-display/message-data';
import { AlertType } from 'app/common/components/message-display/alert-type';
import { LoadingScreenData } from 'app/common/components/loadingscreen-display/loadingscreen-data';
import { LoadingScreenType } from 'app/common/components/loadingscreen-display/loadingscreen-type';

// Abstract base control that all controls should derive from.
@Directive()
export abstract class BaseControl implements ControlValueAccessor {
    // Note that the below fieldData is a SectionField. In the future with other screens like Tax, then
    // this same variable can be extended to support TaxSectionField too. Or we could come up with a base
    // class common to both.
    @Input('fieldData') public fieldData: SectionField; // Must be public as it is bound in html.
    @Input('form') public form: NgForm;
    @Input('pageMetadataState') public pageMetadataState: PageMetadataState;

    // Constructor for BaseControl.
    constructor(
        protected fieldWatcherService: FieldWatcherService,
        protected metadataApiService: MetadataApiService,
        protected changeDetectorRef: ChangeDetectorRef,
        protected domSanitizer: DomSanitizer,
        protected appSharedStateService: AppSharedStateService,
        protected appSharedUtilityService: AppSharedUtilityService,
        public lms: LocalizationManagerService /* Using short name on purpose for data binding. */) {
    }

    // Use of ControlValueAccessor explained in article below.
    // Since this control component (and all other control components) are what are actually sitting inside the form
    // of the parent component (such as bank/tax component), the internal controls defined in this component are not
    // directly available to the form. The ControlValueAccessor creates a 'tunnel' to allow the form to communicate
    // changes and allow the local model to perform any custom handling and communicate changes back to the form.
    // https://medium.com/@tarik.nzl/angular-2-custom-form-control-with-validation-json-input-2b4cf9bc2d73

    // Placeholders for the callbacks which are later provided by the Control Value Accessor.
    private onTouchedCallback: () => void = () => {};
    private onChangeCallback: (_: any) => void = () => {};

    // Part of ControlValueAccessor interface implementation.
    // Write a new value to the element.
    public writeValue(value: number|string|boolean) {
        if (value !== this.fieldData.fieldValue) {
            this.fieldData.fieldValue = value;
        }
    }

    // Part of ControlValueAccessor interface implementation.
    // Set the function to be called when the control receives a change event.
    public registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    // Part of ControlValueAccessor interface implementation.
    // Set the function to be called when the control receives a touch event.
    public registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    // Get the FormControl for this control.
    protected getFormControl(): FormControl {
        return <FormControl>this.form.controls[this.fieldData.id];
    }

    // Add field watchers that all user interactive controls should use.
    protected addCommonFieldWatchers() {
        this.addFieldWatcherForSelf();
        this.addFieldWatcherForDynamicChildren();
        this.addFieldWatcherForDynamicSections();
    }

    // Add field watcher using fieldWatcherService to monitor changes to this (derived class) control.
    protected addFieldWatcherForSelf() {
        // When any change gets made to this control then this section is considered "dirty" and shall
        // invalidate all already loaded sections after this one. Some input controls cause subsequent
        // sections to render differently. For example, certain pay to countries will display an IBAN
        // or a Bank Code in the Bank Account section but not both.

        // Watch for changes to the (our own instance) field using the field watcher service.
        this.fieldWatcherService.addFieldWatcher(
            this.fieldData.id,
            (changedField: SectionField) => {
                if (!this.pageMetadataState.sectionFieldsLoading) {
                    console.log(`Field ${this.fieldData.id} changed value: ${changedField.fieldValue}`);
                    // Mark the section as dirty. This is used to know if we need to save data.
                    this.pageMetadataState.activeSection.isDirty = true;

                    let notifyUserOfDataReset: boolean = false;

                    // Remove loaded fields from all subsequent sections.
                    // Also remove any field watchers for the fields being removed.
                    for (let i: number = this.pageMetadataState.activeSectionIndex + 1; i < this.pageMetadataState.displaySections.length; i++) {
                        const section: DisplaySection = this.pageMetadataState.displaySections[i];
                        if (((!!section.sectionFields && section.sectionFields.length > 0) || section.isSaved) && changedField.metadata.canDirtyNextSections) {
                            // todo: It would be good to be more smart about this, if there were metadata on the field that indicated it may cause a
                            // change to subsequent sections then set this to true, otherwise false. Need to update metadata and services.
                            notifyUserOfDataReset = true;
                            if (!!section.sectionFields) {
                                for (let f: number = 0; f < section.sectionFields.length; f++) {
                                    const field: SectionField = section.sectionFields[f];
                                    this.fieldWatcherService.clearFieldWatchersForField(field.id);
                                }
                            }
                            // Reset the section.
                            section.reset();
                        }
                    }

                    if (notifyUserOfDataReset) {
                        // Warn user of change impact.
                        const messageData: MessageData = {
                            message: this.lms.get('CHANGE_WARNING'),
                            alertType: AlertType.Information
                        };
                        this.appSharedUtilityService.displayMessage(messageData);
                    }
                }
            }
        );
    }

    // Add field watcher using fieldWatcherService for dynamic children.
    // This is to handle when a control metadata has this configuration:
    //     childFieldsDynamic: {
    //         httpVerb: 'GET',
    //         api: 'validateIban'
    //     },
    //     childFields: null
    // If so, then we need to watch for changes to our own field value. When changes occur, then we need to call
    // the api specified in the childFieldsDynamic metadata. The value we pass to that api will be the changed
    // field value. That api should then return more control metadata which will then be placed in the childFields
    // property as a child array. The UI should then display those child fields using associated metadata.
    protected addFieldWatcherForDynamicChildren() {
        // If there is no childFieldsDynamic then do nothing and return.
        if (!this.fieldData.childFieldsDynamic) {
            return;
        }

        // If the fieldValue has no value (null or undefined or empty string) then just set
        // childFieldsDynamic.initialLoadComplete to true.
        if (MiscUtil.isNullOrUndefinedOrEmptyString(this.fieldData.fieldValue)) {
            this.fieldData.childFieldsDynamic.initialLoadComplete = true;
        }

        // Watch for changes to the (our own instance) field using the field watcher service.
        this.fieldWatcherService.addFieldWatcher(
            this.fieldData.id,
            (changedField: SectionField) => {
                // Set the childFieldsDynamic.isReloading flag to true only if this is not the first load of dynamic children.
                // Intention here is during initial load this stays false, and when the user changes the control value
                // that will cause a reload of this controls children.
                if (this.fieldData.childFieldsDynamic.initialLoadComplete) {
                    this.fieldData.childFieldsDynamic.isReloading = true;
                } else {
                    this.fieldData.childFieldsDynamic.isReloading = false;
                }

                const formControl: FormControl = this.getFormControl();
                // If the field is valid.
                if (formControl !== null && formControl !== undefined && formControl.valid) {
                    setTimeout(() => {
                        // If the initial load is not yet complete AND the field already has child fields present (that is, they
                        // were already present in this parent field as children during initial load), then do not make another
                        // call to get dynamic child fields right now - as the child fields are already present.
                        if (!this.fieldData.childFieldsDynamic.initialLoadComplete && !!this.fieldData.childFields && this.fieldData.childFields.length > 0) {
                            console.log(`Field ${this.fieldData.id} is valid. Not getting new children as they were already present during initial load.`);
                            // Notify that child fields were modified (even though they came down on the initial load).
                            // The rendering component listens to this notification so it will render these fields.
                            this.fieldData.notifyChildFieldsModified();
                            // Set the childFieldsDynamic.initialLoadComplete flag to true.
                            this.fieldData.childFieldsDynamic.initialLoadComplete = true;
                            // Set the childFieldsDynamic.isReloading flag to false.
                            this.fieldData.childFieldsDynamic.isReloading = false;
                        } else {
                            console.log(`Field ${this.fieldData.id} is valid. Getting dynamic children.`);
                            // Open loading screen to freeze user focus while dynamic fields are loading
                            this.openLoadingScreen();
                            // Call api specified in the metadata to get child fields.
                            this.metadataApiService.getDynamicChildFields(this.fieldData.childFieldsDynamic, this.fieldData.id, this.fieldData.fieldValue, this.pageMetadataState)
                                .subscribe((childFields: SectionField[]) => {
                                    // Append the returned child fields to our childFields property.
                                    this.fieldData.childFields = childFields;
                                    // Notify that child fields were modified.
                                    this.fieldData.notifyChildFieldsModified();
                                    // Set the childFieldsDynamic.initialLoadComplete flag to true.
                                    this.fieldData.childFieldsDynamic.initialLoadComplete = true;
                                    // Set the childFieldsDynamic.isReloading flag to false.
                                    this.fieldData.childFieldsDynamic.isReloading = false;
                                    // Set the childFieldsDynamic.userChangedOwningField flag to false.
                                    this.fieldData.childFieldsDynamic.userChangedOwningField = false;
                                    // Close loading field and set focus to field
                                    this.closeLoadingScreenAndReFocus(this.fieldData.id);
                                }, (error: any) => {
                                    // The call failed. The CustomErrorHandlerService will cause an error to display.
                                    this.fieldData.childFieldsDynamic.isReloading = false;
                                });
                        }
                    }, environment.useLocalMockData ? environment.useLocalMockDataSimulatedDelay : 0); // If using local mock data, then simulate a delay.

                } else { // The field is invalid.
                    console.log(`Field ${this.fieldData.id} is not valid. Clearing child fields.`);
                    // Clear out child fields.
                    this.fieldData.childFields = [];
                    // Notify that child fields were modified.
                    this.fieldData.notifyChildFieldsModified();
                    // Set the childFieldsDynamic.initialLoadComplete flag to true.
                    this.fieldData.childFieldsDynamic.initialLoadComplete = true;
                    // Set the childFieldsDynamic.isReloading flag to false.
                    this.fieldData.childFieldsDynamic.isReloading = false;
                }
            }
        );
    }

    // Add field watcher using fieldWatcherService for dynamic sections.
    // This is to handle when a control metadata has this configuration:
    //     sectionsDynamic: {
    //         httpVerb: 'GET',
    //         api: 'validateIban'
    //     },
    // If so, then we need to watch for changes to our own field value. When changes occur, then we need to call
    // the api specified in the sectionsDynamic metadata. The value we pass to that api will be the changed
    // field value. That api should then return sections using the same data structure as the original call to
    // get sections. Code in the client will compare the sections incoming here with the sections already displayed
    // and will add or remove sections as appropriate.
    protected addFieldWatcherForDynamicSections() {
        // If there is no sectionsDynamic then do nothing and return.
        if (!this.fieldData.sectionsDynamic) {
            return;
        }

        // If the fieldValue has no value (null or undefined or empty string) then just set
        // sectionsDynamic.initialLoadComplete to true.
        if (MiscUtil.isNullOrUndefinedOrEmptyString(this.fieldData.fieldValue)) {
            this.fieldData.sectionsDynamic.initialLoadComplete = true;
        }

        // Watch for changes to the (our own instance) field using the field watcher service.
        this.fieldWatcherService.addFieldWatcher(
            this.fieldData.id,
            (changedField: SectionField) => {
                // Set the sectionsDynamic.isReloading flag to true only if this is not the first load of dynamic sections.
                // Intention here is during initial load this stays false, and when the user changes the control value
                // that will cause a reload of dynamic sections.
                if (this.fieldData.sectionsDynamic.initialLoadComplete) {
                    this.fieldData.sectionsDynamic.isReloading = true;
                } else {
                    this.fieldData.sectionsDynamic.isReloading = false;
                }

                const formControl: FormControl = this.getFormControl();
                // If the field is valid.
                if (formControl !== null && formControl !== undefined && formControl.valid) {
                    setTimeout(() => {
                        console.log(`Field ${this.fieldData.id} is valid. Getting dynamic sections.`);
                        // Call api specified in the metadata to get dynamic sections.
                        this.metadataApiService.getDynamicSections(this.fieldData.sectionsDynamic, this.fieldData.id, this.fieldData.fieldValue, this.pageMetadataState)
                            .subscribe((sections: Section[]) => {
                                this.pageMetadataState.modifyDynamicSections(sections, (fieldId: string) => {
                                    this.fieldWatcherService.clearFieldWatchersForField(fieldId);
                                });
                                // Set the sectionsDynamic.initialLoadComplete flag to true.
                                this.fieldData.sectionsDynamic.initialLoadComplete = true;
                                // Set the sectionsDynamic.isReloading flag to false.
                                this.fieldData.sectionsDynamic.isReloading = false;
                            }, (error: any) => {
                                // The call failed. The CustomErrorHandlerService will cause an error to display.
                                this.fieldData.sectionsDynamic.isReloading = false;
                            });
                    }, environment.useLocalMockData ? environment.useLocalMockDataSimulatedDelay : 0); // If using local mock data, then simulate a delay.
                } else { // The field is invalid.
                    console.log(`Field ${this.fieldData.id} is not valid. Not loading dynamic sections.`);
                    // Set the sectionsDynamic.initialLoadComplete flag to true.
                    this.fieldData.sectionsDynamic.initialLoadComplete = true;
                    // Set the sectionsDynamic.isReloading flag to false.
                    this.fieldData.sectionsDynamic.isReloading = false;
                }
            }
        );
    }

    // Transform the html string and bypass sanitization. This is for controls that support html display in the label or value.
    public htmlTransform(htmlString): SafeHtml {
        if (!!htmlString) {
            return this.domSanitizer.bypassSecurityTrustHtml(htmlString);
        } else {
            return '';
        }
    }

    // Transform the html string and bypass sanitization. This is for controls that support html display in the label or value.
    public cleanUrl(url): SafeHtml {
        return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    }

    // Notify eventEmitter to open the loading screen. This is to prevent users from moving focus while dynamic child fields load.
    private openLoadingScreen() {
        const loadingScreenOpenData: LoadingScreenData = {
            loadingScreenType : LoadingScreenType.Open
        };
        this.appSharedUtilityService.notifyLoadingScreen(loadingScreenOpenData);
    }

    // Notify EventEmitter to close the loading screen and set focus to next field.
    private closeLoadingScreenAndReFocus(fieldId: string) {
        const loadingScreenCloseData: LoadingScreenData = {
            loadingScreenType : LoadingScreenType.Close,
            closedCallback : () => {
                // set timeout to allow child fields to be added to document.
                setTimeout(() => {
                    // find the next invalid field and sent focus
                    const nextField = $('form.ng-invalid').find('.k-widget.ng-invalid, input.ng-invalid')[0];
                    // Here we have to handle kendo dropdown as there is an extra step to focus on these elements.
                    if ($(nextField).hasClass('k-dropdown')) {
                        const Kendoinput = $(nextField).find('.k-dropdown-wrap')[0];
                        Kendoinput.focus();
                    } else {
                        nextField.focus();
                    }
                }, 200);
            }
        };
        this.appSharedUtilityService.notifyLoadingScreen(loadingScreenCloseData);
    }
}

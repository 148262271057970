import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MockSectionDynamicChildFields } from 'app/pages/bank/mockData/generalExample/mockSectionDynamicChildFields';
import { MockSectionFields } from 'app/pages/bank/mockData/generalExample/mockSectionFields';
import { MockSections } from 'app/pages/bank/mockData/generalExample/mockSections';
import { MockDynamicSections } from 'app/pages/bank/mockData/generalExample/mockDynamicSections';
import { MockDynamicDropdownData } from 'app/pages/bank/mockData/generalExample/mockDynamicDropdownData';
import { MetadataJsonSchemaValidatorService } from 'app/common/services/metadata-json-schema-validator.service';
import { Section } from 'app/common/metadata-models/section';
import { IMetadataApiService, SupportedMicroservice } from 'app/common/services/metadata-api.service';
import { SectionField, MinSectionField } from 'app/common/metadata-models/sectionField';
import { PageMetadataState } from 'app/common/utility/page-metadata-state';
import { ChildFieldsDynamic } from 'app/common/metadata-models/childFieldsDynamic';
import { SectionsDynamic } from 'app/common/metadata-models/sectionsDynamic';
import { DropdownOption } from 'app/common/metadata-models/dropdownOption';
import { DropdownOptionDynamic } from 'app/common/metadata-models/dropdownOptionDynamic';
import { SessionData } from 'app/common/metadata-models/sessionData';
import { ControlType } from 'app/common/metadata-models/controlType';

// Mock data provider for bank data.
@Injectable()
export class GeneralExampleMockDataProvider implements IMetadataApiService {

    // GeneralExampleMockDataProviderService constructor.
    constructor(private metadataJsonValidator: MetadataJsonSchemaValidatorService) {
    }

    // Configure service.
    public configureService(microService: SupportedMicroservice, baseUrl: string, apiVersionParam: string, apiVersionValue: string) {
    }

    // Get sections.
    public getSections(): Observable<Section[]> {
        const mockSections: MockSections = new MockSections();
        const instanceData: Section[] = mockSections.sections_Partner;
        if (!this.metadataJsonValidator.validateJsonSchema(instanceData)) {
            return of(null);
        }
        return of(instanceData).pipe(
            tap(data => {
                console.log(`getSections mock data: ${JSON.stringify(data)}`);
            })
        );
    }

    // Get section data for a given section.
    public getSectionFields(sectionId: string): Observable<SectionField[]> {
        const mockSectionFields: MockSectionFields = new MockSectionFields();
        let instanceData: SectionField[];
        switch (sectionId) {
            case 'BankProfileDetails':
                instanceData = mockSectionFields.sectionFields_BankProfileDetails_1;
                break;
            case 'BankAccountDetails':
                instanceData = mockSectionFields.sectionFields_BankAccountDetails_1;
                break;
        }
        if (!this.metadataJsonValidator.validateJsonSchema(instanceData)) {
            return of(null);
        }
        return of(instanceData).pipe(
            tap(data => {
                console.log(`getSectionFields mock data: ${JSON.stringify(data)}`);
            })
        );
    }

    // Put (save) section data for a given section.
    public putSectionFields(sectionId: string, minSectionFields: MinSectionField[]): Observable<Object> {
        return of(null);
    }

    // Get dynamic dropdown data.
    public getDynamicDropdownData(dropdownOptionsDynamic: DropdownOptionDynamic, fieldId: string, changedFieldId: string,
        changedFieldValue: number|string|boolean, pageMetadataState: PageMetadataState): Observable<DropdownOption[]> {
        const mockDynamicDropdownData: MockDynamicDropdownData = new MockDynamicDropdownData();
        const instanceData: DropdownOption[] = mockDynamicDropdownData.currencies_1;
        this.metadataJsonValidator.validateJsonSchema(instanceData);
        return of(instanceData).pipe(
            tap(data => {
                console.log(`getDynamicDropdownData mock data: ${JSON.stringify(data)}`);
            })
        );
    }

    // Get dynamic child fields.
    public getDynamicChildFields(childFieldsDynamic: ChildFieldsDynamic, fieldId: string, changedFieldValue: number|string|boolean,
        pageMetadataState: PageMetadataState): Observable<SectionField[]> {
        const mockSectionDynamicChildFields: MockSectionDynamicChildFields = new MockSectionDynamicChildFields();
        const instanceData: SectionField[] = mockSectionDynamicChildFields.validateIban_1;
        if (!this.metadataJsonValidator.validateJsonSchema(instanceData)) {
            return of(null);
        }
        return of(instanceData).pipe(
            tap(data => {
                console.log(`getDynamicChildFields mock data: ${JSON.stringify(data)}`);
            })
        );
    }

    // Get dynamic child fields.
    public getDynamicSections(sectionsDynamic: SectionsDynamic, fieldId: string, changedFieldValue: number|string|boolean,
        pageMetadataState: PageMetadataState): Observable<Section[]> {
        const mockDynamicSections: MockDynamicSections = new MockDynamicSections();
        let instanceData: Section[];

        // Choose different mock dynamic sections based on payment method.
        if (changedFieldValue === 'EBT') {
            instanceData = mockDynamicSections.whenPaymentMethodElectronicBankTransfer;
        } else if (changedFieldValue === 'CHK') {
            instanceData = mockDynamicSections.whenPaymentMethodCheck;
        }

        if (!this.metadataJsonValidator.validateJsonSchema(instanceData)) {
            return of(null);
        }
        return of(instanceData).pipe(
            tap(data => {
                console.log(`getDynamicSections mock data: ${JSON.stringify(data)}`);
            })
        );
    }

    // Get session data.
    public getSessionData(): Observable<SessionData> {
        const expiryDate = new Date();
        expiryDate.setMinutes(expiryDate.getMinutes() + 60);
        return of(new SessionData({
            // To experiment with header/footer cobranding...
            // Copy from "docs/Sample cobranded header footer" to a web site such as the localhost web root or any other site.
            // Set the headerUri and footerUri to point to those URL locations.
            // Examples: headerUri: 'http://localhost/header.html', footerUri: 'http://localhost/footer.html',
            // Javascript based example: https://partnerincentives.microsoft.com/webapi/header.cshtml
            headerUri: '',
            footerUri: '',
            returnUri: 'http://bing.com',
            locale: '',
            email: 'mockuser@somewhere.com',
            userSessionExpiryDate: expiryDate
        }));
    }
}

<main role="main">
    <div class="container">
        <div class="row section-title-footer">
            <div class="col-sm">
                <span>{{heading}}</span>
            </div>
            <div class="col-sm-4 section-cancel">
                <span class="section-tip-text">{{lms.get('CANCEL_MSG')}}</span>
                <button kendoButton 
                    attr.data-tracking="{{instrumentationService.trackedElements.cancelButton}}"
                    tabindex="0" 
                    class="btn-cancel" 
                    (click)="onClickCancel()" 
                    [attr.aria-label]="lms.get('CANCEL')">{{lms.get('CANCEL')}}
                </button>
            </div>
        </div>
    </div>

    <!-- Busy / loading spinner for sections loading. -->
    <div class="container">
        <div class="row container-input" *ngIf="!pageMetadataState.displaySectionsLoaded">
            <div class="col" style="text-align:center;">
                <span>{{lms.get('LOADING_ELLIPSIS')}}</span>
                <span class='fas fa-spinner fa-pulse' aria-hidden='true'></span>
            </div>
        </div>
    </div>

    <ng-content></ng-content>

    <div class="container">
        <div class="row section-title-footer">
            <div class="col-sm">
                <span *ngIf="!!sessionManagerService.displayExpiryDate" class="section-tip-text">{{lms.get('SESSION_TIMEOUT_COUNTER')}}{{sessionManagerService.displayExpiryDate}}</span>
            </div>
            <div class="col-sm-4 section-cancel">
                <span class="section-tip-text">{{lms.get('CANCEL_MSG')}}</span>
                <button kendoButton 
                    attr.data-tracking="{{instrumentationService.trackedElements.cancelButton}}"
                    tabindex="0" 
                    class="btn-cancel" 
                    (click)="onClickCancel()" 
                    [attr.aria-label]="lms.get('CANCEL')">{{lms.get('CANCEL')}}
                </button>
            </div>
        </div>
    </div>
</main>


import { Section } from './section';
import { SectionField } from './sectionField';

// Section status.
export enum SectionStatus {
    Undetermined,
    InProgress,
    InputPending,
    Complete
}

// Represents a row in the section. Normally, most sections have just one "row" with one to four columns in that one
// row. The columns a field goes into is controlled by the columnHint field metadata. There is also a fieldGroup
// property in the metadata. The fieldGroup is used to create multiple rows in the section where all the fields
// appear in the same row where the fieldGroup matches. A typical case for this would be when sections have a heading
// (an html field control) on top of the section or in the middle above a block of fields. That heading would be in
// its own fieldGroup, causing a separate row to be made for it with one column. All columns use Bootstrap automatic
// column layout (Bootstrap style 'col'), so a row with one column would span the entire row.
export interface DisplayRow {
    displayColumns: Array<DisplayColumn>;
}

// Represents a column in the row.
export interface DisplayColumn {
    colNum: number;
    fields: Array<SectionField>;
}

// DisplaySection extends the Section by adding additional capability used by the UI.
export class DisplaySection extends Section {
    public sectionFields: Array<SectionField>;
    public displayRows: Array<DisplayRow> = [];
    private parentChildFieldMap: {[parentFieldId: string]: Array<SectionField>} = {};
    public isDirty: boolean = false;
    public noDataFound: boolean = false;
    public sectionLoadComplete: boolean = false; // This could potentially toggle back and forth from true/false within checkSectionFieldsLoaded due to dynamic data reloading.
    public sectionInitialLoadComplete: boolean = false; // This will be set to true and stay true regardless of dynamic data reloading. It will only get set back to false if reset() is called.

    // Copy constructor which takes a Section and extends it with additional properties.
    public constructor(section: Section) {
        super(section);
    }

    // Remove child fields under a parent field.
    public removeDisplayedChildFields(parentFieldId: string, callbackForRemovedField?: (fieldId: string) => void): void {
        // See if there is an entry in the parent child field map for the parent field id.
        if (!!this.parentChildFieldMap[parentFieldId]) {
            // Loop through all the field ids in the parent child field map.
            for (let m: number = 0; m < this.parentChildFieldMap[parentFieldId].length; m++) {
                // Loop through the rows.
                for (let r: number = 0; r < this.displayRows.length; r++) {
                    // Loop through the columns in the row.
                    for (let c: number = 0; c < this.displayRows[r].displayColumns.length; c++) {
                        // Loop backwards through the fields in each column. Backwards so field index remains correct as items get removed from the array.
                        for (let f: number = this.displayRows[r].displayColumns[c].fields.length - 1; f > -1; f--) {
                            const sectionField = this.displayRows[r].displayColumns[c].fields[f];
                            // If this field id matches the field in the parent child field map array.
                            if (sectionField.id === this.parentChildFieldMap[parentFieldId][m].id) {
                                // Recursively call this function to remove child fields of the field being removed.
                                this.removeDisplayedChildFields(sectionField.id, callbackForRemovedField);
                                // If there is a callback registered for the removed field, then call that callback.
                                if (!!callbackForRemovedField) {
                                    callbackForRemovedField(sectionField.id);
                                }
                                // Remove the field.
                                this.displayRows[r].displayColumns[c].fields.splice(f, 1);
                            }
                        }
                    }
                }
            }
            // Remove the key from the parent child field map for the parent field id.
            delete this.parentChildFieldMap[parentFieldId];
        }
    }

    // Place the SectionField array in the correct column.
    public placeFieldsInColumn(sectionFields: SectionField[], parentFieldId?: string): void {
        if (!sectionFields) {
            return;
        }

        for (let i: number = 0; i < sectionFields.length; i++) {
            this.placeFieldInColumn(sectionFields[i], parentFieldId);
        }
    }

    // Place the SectionField in the correct column.
    public placeFieldInColumn(sectionField: SectionField, parentFieldId?: string): void {
        // If parentFieldId is not null, then use it to record what field id is the parent field.
        // This allows us to later use this information to remove any prior added child fields
        // matching that same parent field id. Note that a child field could appear in any column,
        // not necessarily the same column as the parent. Each control, including child controls,
        // have their own columnHint.
        if (!!parentFieldId) {
            // Place the field id into the parent child field map.
            if (!this.parentChildFieldMap[parentFieldId]) {
                this.parentChildFieldMap[parentFieldId] = [];
            }
            this.parentChildFieldMap[parentFieldId].push(sectionField);
        }

        // If columnHint was not present, then default it to column 1.
        if (sectionField.metadata.columnHint === null || sectionField.metadata.columnHint === undefined) {
            sectionField.metadata.columnHint = 1;
        }

        // If this sectionField has a null or undefined fieldGroup then make it a blank empty string.
        // This helps with the next logic that uses fieldGroup to place the field into the proper row/column.
        if (sectionField.metadata.fieldGroup === null || sectionField.metadata.fieldGroup === undefined) {
            sectionField.metadata.fieldGroup = '';
        }

        // Check to see if there is a row for this fieldGroup. If not, add one.
        let found: boolean = false;
        let row: DisplayRow;
        for (let r: number = 0; r < this.displayRows.length; r++) {
            for (let c: number = 0; c < this.displayRows[r].displayColumns.length; c++) {
                for (let f: number = 0; f < this.displayRows[r].displayColumns[c].fields.length; f++) {
                    if (this.displayRows[r].displayColumns[c].fields[f].metadata.fieldGroup === sectionField.metadata.fieldGroup) {
                        found = true;
                        row = this.displayRows[r];
                        break;
                    }
                }
                if (found) {
                    break;
                }
            }
            if (found) {
                break;
            }
        }

        if (!found) {
            row = {displayColumns: []};
            this.displayRows.push(row);
        }

        // Allow for columns 1 through 4. More than that make the UI not look good.
        if (sectionField.metadata.columnHint > 0 && sectionField.metadata.columnHint < 5) {
            // Add any missing columns to match the section field column hint amount.
            found = false;
            let largestColNum: number = 0;
            for (let i: number = 0; i < row.displayColumns.length; i++) {
                if (row.displayColumns[i].colNum === sectionField.metadata.columnHint) {
                    found = true;
                }
                if (largestColNum < row.displayColumns[i].colNum) {
                    largestColNum = row.displayColumns[i].colNum;
                }
            }
            if (!found) {
                for (let i: number = largestColNum; i < sectionField.metadata.columnHint; i++) {
                    row.displayColumns.push({colNum: i + 1, fields: []});
                }
            }
        } else {
            console.error('Illegal columnHint specified.');
            return;
        }

        const zeroBasedColumnHint = sectionField.metadata.columnHint - 1;

        if (!!parentFieldId) {
            // If parentFieldId was specified, then place the child field at the end of the child field array
            // of the parent field if the child field was designated to go into the same column. If the child
            // was set to go into a different column than the parent, then put the child at the end of the
            // specified column.

            // Loop through the fields in the column specified in the field to be added.
            let added: boolean = false;
            for (let f: number = 0; f < row.displayColumns[zeroBasedColumnHint].fields.length; f++) {
                // If the parent field id was found in that column.
                if (row.displayColumns[zeroBasedColumnHint].fields[f].id === parentFieldId) {
                    // Find out how many children are also in the same column.
                    let childrenInSameColumnCount: number = 0;
                    if (!!this.parentChildFieldMap[parentFieldId]) {
                        for (let m: number = 0; m < this.parentChildFieldMap[parentFieldId].length; m++) {
                            if (this.parentChildFieldMap[parentFieldId][m].metadata.columnHint === sectionField.metadata.columnHint) {
                                childrenInSameColumnCount++;
                            }
                        }
                    }

                    // Add the field after the parent in that column.
                    // If other children of the same parent are also in that same column then display at the end of those children.
                    row.displayColumns[zeroBasedColumnHint].fields.splice(f + childrenInSameColumnCount, 0, sectionField);
                    added = true;
                    break;
                }
            }
            // If it was not added in the above loop.
            if (!added) {
                // Add the field to the column specified by the field.
                row.displayColumns[zeroBasedColumnHint].fields.push(sectionField);
            }
        } else {
            // Add the field to the column specified by the field.
            row.displayColumns[zeroBasedColumnHint].fields.push(sectionField);
        }
    }

    // Wait for all dynamic data to load before calling the callback.
    public waitForAllDynamicDataLoaded(callback: (() => void)) {
        if (this.checkIfAllDynamicDataLoaded()) {
            callback();
        } else {
            setTimeout(() => {
                this.waitForAllDynamicDataLoaded(callback);
            }, 100);
        }
    }

    // Reset this section.
    public reset(): void {
        // Reset section fields for the section.
        this.sectionFields = [];
        // Reset display rows/columns for the section.
        this.displayRows = [];
        // Reset the section load and initial section load complete flag for the section.
        this.sectionLoadComplete = false;
        this.sectionInitialLoadComplete = false;
        // Mark the section as not saved.
        this.isSaved = false;
    }

    // Returns true if the section fields are loaded, otherwise false.
    public checkSectionFieldsLoaded(checkForDynamicReloading?: boolean): boolean {
        if (this.noDataFound) {
            return true;
        }

        // If not checking for dynamic reloading, and this section initial load was already completed, then return true.
        if (!checkForDynamicReloading && this.sectionInitialLoadComplete) {
            return true;
        }

        // A section is considered loaded if the sectionFields array is not null or undefined, is an array object
        // with length !== 0, and all fields dynamic data initially loaded. Checking for child fields dynamic reloading is optional (default to false).
        // Note that sectionLoadComplete could potentially toggle back to false here.
        this.sectionLoadComplete = !!this.sectionFields && this.sectionFields.length !== 0 && this.checkIfAllDynamicDataLoaded(null, checkForDynamicReloading);

        // If sectionLoadComplete is true and sectionInitialLoadComplete is still false, then set sectionInitialLoadComplete to true this once
        // for this section. It is used above when not checking for dynamic reloading. The only time the sectionInitialLoadComplete gets toggled
        // back to false is during a reset() call.
        if (this.sectionLoadComplete && !this.sectionInitialLoadComplete) {
            this.sectionInitialLoadComplete = true;
        }

        return this.sectionLoadComplete;
    }

    // Check if all dynamic data is initially loaded for the section fields (not checking reloads).
    // Checks both childFieldsDynamic and dropdownOptionsDynamic.
    // See comment in the code below about child fields.
    public checkIfAllDynamicDataLoaded(sectionFields?: SectionField[], checkForDynamicReloading?: boolean): boolean {
        // If sectionFields was not passed in then start with this objects sectionFields.
        // Note this function is recursive (see below).
        if (!sectionFields) {
            sectionFields = this.sectionFields;
        }

        // Set checkForDynamicReloading to false if not supplied.
        if (checkForDynamicReloading === null || checkForDynamicReloading === undefined) {
            checkForDynamicReloading = false;
        }

        let isLoaded: boolean = true;
        for (let i: number = 0; i < sectionFields.length; i++) {
            if (!isLoaded) {
                break;
            }
            if (isLoaded &&
                !!sectionFields[i].childFieldsDynamic && // If childFieldsDynamic exists...
                (!sectionFields[i].childFieldsDynamic.initialLoadComplete || // And initial load is not complete...
                sectionFields[i].childFieldsDynamic.userChangedOwningField || // OR user changed the owning field...
                (checkForDynamicReloading && sectionFields[i].childFieldsDynamic.isReloading))) { // OR if checking for dynamic reloading and the children are now reloading.
                isLoaded = false;
            }
            if (isLoaded &&
                !!sectionFields[i].sectionsDynamic && // If sectionsDynamic exists...
                (!sectionFields[i].sectionsDynamic.initialLoadComplete || // And initial load is not complete...
                (checkForDynamicReloading && sectionFields[i].sectionsDynamic.isReloading))) { // OR if checking for dynamic reloading and the sections are now reloading.
                isLoaded = false;
            }
            if (isLoaded &&
                !!sectionFields[i].metadata.controlOptions && // If controlOptions exists...
                !!sectionFields[i].metadata.controlOptions.dropdownOptionsDynamic && // And controlOptions.dropdownOptionsDynamic exists...
                (!sectionFields[i].metadata.controlOptions.dropdownOptionsDynamic.initialLoadComplete || // And initial load is not complete...
                (checkForDynamicReloading && sectionFields[i].metadata.controlOptions.dropdownOptionsDynamic.isLoadingOrReloading))) { // OR if checking for child fields dynamic reloading and the children are now reloading.
                isLoaded = false;
            }
            if (isLoaded &&
                !!sectionFields[i].childFields && // If childFields exists...
                sectionFields[i].childFields.length > 0) { // And childFields.length is > 0.
                // If child fields are *already* present in the original sectionFields, then
                // recurse into this method for those fields. This will not work for dynamic children
                // that are not yet present in the original sectionFields because the owning field
                // has not yet called for the retrieval of its child fields.
                isLoaded = this.checkIfAllDynamicDataLoaded(sectionFields[i].childFields, checkForDynamicReloading);
            }
        }

        return isLoaded;
    }
}

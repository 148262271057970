import { Observable } from 'rxjs';
import { HttpHandler, HttpRequest, HttpInterceptor, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

// This interceptor will add the http header indicating we want remote mock data for test purposes.
// This is only done if useRemoteMockData is true.
@Injectable()
export class RemoteMockDataInterceptorService implements HttpInterceptor {
    // Constructor for RemoteMockDataInterceptorService.
    constructor() {
    }

    // HttpInterceptor intercept implementation.
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!environment.useRemoteMockData) {
            return next.handle(req);
        } else {
            const httpHeaders: {[name: string]: string | string[]} = {};

            // Currently need different values in the header for remote mock data based on the api called.
            // Ideally in the future there would be one header/value to use for all remote mock data.
            // Follow up with Winston on that in the future. See below mail from Winston:
            /*
            Oh let me give you different parameters for each endpoint.

            /Sections
            •	CI,Bank
            -	Getting list of sections varies on Tenant. If you pass “AP,Bank”, it will only provide 2 entities (supplier). Ideally, we’re getting this information from the token in redis cache.

            /Sections/{Section}/Fields
            •	CI,US,,hawkeye
            -	Ideally, we’re getting this information from the token in redis cache.

            /Sections/BankDetails/Fields/BankCountrySelection?value=US&api-version=2017-08-24
            •	CI,US,,hawkeye
            -	Ideally, we’re getting this information from the token in redis cache.
            -	This api is the HTTP Get Request for selecting Bank location dropdown
            */

            // If use remote mock data is true, then add a header to ask the service for mock data.
            // This is in contrast to local mock data which is stored locally in this app.
            if (req.url.toLowerCase().indexOf('/fields') > -1) {
                httpHeaders['TestData'] = 'CI,US,,hawkeye';
            } else {
                httpHeaders['TestData'] = 'CI,Bank';
            }

            const authReq = req.clone({
                setHeaders: httpHeaders
            });

            return next.handle(authReq);
        }
    }
}

import { IsSchemaCompliant } from 'app/common/interfaces/isSchemaCompliant';

// DropdownOptionDynamic models data returned from the API.
// *** Shape should match exactly what the API returns. ***
export class DropdownOptionDynamic implements IsSchemaCompliant {
    public drivenByFieldId: string;
    public httpVerb: string;
    public api: string;

    // Not part of data returned from API.
    public initialLoadComplete: boolean = false;
    public isLoadingOrReloading: boolean = false;

    // Constructor that creates a new object from JSON data.
    constructor(jsonData: any) {
        this.drivenByFieldId = jsonData.drivenByFieldId;
        this.httpVerb = jsonData.httpVerb;
        this.api = jsonData.api;
    }

    // User defined type guard to verify data is a valid DropdownOptionDynamic.
    public isSchemaCompliant(): boolean {
        let isCompliant: boolean = true;

        // Make sure all non-nullable properties are present.
        isCompliant = !!this.drivenByFieldId && !!this.httpVerb && !!this.api;
        if (!isCompliant) {
            console.error('JSON is non-compliant - dropdownOptionDynamic required properties not found.');
        }

        return isCompliant;
    }
}

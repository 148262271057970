<div class="modal" tabindex="-1" role="alertdialog" data-backdrop="false" data-keyboard="true" [ngStyle]="{'display':display}" [attr.aria-label]="lms.get('ERROR_HEADING')" aria-describedby="errorMessage">
    <div class="modal-dialog modal-dialog-centered" style="width:100%" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="alert alert-full-width alert-danger">
                    <strong >{{lms.get('ERROR_HEADING')}}</strong>
                </div>
            </div>
            <div class="modal-body">
                <p id="errorMessage">{{friendlyErrorMsg}}</p>
                <div class='tracking-id' *ngIf='!!correlationId'>
                    <span>Correlation Id: </span>
                    <span>{{correlationId}}</span>
                </div>
                <div class='tracking-id' *ngIf='!!referenceId'>
                    <span>Reference Id: </span>
                    <span>{{referenceId}}</span>
                </div>
                <div class='details-container' *ngIf="displayDetails">
                    <span class='details-expander' (click)='expandToggle()'>Details (debug build only)</span>
                    <div class='error-details-content' *ngIf='detailsExpanded'>
                        <div>{{customErrorHandlerService.errors.length}} errors:</div>
                        <pre>{{errorJson}}</pre>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button 
                    id='errorDisplayCloseButton' 
                    attr.data-tracking="{{instrumentationService.trackedElements.errorCloseButton}}"
                    attr.data-trackinginfo="Error dialog: {{friendlyErrorMsg}}"
                    tabindex="-1" 
                    type="button" 
                    class="btn btn-default" 
                    (click)="onErrorCloseClicked()">{{lms.get('CLOSE')}}
                </button>
            </div>
        </div>
    </div>
</div>

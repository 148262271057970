import * as $ from 'jquery';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { LocalizationManagerService } from 'app/common/services/localization-manager.service';
import { AppSharedUtilityService } from 'app/common/services/app-shared-utility.service';
import { MessageData } from 'app/common/components/message-display/message-data';
import { AlertType } from 'app/common/components/message-display/alert-type';
import { InstrumentationService } from 'app/common/services/instrumentation.service';

@Component({
    selector: 'app-message-display',
    templateUrl: './message-display.component.html',
    styleUrls: ['./message-display.component.css']
})
export class MessageDisplayComponent implements OnInit {
    public messageData: MessageData = { message: null, alertType: AlertType.Information, closedCallback: null };
    public display = 'none';

    // Set references to enum types so they can be data bound in html.
    public AlertTypeRef: typeof AlertType = AlertType;

    // MessageDisplayComponent constructor.
    constructor(
        private appSharedUtilityService: AppSharedUtilityService,
        private domSanitizer: DomSanitizer,
        public instrumentationService: InstrumentationService,
        public lms: LocalizationManagerService /* Using short name on purpose for data binding. */
     ) {
        // Subscribe to the message display emitter.
        this.appSharedUtilityService.messageDisplayEmitter.subscribe((messageData: MessageData) => {
            this.messageData = messageData;
            this.openModal();
       });
    }

    public openModal() {
        setTimeout(() => {
            this.display = 'block';
            // Put below code on UI thread after display has been set to 'block'. It takes a moment for the
            // bootstrap UI to render the modal. Set focus to close button so user can press return to close it.
            setTimeout(() => {
                $('#messageDisplayCloseButton').focus();
            }, 0);
        }, 200);
    }

    public onCloseClicked() {
        this.display = 'none';

        // If the closedCallback exists in the messageData then call it.
        if (!!this.messageData.closedCallback) {
            this.messageData.closedCallback();
        }
    }

    // Angular OnInit handler. Part of the lifecycle hooks: https://angular.io/guide/lifecycle-hooks
    public ngOnInit(): void {
    }

    // Transform the html string and bypass sanitization. This is for controls that support html display in the label or value.
    public htmlTransform(htmlString): SafeHtml {
        return this.domSanitizer.bypassSecurityTrustHtml(htmlString);
    }
}

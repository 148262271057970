import { IsSchemaCompliant } from 'app/common/interfaces/isSchemaCompliant';

// Represents some (not all) data from a session object.
export class SessionData implements IsSchemaCompliant {
    public headerUri?: string;
    public footerUri?: string;
    public returnUri: string;
    public locale?: string;
    public email?: string;
    public userSessionExpiryDate?: Date;
    public userSessionTimeRemaining?: number;
    public profileInProgress?: boolean;

    // Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
    constructor(jsonData: any) {
        this.headerUri = jsonData.headerUri;
        this.footerUri = jsonData.footerUri;
        this.returnUri = jsonData.returnUri;
        this.locale = jsonData.locale;
        this.email = jsonData.email;
        this.userSessionExpiryDate = new Date(jsonData.userSessionExpiryDate);
        this.userSessionTimeRemaining = jsonData.userSessionTimeRemaining;
        this.profileInProgress = jsonData.profileInProgress;
    }

    // User defined type guard to verify data is a valid SessionData.
    public isSchemaCompliant(): boolean {
        let isCompliant: boolean = true;

        // Make sure all non-nullable properties are present.
        isCompliant = !!this.returnUri;
        if (!isCompliant) {
            console.error('JSON is non-compliant - SessionData required properties not found.');
        }

        return isCompliant;
    }
}

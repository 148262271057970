import { DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit, ChangeDetectorRef, AfterViewInit, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MetadataApiService } from 'app/common/services/metadata-api.service';
import { FieldWatcherService } from 'app/common/services/field-watcher.service';
import { BaseControl } from 'app/common/components/controls/base-control';
import { LocalizationManagerService } from 'app/common/services/localization-manager.service';
import { AppSharedStateService } from 'app/common/services/app-shared-state.service';
import { AppSharedUtilityService } from 'app/common/services/app-shared-utility.service';

@Component({
    selector: 'app-label',
    templateUrl: './label.component.html',
    styleUrls: ['./label.component.css'],
    providers: [
        {
            // This provider not really needed on this non user input control, but here for consistency
            // and because ControlValueAccessor is implemented in BaseControl.
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LabelComponent),
            multi: true
        }
    ]
})
export class LabelComponent extends BaseControl implements OnInit, AfterViewInit {
    // Constructor for LabelComponent.
    constructor(
        protected fieldWatcherService: FieldWatcherService,
        protected metadataApiService: MetadataApiService,
        protected changeDetectorRef: ChangeDetectorRef,
        protected domSanitizer: DomSanitizer,
        protected appSharedStateService: AppSharedStateService,
        protected appSharedUtilityService: AppSharedUtilityService,
        public lms: LocalizationManagerService /* Using short name on purpose for data binding. */) {
        super(fieldWatcherService, metadataApiService, changeDetectorRef, domSanitizer, appSharedStateService, appSharedUtilityService, lms);
    }

    // Angular OnInit handler. Part of the lifecycle hooks: https://angular.io/guide/lifecycle-hooks
    public ngOnInit(): void {
    }

    // Angular AfterViewInit handler. Part of the lifecycle hooks: https://angular.io/guide/lifecycle-hooks
    public ngAfterViewInit(): void {
        // This is done in a setTimeout so it defers running until a data binding pass is done first.
        setTimeout(() => {
            // Label control cannot have dynamic children, no need to add field watcher for it.
            // Must mark this label control as "ready" even there are no watchers.
            this.fieldData.isControlReady = true;
        });
    }
}

import * as $ from 'jquery';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { MiscUtil } from 'app/common/utility/miscUtil';
import { AppInsights } from 'applicationinsights-js';

// The InstrumentationService is responsible for tracking activity across the app and
// to use Application Insights SDK to record custom events, metrics, and to report
// exceptions and errors.
@Injectable()
export class InstrumentationService {
    public instrumentationKey: string;

    // List of tracked elements used with data-tracking attributes. Example usage:
    //   attr.data-tracking="{{instrumentationService.trackedElements.nextButton}}"
    public trackedElements: any = {
        nextButton: 'nextButton',
        backButton: 'backButton',
        finishButton: 'finishButton',
        cancelButton: 'cancelButton',
        errorCloseButton: 'errorCloseButton',
        infoCloseButton: 'infoCloseButton'
    };

    private config: Microsoft.ApplicationInsights.IConfig = {
        // Represents the Application Insights instrumentation key for the environment.
        instrumentationKey: environment.appInsightsInstrumentationKey
    };

    // Constructor for InstrumentationService.
    constructor() {
        if (!AppInsights.config) {
            AppInsights.downloadAndSetup(this.config);
        }
    }

    // Track page view.
    public trackPageView(name?: string, url?: string, properties?: any, measurements?: any, duration?: number) {
        AppInsights.trackPageView(name, url, properties, measurements, duration);
    }

    // Track metric.
    public trackMetric(): void {
        // todo
    }

    // Track event.
    public trackEvent(name: string, properties: { [name: string]: string }, measurements?: { [name: string]: number }): void {
        AppInsights.trackEvent(name, properties, measurements);
    }

    // Track exception.
    public trackException(error: string, properties: { [name: string]: string }): void {
        AppInsights.trackException(new Error(error), null, properties, null, null);
    }

    // Generate a correlation id (guid) to be used on each service call.
    public generateCorrelationId(): string {
        return MiscUtil.createGuid();
    }

    // Create a new Application Insights standard id.
    // Based off newId in Application Insights source code Util.ts: https://github.com/Microsoft/ApplicationInsights-JS/blob/master/JavaScript/JavaScriptSDK/Util.ts
    // This function is not readily available from the window.appInsights object.
    // This will create a small random 5 character string similar to: wVLno
    private newId(): string {
        const base64Chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
        let result = '';
        let random = Math.random() * 1073741824; // 5 symbols in base64, almost maxint.

        while (random > 0) {
            const char = base64Chars.charAt(random % 64);
            result += char;
            random = Math.floor(random / 64);
        }

        return result;
    }

    // Given a full href, return the path portion.
    private getHrefPath(href: string): string {
        const anchor: HTMLAnchorElement = document.createElement('a');
        anchor.href = href;
        return anchor.pathname;
    }

    // Register element event handlers.
    public registerElementEventHandlers(referenceId: string, userKey: string) {
        // The jQuery on method will register event handlers for existing elements as well as elements yet to be added dynamically.
        $(document).on('click', 'button', (e: any) => {
            this.trackButtonClick(e.currentTarget, referenceId, userKey);
        });
    }

    // Track button click.
    public trackButtonClick(buttonElem: HTMLButtonElement, referenceId: string, userKey: string): void {
        const data: any = <any>$(buttonElem).data();
        // If there is a data-tracking attribute on the element, then track it.
        if (!!data.tracking) {
            let name: string = 'Button click event: ' + data.tracking;
            // Define properties parameter
            const properties: { [name: string]: string } = {
                'button': data.tracking,
                'correlationId': this.generateCorrelationId(),
                'referenceId': referenceId,
                'userKey': userKey
            };
            // Check to see if there is a data-tracking-info. If present then pass this as additional tracking data.
            if (!!data.trackinginfo) {
                properties.info = data.trackinginfo;
                name = `${name}, ${data.trackinginfo}`;
            }
            this.trackEvent(
                name,
                properties
            );
        }
    }
}

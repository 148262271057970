export enum AlertType {
    Information,
    Success,
    Warning,
    Error
}


export function alertTypeFromString(alertType: string|number): AlertType {
    let alertTypeEnum: AlertType;

    if (typeof alertType === 'number') {
        return <AlertType>alertType;
    } else if (typeof alertType === 'string') {
        switch (alertType.toLowerCase()) {
            case 'information':
                alertTypeEnum = AlertType.Information;
                break;
            case 'warning':
                alertTypeEnum = AlertType.Warning;
                break;
            case 'success':
                alertTypeEnum = AlertType.Success;
                break;
            case 'error':
                alertTypeEnum = AlertType.Error;
                break;
            default:
                alertTypeEnum = AlertType.Information;
                break;
        }
    }
    return alertTypeEnum;
}

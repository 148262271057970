import { Injectable } from '@angular/core';
import { Section } from 'app/common/metadata-models/section';
import { SectionField } from 'app/common/metadata-models/sectionField';
import { FieldMetadata } from 'app/common/metadata-models/fieldMetadata';
import { DropdownOption } from 'app/common/metadata-models/dropdownOption';
import { DropdownOptionDynamic } from 'app/common/metadata-models/dropdownOptionDynamic';
import { ChildFieldsDynamic } from 'app/common/metadata-models/childFieldsDynamic';
import { ControlOptions } from 'app/common/metadata-models/controlOptions';
import { IsSchemaCompliant } from 'app/common/interfaces/isSchemaCompliant';
import { CustomErrorHandlerService, ErrorClassification } from 'app/common/services/custom-error-handler.service';
import { SessionData } from 'app/common/metadata-models/sessionData';

// This service validates the JSON schema of data contract objects returned from the
// bank service. If any errors are found then the error handler service (ErrorHandlerServiceService) will be notified.
//
// When JSON is returned "over the wire" from the web service, it is just a loose JSON object, and not one that is an actual
// instance of a typed object like Section or SectionField. Therefore, we cannot use the "instanceof" operator.
// That is, we cannot do "if (data instanceof Section)" because data is actually an instance of Object. An object must
// new'ed up in order to have the object have the correct prototype chain. To solve that problem, the jsonObjectFactory.ts
// was created which converts loose JSON objects into typed objects that were new'ed up and have the correct prototype chain.
// Because we are using the jsonObjectFactory.ts we can utilize "instanceof" in this MetadataJsonSchemaValidatorService.
// Uses TypeScript Generics, see: https://www.typescriptlang.org/docs/handbook/generics.html
@Injectable()
export class MetadataJsonSchemaValidatorService {
    // JsonSchemaValidatorService constructor.
    constructor() {
    }

    // Validates data to be compliant to a JSON schema.
    public validateJsonSchema<T extends IsSchemaCompliant>(data: T | T[]): boolean {
        if (!data) {
            return false;
        }

        let isValid: boolean = true;

        // If data is an array, then recursively call this same function with each item in the array.
        if (Array.isArray(data)) {
            for (let i = 0; i < data.length; i++) {
                // Call this same method for each element in the array, which should be instances
                // of one of the supported objects.
                isValid = this.validateJsonSchema(data[i]);
                // If isValid ever becomes false, then don't bother continuing.
                if (!isValid) {
                    return false;
                }
            }
        } else if (data instanceof Section ||
            data instanceof SectionField ||
            data instanceof FieldMetadata ||
            data instanceof ChildFieldsDynamic ||
            data instanceof ControlOptions ||
            data instanceof DropdownOption ||
            data instanceof DropdownOptionDynamic ||
            data instanceof SessionData) {
            // Use an instanceof check to see if the object is actually an object instance of that
            // type at runtime (was new'ed up and has proper prototype chain).

            // Call the isSchemaCompliant only if it is present on the object.
            if (!!data.isSchemaCompliant) {
                if (data.isSchemaCompliant()) {
                    isValid = true;
                } else {
                    isValid = false;
                    const err: string = `JSON data validation failed for object: ${JSON.stringify(data)}`;
                    console.error(err);
                    CustomErrorHandlerService.instance.addError(err, null, ErrorClassification.JsonSchemaValidation);
                }
            }
        } else {
            // If it was not an array of objects and it was not an actual instance of one of the supported
            // objects, then make isValid false. This would happen if validation occurred on loose json
            // returned from the service (or mock data) without first converting it to an actual instance
            // of that type (using the JsonObjectFactory).
            isValid = false;
            const err: string = `JSON data validation unknown object: ${JSON.stringify(data)}`;
            console.error(err);
            CustomErrorHandlerService.instance.addError(err, null, ErrorClassification.JsonSchemaValidation);
        }

        return isValid;
    }
}

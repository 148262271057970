import { Component, OnInit } from '@angular/core';
import { AppSharedStateService } from 'app/common/services/app-shared-state.service';
import { LocalizationManagerService } from 'app/common/services/localization-manager.service';

/*
Footer URLs that can be used to test co-branding (alternate footer) support.
MPC/NVA portal - various environments:
    https://uatepportal.cloudapp.net/footer.cshtml
    https://devepportal.cloudapp.net/footer.cshtml
    https://paymentcentral.microsoft.com/footer.cshtml
UST/Partner Incentives portal - various environments:
    https://uat2-partnerincentives.microsoft.com/webapi/footer.cshtml
    https://partnerincentives.microsoft.com/webapi/footer.cshtml
*/

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
    constructor(
        public appSharedStateService: AppSharedStateService,
        public lms: LocalizationManagerService /* Using short name on purpose for data binding. */) {
    }

    // Angular OnInit handler. Part of the lifecycle hooks: https://angular.io/guide/lifecycle-hooks
    public ngOnInit(): void {
    }
}

import { Section } from 'app/common/metadata-models/section';

// Mock data used for development and testing.
export class MockSections {
    public sections: Section[] = [
        new Section({
            id: 'Setup',
            sectionName: 'Setup',
            isSaved: false,
            alwaysSave: false
        }),
        new Section({
            id: 'TaxStatus',
            sectionName: 'Tax status',
            isSaved: false,
            alwaysSave: false
        })
    ];
}

import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MetadataJsonSchemaValidatorService } from 'app/common/services/metadata-json-schema-validator.service';
import { Section } from 'app/common/metadata-models/section';
import { IMetadataApiService, SupportedMicroservice } from 'app/common/services/metadata-api.service';
import { SectionField, MinSectionField } from 'app/common/metadata-models/sectionField';
import { PageMetadataState } from 'app/common/utility/page-metadata-state';
import { ChildFieldsDynamic } from 'app/common/metadata-models/childFieldsDynamic';
import { SectionsDynamic } from 'app/common/metadata-models/sectionsDynamic';
import { SessionData } from 'app/common/metadata-models/sessionData';
import { DropdownOption } from 'app/common/metadata-models/dropdownOption';
import { DropdownOptionDynamic } from 'app/common/metadata-models/dropdownOptionDynamic';
import { MockSectionDynamicChildFields } from './mockSectionDynamicChildFields';
import { MockSectionFields } from './mockSectionFields';
import { MockSections } from './mockSections';
import { MockDynamicDropdownData } from './mockDynamicDropdownData';

export class ControlsExampleMockDataProvider implements IMetadataApiService {
    constructor(private metadataJsonValidator: MetadataJsonSchemaValidatorService) {
    }

    public configureService(microService: SupportedMicroservice, baseUrl: string, apiVersionParam: string, apiVersionValue: string) {
    }

    public getSections(): Observable<Section[]> {
        const mockSections: MockSections = new MockSections();
        const instanceData: Section[] = mockSections.sections;
        if (!this.metadataJsonValidator.validateJsonSchema(instanceData)) {
            return of(null);
        }
        return of(instanceData).pipe(
            tap(data => {
                console.log(`getSections mock data: ${JSON.stringify(data)}`);
            })
        );
    }

    // Get section data for a given section.
    public getSectionFields(sectionId: string): Observable<SectionField[]> {
        const mockSectionFields: MockSectionFields = new MockSectionFields();
        let instanceData: SectionField[];
        switch (sectionId) {
            case 'Examples':
                instanceData = mockSectionFields.sectionFields_examples;
                break;
        }
        if (!this.metadataJsonValidator.validateJsonSchema(instanceData)) {
            return of(null);
        }
        return of(instanceData).pipe(
            tap(data => {
                console.log(`getSectionFields mock data: ${JSON.stringify(data)}`);
            })
        );
    }

    // Put (save) section data for a given section.
    public putSectionFields(sectionId: string, minSectionFields: MinSectionField[]): Observable<Object> {
        return of(null);
    }

    // Get dynamic dropdown data.
    public getDynamicDropdownData(dropdownOptionsDynamic: DropdownOptionDynamic, fieldId: string, changedFieldId: string,
        changedFieldValue: number|string|boolean, pageMetadataState: PageMetadataState): Observable<DropdownOption[]> {
        const mockDynamicDropdownData: MockDynamicDropdownData = new MockDynamicDropdownData();
        const instanceData: DropdownOption[] = mockDynamicDropdownData.nothingNeededYet;
        this.metadataJsonValidator.validateJsonSchema(instanceData);
        return of(instanceData).pipe(
            tap(data => {
                console.log(`getDynamicDropdownData mock data: ${JSON.stringify(data)}`);
            })
        );
    }

    // Get dynamic child fields.
    public getDynamicChildFields(childFieldsDynamic: ChildFieldsDynamic, fieldId: string, changedFieldValue: number|string|boolean,
        pageMetadataState: PageMetadataState): Observable<SectionField[]> {
        const mockSectionDynamicChildFields: MockSectionDynamicChildFields = new MockSectionDynamicChildFields();
        let instanceData: SectionField[];

        if (fieldId === 'DropdownWithChildFields_Pets') {
            switch (changedFieldValue) {
                case 'Cats':
                    instanceData = mockSectionDynamicChildFields.cat;
                    break;
                case 'Dogs':
                    instanceData = mockSectionDynamicChildFields.dog;
                    break;
                case 'Pandas':
                    instanceData = mockSectionDynamicChildFields.panda;
                    break;
                case 'Sloths':
                    instanceData = mockSectionDynamicChildFields.sloth;
                    break;
            }
        } else if (fieldId === 'CheckboxWithChildren_Goat') {
            if (changedFieldValue === true) {
                instanceData = mockSectionDynamicChildFields.goat;
            } else {
                instanceData = [];
            }
        } else if (fieldId === 'RadioButtonWithChildren_Sheep') {
            if (changedFieldValue === fieldId) {
                instanceData = mockSectionDynamicChildFields.sheep;
            } else {
                instanceData = [];
            }
        } else if (fieldId === 'RadioButtonWithChildren_Pig') {
            if (changedFieldValue === fieldId) {
                instanceData = mockSectionDynamicChildFields.pig;
            } else {
                instanceData = [];
            }
        } else if (fieldId === 'CheckboxMultilevel1') {
            if (changedFieldValue === true) {
                instanceData = mockSectionDynamicChildFields.checkboxMultilevel2;
            } else {
                instanceData = [];
            }
        } else if (fieldId === 'CheckboxMultilevel2') {
            if (changedFieldValue === true) {
                instanceData = mockSectionDynamicChildFields.checkboxMultilevel3;
            } else {
                instanceData = [];
            }
        }

        if (!this.metadataJsonValidator.validateJsonSchema(instanceData)) {
            return of(null);
        }
        return of(instanceData).pipe(
            tap(data => {
                console.log(`getDynamicChildFields mock data: ${JSON.stringify(data)}`);
            })
        );
    }

    // Get dynamic child fields.
    public getDynamicSections(sectionsDynamic: SectionsDynamic, fieldId: string, changedFieldValue: number|string|boolean,
        pageMetadataState: PageMetadataState): Observable<Section[]> {
        return of(null);
    }

    // Get session data.
    public getSessionData(): Observable<SessionData> {
        const expiryDate = new Date();
        expiryDate.setMinutes(expiryDate.getMinutes() + 60);
        return of(new SessionData({
            headerUri: '',
            footerUri: '',
            returnUri: '',
            locale: '',
            email: 'mockuser@somewhere.com',
            userSessionExpiryDate: expiryDate
        }));
    }
}

import { Observable, of } from 'rxjs';
import { IMetadataApiService, SupportedMicroservice } from 'app/common/services/metadata-api.service';
import { Section } from 'app/common/metadata-models/section';
import { SectionField, MinSectionField } from 'app/common/metadata-models/sectionField';
import { FieldMetadata } from 'app/common/metadata-models/fieldMetadata';
import { ControlType } from 'app/common/metadata-models/controlType';
import { ControlOptions } from 'app/common/metadata-models/controlOptions';
import { ChildFieldsDynamic } from 'app/common/metadata-models/childFieldsDynamic';
import { SectionsDynamic } from 'app/common/metadata-models/sectionsDynamic';
import { DropdownOptionDynamic } from 'app/common/metadata-models/dropdownOptionDynamic';
import { DropdownOption } from 'app/common/metadata-models/dropdownOption';
import { SessionData } from 'app/common/metadata-models/sessionData';
import { PageMetadataState } from 'app/common/utility/page-metadata-state';

// Common mock metadata api service. Used with most tests. Certain specs like for bank or tax use their own
// mock metadata api service specific to those pages.
export class CommonMockMetadataApiService implements IMetadataApiService {
    public configureService(microService: SupportedMicroservice, baseUrl: string, apiVersionParam: string, apiVersionValue: string) {
    }

    public getSections(): Observable<Section[]> {
        const data: Section[] = [
            new Section({
                id: 'section1',
                sectionName: 'Mock section 1',
                isSaved: true
            })
        ];
        return of(data);
    }

    public getSectionFields(sectionId: string): Observable<SectionField[]> {
        let data: SectionField[] = [];

        switch (sectionId) {
            case 'section1':
                data = [
                    new SectionField({
                        id: 'SampleField',
                        fieldValue: 'sample field',
                        metadata: new FieldMetadata({
                            fieldGroup: '',
                            columnHint: 1,
                            required: true,
                            label: 'Sample field',
                            readOnly: false,
                            ariaLabel: 'Sample field',
                            fieldInfo: 'Sample field',
                            control: ControlType.textbox,
                            controlOptions: new ControlOptions({
                                maxLength: 50,
                                regExPattern: '[A-Za-z0-9 ]+',
                                placeholder: 'Enter text',
                                helpText: ''
                            })
                        })
                    })
                ];
                break;
        }
        return of(data);
    }

    public putSectionFields(sectionId: string, minSectionFields: MinSectionField[]): Observable<Object> {
        return of(null);
    }

    public getDynamicChildFields(childFieldsDynamic: ChildFieldsDynamic, fieldId: string, changedFieldValue: number|string|boolean): Observable<SectionField[]> {
        const data: SectionField[] = [];
        return of(data);
    }

    public getDynamicDropdownData(dropdownOptionsDynamic: DropdownOptionDynamic, fieldId: string, changedFieldId: string, changedFieldValue: number|string|boolean): Observable<DropdownOption[]> {
        const data: DropdownOption[] = [];
        return of(data);
    }

    // Get dynamic child fields.
    public getDynamicSections(sectionsDynamic: SectionsDynamic, fieldId: string, changedFieldValue: number|string|boolean,
        pageMetadataState: PageMetadataState): Observable<Section[]> {
        return of(null);
    }

    public getSessionData(): Observable<SessionData> {
        const expiryDate = new Date();
        expiryDate.setMinutes(expiryDate.getMinutes() + 60);
        return of(new SessionData({
            headerUri: '',
            footerUri: '',
            returnUri: '',
            locale: '',
            email: 'mockuser@somewhere.com',
            userSessionExpiryDate: expiryDate
        }));
    }
}

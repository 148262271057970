import { DefaultUrlSerializer, UrlTree } from '@angular/router';
import { AppConstants } from 'app/common/utility/appConstants';
import { Injectable } from "@angular/core";

// This auth decode url serializer will decode the url after redirection back from AAD auth.
// The url is encoded prior to redirection to AAD auth as it will strip any querystring params
// upon return.
//
// Use this by adding this block to the app module:
//   providers: [
//       {
//           provide: UrlSerializer,
//           useClass: CustomUrlSerializer
//       }
//   ]
@Injectable()
export class CustomUrlSerializer extends DefaultUrlSerializer {
    parse(url: string): UrlTree {
        url = this.authDecodeUrl(url);
        url = this.lowercaseRoute(url);
        return super.parse(url);
    }

    // Convert the url to lowercase EXCEPT for all querystring parameters.
    // Reason is we want the routes to be case insensitive, but we cannot modify any parameters like SessionId
    // as that is case sensitive.
    // See: https://stackoverflow.com/questions/36154672/angular2-make-route-paths-case-insensitive
    public lowercaseRoute(url: string): string {
        const questionMarkIndex: number = url.indexOf('?');
        if (questionMarkIndex > 0) {
            const queryStringParams = url.substring(questionMarkIndex);
            url = url.substring(0, questionMarkIndex).toLowerCase();
            url += queryStringParams;
        } else {
            url = url.toLowerCase();
        }
        return url;
    }

    // Decodes url if it has the AppConstants.encodedUrlToken.
    public authDecodeUrl(url: string): string {
        // Only need to do this decode if the url contains the encodedUrlToken which is placed in the url path
        // in the auth.guard.ts prior to redirection to AAD auth.
        if (url.indexOf(AppConstants.encodedUrlToken) > -1) {
            url = decodeURIComponent(url);
            url = url.replace(AppConstants.encodedUrlToken, '');
        }
        return url;
    }
}

<div class="inline-field radio-container" *ngIf="!!fieldData">
    <div class="control-spinner-wrapper">
        <!-- https://www.telerik.com/kendo-angular-ui/components/inputs/textbox/ -->
        <input
            id="{{fieldData.id}}" 
            [attr.name]="fieldData.metadata.controlOptions.groupName"
            role="radio"
            class="form-control" 
            type="radio"
            [required]="!!fieldData.metadata.required ? fieldData.metadata.required : false"
            [attr.aria-label]="fieldData.metadata.ariaLabel"
            [disabled]="!!fieldData.metadata.readOnly"
            [(ngModel)]="fieldData.fieldValue"
            [value]="fieldData.id"
            (change)="onChange($event)"
        />
        <!--
            Regarding:
                [value]="fieldData.id" - Setting this to be the field id. All radio buttons in a group need to have
                unique values, so just using this id as the unique value. So, for selected radio buttons the value
                will be the id of the field, and for unselected radio buttons the value will be 'unselected'.
        -->

        <!-- Radio button label supports html. Some fields display blocks of html formatted text next to the radio button. -->
        <label for="{{fieldData.id}}" class="control-label" [innerHTML]="htmlTransform(fieldData.metadata.label)"></label>

        <div class="spinner-container">
            <div *ngIf="(!!fieldData.childFieldsDynamic && fieldData.childFieldsDynamic.isReloading) || (!!fieldData.sectionsDynamic && fieldData.sectionsDynamic.isReloading)">
                <span class='fas fa-spinner fa-pulse' aria-hidden='true'></span>
            </div>
        </div>
    </div>
</div>

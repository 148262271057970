<div class="inline-field" *ngIf="!!fieldData">
    <div class="control-spinner-wrapper">
        <!-- https://www.telerik.com/kendo-angular-ui/components/inputs/textbox/ -->
        <input
            id="{{fieldData.id}}" 
            role="checkbox"
            name="{{fieldData.id}}"
            class="form-control" 
            type="checkbox"
            [attr.aria-label]="fieldData.metadata.ariaLabel"
            [disabled]="!!fieldData.metadata.readOnly"
            [(ngModel)]="fieldData.fieldValue"
            (change)="onChange($event)"/>

        <!-- Checkbox label supports html. Some fields display blocks of html formatted text next to the radio button. -->
        <label for="{{fieldData.id}}" class="control-label" [innerHTML]="htmlTransform(fieldData.metadata.label)"></label>

        <div class="spinner-container">
            <div *ngIf="(!!fieldData.childFieldsDynamic && fieldData.childFieldsDynamic.isReloading) || (!!fieldData.sectionsDynamic && fieldData.sectionsDynamic.isReloading)">
                <span class='fas fa-spinner fa-pulse' aria-hidden='true'></span>
            </div>
        </div>
    </div>
</div>

import { ControlType } from 'app/common/metadata-models/controlType';
import { SectionField } from 'app/common/metadata-models/sectionField';
import { FieldMetadata } from 'app/common/metadata-models/fieldMetadata';

// Mock data used for development and testing.
export class MockSectionDynamicChildFields {
    public validateIban_1: SectionField[] = [
        new SectionField({
            id: 'bankName',
            fieldValue: 'Bank of Ireland',
            metadata: new FieldMetadata({
                fieldGroup: '',
                columnHint: 1,
                indentHint: 1,
                label: 'Bank name',
                control: ControlType.label
            })
        }),
        new SectionField({
            id: 'bankAddress',
            fieldValue: 'Custom House Quay<br>Ireland Wexford IE',
            metadata: new FieldMetadata({
                fieldGroup: '',
                columnHint: 1,
                indentHint: 1,
                label: 'Bank address',
                control: ControlType.label
            })
        }),
        new SectionField({
            id: 'bankBranchName',
            fieldValue: 'Wexford',
            metadata: new FieldMetadata({
                fieldGroup: '',
                columnHint: 1,
                indentHint: 1,
                label: 'Bank branch name',
                control: ControlType.label
            })
        })
    ];
}

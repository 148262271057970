import { Injectable, ErrorHandler, Inject, EventEmitter } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { InstrumentationService } from './instrumentation.service';
import { ServiceError } from 'app/common/metadata-models/serviceError';
import { MiscUtil } from 'app/common/utility/miscUtil';
import { AppSharedStateService } from 'app/common/services/app-shared-state.service';
import { AppSharedUtilityService } from 'app/common/services/app-shared-utility.service';
import { MessageData } from 'app/common/components/message-display/message-data';
import { AlertType } from 'app/common/components/message-display/alert-type';

// Enumeration of handled error types.
// Using string based enum so when it displays in the error display (both UI and console output) it will show the string and not a number.
export enum ErrorType {
    Unknown = 'Unknown',
    Message = 'Message',
    HttpErrorResponse = 'HttpErrorResponse',
    HttpErrorResponseWithErrorEvent = 'HttpErrorResponseWithErrorEvent',
    HttpErrorResponseWithServiceError = 'HttpErrorResponseWithServiceError',
    HttpErrorResponseWithKnownError = 'HttpErrorResponseWithKnownError',
    HttpErrorResponseWithUnknownError = 'HttpErrorResponseWithUnknownError',
    ClientException = 'ClientException'
}

// This error classification further augments the ErrorType and is used to further
// classify the error to be used with UI to display a friendly error to the user.
export enum ErrorClassification {
    Unauthorized = 'Unauthorized', // 401 unauthorized / expired session
    Forbidden = 'Forbidden', // 403 forbidden
    ResourceNotFound = 'ResourceNotFound', // 404 not found
    JsonSchemaValidation = 'JsonSchemaValidation', // JSON schema validation failure
    General = 'General' // General error
}

// Friendly error message by classification.
export class FriendlyErrorMessageByClassification {
    public errorClassification: ErrorClassification;
    public message: string;
}

// Represents a handled error.
export class HandledError {
    constructor(errorType: ErrorType, errorClassification: ErrorClassification, error: any | string | HttpErrorResponse, correlationId: string) {
        this.errorType = errorType;
        this.errorClassification = errorClassification;
        this.error = error;
        this.correlationId = correlationId;
    }

    public errorType: ErrorType;
    public errorClassification: ErrorClassification;
    public error: any | string | HttpErrorResponse;
    public correlationId: string;
}

// Handles errors across the full application.
// See https://angular.io/guide/http "Getting error details" for tips on handling errors.
//
// Extending and providing a custom error handler.
// See: https://netbasal.com/angular-2-custom-exception-handler-1bcbc45c3230
// This custom error handler needs to be registered in app.module.ts like this:
//   {
//       provide: ErrorHandler,
//       useClass: CustomErrorHandlerService
//   }
@Injectable()
export class CustomErrorHandlerService extends ErrorHandler {
    public static instance: CustomErrorHandlerService;
    public changeDetectionEmitter: EventEmitter<void> = new EventEmitter<void>();

    // Error object array. Public, as it is bound in the error display.
    public errors: Array<HandledError> = [];

    // Returns true if the errors collection has errors.
    public get hasErrors() {
        return this.errors.length > 0;
    }

    // CustomErrorHandlerService constructor.
    constructor(
        private appSharedStateService: AppSharedStateService,
        private instrumentationService: InstrumentationService,
        private appSharedUtilityService: AppSharedUtilityService) {
        super();
        CustomErrorHandlerService.instance = this;
    }

    // Handle the error. This method is called by Angular as this is registered as the error handler
    // in the app module. Note this also hides the base class method of the same name.
    public handleError(error) {
        // Add the error.
        this.addError(error, null, null);

        // Call the base class default error handler (ErrorHandler).
        super.handleError(error);
    }

    // Clears the errors array.
    public clearErrors() {
        // Clear the errors collection.
        this.errors = [];
    }

    // Emit a change notification event. This is needed because data changes in this object (such as the errors collection
    // and hasErrors() property) will not be picked up by Angular change detection if bound in a UI component (such as
    // the ErrorDisplayComponent). So when changes happen in this component, then it is required to emit this change notification
    // event. Then the component that is bound to this data can in turn subscribe to this event and then trigger change
    // detection in that component using the changeDetectorRef object.
    // Example usage:
    //     this.customErrorHandlerService.changeDetectionEmitter.subscribe(() => {
    //         this.changeDetectorRef.detectChanges();
    //     });
    // See: https://stackoverflow.com/questions/39511820/trigger-update-of-component-view-from-service-no-provider-for-changedetectorre
    // See answer with: "Another option if you want to trigger the change from the service but allow the components to control
    // if, when, and how they respond is to set up subscriptions."
    private emitChangeNotification() {
        this.changeDetectionEmitter.emit();
    }

    // Add error to the errors collection.
    // Handles cases where error can be any of:
    //   - null or undefined or not supplied
    //   - string
    //   - Any unknown/unexpected json object
    //   - HttpErrorResponse
    //     - Handle known statuses like 404, 403
    //     - Unknown/unexpected statuses
    //     - Handle cases where HttpErrorResponse.error is instance of:
    //       - ErrorEvent (defined in lib.dom.d.ts and represents an error in client)
    //       - ServiceError (custom defined in server and client project code, represents an error in server)
    public addError(error?: any | string | HttpErrorResponse, correlationId?: string, errorClassification?: ErrorClassification, displayAnyErrorMessage = true): void {
        // Create a default handledError object. It will be potentially updated below.
        const handledError = new HandledError(ErrorType.Unknown, errorClassification, error, correlationId);

        if (!handledError.errorClassification) {
            handledError.errorClassification = ErrorClassification.General;
        }

        // Look for different error conditions in specific sequence.
        if (MiscUtil.isNullOrUndefined(error)) {
            this.instrumentationService.trackException('error is null or undefined', {
                errorClassification: handledError.errorClassification,
                correlationId: this.instrumentationService.generateCorrelationId(),
                referenceId: this.appSharedStateService.referenceId,
                userKey: this.appSharedStateService.userKey
            });
            console.log(`Error is null or undefined.`);
            handledError.error = 'An unknown error has occurred.';
            // No need to alter the default handledError.
            console.error(handledError);
        } else if (typeof error === 'string') {
            this.instrumentationService.trackException(error, {
                errorClassification: handledError.errorClassification,
                correlationId: this.instrumentationService.generateCorrelationId(),
                referenceId: this.appSharedStateService.referenceId,
                userKey: this.appSharedStateService.userKey
            });
            console.log(`Error is a message string.`);
            // No need to alter the default handledError.
            console.error(handledError);
        } else if (error instanceof HttpErrorResponse) {
            // For instrumentation of failed HTTP calls, we use the InstrumentationInterceptor (instrumentation-interceptor.service.ts).
            // So there is no need to track these errors here also with the instrumentationService. For the other error cases, however, we do.
            console.log(`Error is a HttpErrorResponse.`);
            handledError.errorType = ErrorType.HttpErrorResponse;
            if (error.status === 404) {
                // 404 = Not Found: This usually indicates the service is down
                // OR it is up but has returned a 404 internally due to a resource not found.
                console.log(`HttpErrorResponse.status ${error.status} indicates service down or resource not found.`);
                handledError.errorType = ErrorType.HttpErrorResponseWithKnownError;
                handledError.errorClassification = ErrorClassification.ResourceNotFound;
            } else if (error.status === 401) {
                // 401 = Unauthorized. This usually indicates invalid or expired SessionId.
                console.log(`HttpErrorResponse.status ${error.status}`);
                handledError.errorType = ErrorType.HttpErrorResponseWithKnownError;
                handledError.errorClassification = ErrorClassification.Unauthorized;
            } else if (error.status === 403) {
                // 403 = Forbidden. This is a general catch for authorization issues.
                console.log(`HttpErrorResponse.status ${error.status}`);
                handledError.errorType = ErrorType.HttpErrorResponseWithKnownError;
                handledError.errorClassification = ErrorClassification.Forbidden;
            } else if (error.error instanceof ErrorEvent) {
                console.log(`HttpErrorResponse.error is an ErrorEvent indicating error in client.`);
                handledError.errorType = ErrorType.HttpErrorResponseWithErrorEvent;
            } else if (ServiceError.checkIfJsonMatchesType(error.error)) {
                console.log(`HttpErrorResponse.error is an ServiceError indicating error in server.`);
                handledError.errorType = ErrorType.HttpErrorResponseWithServiceError;
                // Check to see if the ServiceError has the optional displayAlert property.
                // If so, then display the alert in a message box popup, rather than use the error handler popup.
                const serviceError: ServiceError = error.error;
                if (!!serviceError.displayAlert) {
                    const messageData: MessageData = {
                        message: serviceError.displayAlert.message,
                        alertType: serviceError.displayAlert.type === 'error' ? AlertType.Error :
                                   serviceError.displayAlert.type === 'warning' ? AlertType.Warning :
                                   serviceError.displayAlert.type === 'success' ? AlertType.Success :
                                   serviceError.displayAlert.type === 'information' ? AlertType.Information :
                                   AlertType.Information
                    };
                    this.appSharedUtilityService.displayMessage(messageData);
                    // Return here, do not fall through to the rest of this function (no error added).
                    return;
                }
            } else {
                console.log(`HttpErrorResponse.error is unknown.`);
                handledError.errorType = ErrorType.HttpErrorResponseWithUnknownError;
            }
            console.error(handledError);
        } else if (!!error.message && !!error.stack) {
            this.instrumentationService.trackException(error.message, {
                stack: error.stack,
                errorClassification: handledError.errorClassification,
                correlationId: this.instrumentationService.generateCorrelationId(),
                referenceId: this.appSharedStateService.referenceId,
                userKey: this.appSharedStateService.userKey
            });
            console.log(`Error is a client exception.`);
            handledError.errorType = ErrorType.ClientException;
            // Create an object with just the message and stack. Otherwise the object is massive in size and potentially with circular references.
            handledError.error = {message: error.message, stack: error.stack};
            console.error(handledError);
        } else if (!!error) {
            this.instrumentationService.trackException('error is unknown object', {
                errorObject: JSON.stringify(error),
                errorClassification: handledError.errorClassification,
                correlationId: this.instrumentationService.generateCorrelationId(),
                referenceId: this.appSharedStateService.referenceId,
                userKey: this.appSharedStateService.userKey
            });
            console.log(`Error is unknown.`);
            console.error(handledError);
        }

        // The displayAnyErrorMessage, if false, states that some exceptions are to be made on which errors are to be displayed to the user
        // In this case, we don't display an error message if its a 401 error (Unauthorized. This usually indicates invalid or expired SessionId)
        if(!displayAnyErrorMessage && error.status == 401){
            return;
        }

        // Add the error to the collection.
        this.errors.push(handledError);

        // Emit a change notification.
        this.emitChangeNotification();
    }
}

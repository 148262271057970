<footer role="contentinfo" class="container footer-component" *ngIf="appSharedStateService.footerType === 'BuiltIn'">
    <hr/>
    <div class="content">
        <div class="left list-inline">
            <ul>
                <li>{{lms.get('MICROSOFT_CONFIDENTIAL')}}</li>
                <li><a class="footer-link" role="link" href="http://go.microsoft.com/fwlink/?LinkId=521839" target="_blank">{{lms.get('PRIVACY_STATEMENT')}}</a></li>
                <li *ngIf="lms.selectedLanguageCode === 'fr'"><span> | </span><a class="footer-link" role="link" href="https://go.microsoft.com/fwlink/?linkid=2121428" target="_blank">{{lms.get('ACCESSIBILITY_DOCUMENT')}}</a></li>
                <li *ngIf="lms.selectedLanguageCode === 'fr'"><span> | </span><a class="footer-link" role="link" href="https://www.microsoft.com/fr-fr/accessibility/accessibility-statement" target="_blank">{{lms.get('ACCESSIBILITY_STATEMENT_FR')}}</a></li>
                <li *ngIf="lms.selectedLanguageCode === 'it'"><span> | </span><a class="footer-link" role="link" href="https://www.microsoft.com/it-it/accessibility/declarations" target="_blank">{{lms.get('ACCESSIBILITY_STATEMENT_IT')}}</a></li>
            </ul>
        </div>
        <div class="right">
            <div class="microsoft-logo">
            </div>
        </div>
    </div>
</footer>

<footer role="contentinfo" *ngIf="appSharedStateService.footerType === 'CobrandRemoteJavascript'">
    <app-script-inject [src]="appSharedStateService.sessionData.footerUri" type="text/javascript"></app-script-inject>
</footer>

<footer role="contentinfo" *ngIf="appSharedStateService.footerType === 'CobrandRemoteHtml'">
    <div [innerHtml]="appSharedStateService.cobrandFooterHtml"></div>
</footer>

import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSerializer } from '@angular/router';
import { BankComponent } from 'app/pages/bank/bank.component';
import { TaxComponent } from 'app/pages/tax/tax.component';
import { BasicProfileComponent } from 'app/pages/basic-profile/basic-profile.component';
import { ComplianceComponent } from 'app/pages/compliance/compliance.component';
import { InvalidRouteComponent } from 'app/pages/invalid-route/invalid-route.component';
import { LoggedOutComponent } from 'app/pages/logged-out/logged-out.component';
import { CustomUrlSerializer } from 'app/common/utility/customUrlSerializer';
import { environment } from 'environments/environment';

// Make all paths lowercase. Note that we use the CustomUrlSerializer which will automatically make all URLs lowercase.
const routes: Routes = [
    { path: 'invalidroute', component: InvalidRouteComponent },
    { path: 'loggedout', component: LoggedOutComponent },
    { path: 'bank', component: BankComponent, runGuardsAndResolvers: 'always' },
    { path: 'tax', component: TaxComponent, runGuardsAndResolvers: 'always' },
    { path: 'basicprofile', component: BasicProfileComponent, runGuardsAndResolvers: 'always' },
    { path: 'compliance', component: ComplianceComponent, runGuardsAndResolvers: 'always' },
    { path: '', redirectTo: 'invalidroute', pathMatch: 'full' },
    { path: '**', redirectTo: 'invalidroute', pathMatch: 'full' }
];

// Regarding runGuardsAndResolvers and onSameUrlNavigation. This enables page reloading.
// See: https://medium.com/engineering-on-the-incline/reloading-current-route-on-click-angular-5-1a1bfc740ab2

@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: environment.routerEnableTracing, onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
    providers: [
        {
            provide: UrlSerializer,
            useClass: CustomUrlSerializer
        }
    ]
})
export class AppRoutingModule {
}

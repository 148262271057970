import { ControlType } from 'app/common/metadata-models/controlType';
import { SectionField } from 'app/common/metadata-models/sectionField';
import { FieldMetadata } from 'app/common/metadata-models/fieldMetadata';
import { DropdownOption } from 'app/common/metadata-models/dropdownOption';
import { ControlOptions } from 'app/common/metadata-models/controlOptions';

// Mock data used for development and testing.
export class MockSectionDynamicChildFields {
    public OnBehalfOfOrgRadio2_Children: SectionField[] = [
        new SectionField({
            id: 'bankAccountLocation',
            fieldValue: null,
            metadata: new FieldMetadata({
                fieldGroup: 'OnBehalfOfOrg',
                columnHint: 2,
                required: true,
                label: 'Permanent residence country/region',
                readOnly: false,
                ariaLabel: 'tbd',
                fieldInfo: null,
                control: ControlType.dropdown,
                controlOptions: new ControlOptions({
                    nullSelectionText: 'Select location',
                    dropdownOptionsStatic: [
                        new DropdownOption({ text: 'Afghanistan', value: 190 }),
                        new DropdownOption({ text: 'Aland Islands', value: 216 }),
                        new DropdownOption({ text: 'Albania', value: 1 }),
                        new DropdownOption({ text: 'Algeria', value: 2 }),
                        new DropdownOption({ text: 'American Samoa', value: 201 }),
                        new DropdownOption({ text: 'Andorra', value: 4 }),
                        new DropdownOption({ text: 'Angola', value: 5 }),
                        new DropdownOption({ text: 'Anguilla', value: 6 }),
                        new DropdownOption({ text: 'Antigua and Barbuda', value: 7 }),
                        new DropdownOption({ text: 'Argentina', value: 8 }),
                        new DropdownOption({ text: 'Armenia', value: 9 }),
                        new DropdownOption({ text: 'Aruba', value: 10 }),
                        new DropdownOption({ text: 'Australia', value: 11 }),
                        new DropdownOption({ text: 'Austria', value: 12 }),
                        new DropdownOption({ text: 'Azerbaijan', value: 13 }),
                        new DropdownOption({ text: 'Bahamas', value: 14 }),
                        new DropdownOption({ text: 'Bahrain', value: 15 }),
                        new DropdownOption({ text: 'Bangladesh', value: 16 }),
                        new DropdownOption({ text: 'Barbados', value: 17 }),
                        new DropdownOption({ text: 'Belarus', value: 18 }),
                        new DropdownOption({ text: 'Belgium', value: 19 }),
                        new DropdownOption({ text: 'Belize', value: 20 }),
                        new DropdownOption({ text: 'Benin', value: 21 }),
                        new DropdownOption({ text: 'Bermuda', value: 22 }),
                        new DropdownOption({ text: 'Bhutan', value: 206 }),
                        new DropdownOption({ text: 'Bolivia', value: 23 }),
                        new DropdownOption({ text: 'Bosnia and Herzegovina', value: 24 }),
                        new DropdownOption({ text: 'Botswana', value: 25 }),
                        new DropdownOption({ text: 'Brazil', value: 26 }),
                        new DropdownOption({ text: 'British Virgin Islands', value: 191 }),
                        new DropdownOption({ text: 'Brunei', value: 27 }),
                        new DropdownOption({ text: 'Bulgaria', value: 28 }),
                        new DropdownOption({ text: 'Burkina Faso', value: 29 }),
                        new DropdownOption({ text: 'Burundi', value: 30 }),
                        new DropdownOption({ text: 'Cambodia', value: 31 }),
                        new DropdownOption({ text: 'Cameroon', value: 32 }),
                        new DropdownOption({ text: 'Canada', value: 33 }),
                        new DropdownOption({ text: 'Cape Verde', value: 34 }),
                        new DropdownOption({ text: 'Cayman Islands', value: 192 }),
                        new DropdownOption({ text: 'Central African Republic', value: 35 }),
                        new DropdownOption({ text: 'Chad', value: 36 }),
                        new DropdownOption({ text: 'Chile', value: 37 }),
                        new DropdownOption({ text: 'China', value: 38 }),
                        new DropdownOption({ text: 'Christmas Island', value: 39 }),
                        new DropdownOption({ text: 'Cocos (Keeling) Islands', value: 40 }),
                        new DropdownOption({ text: 'Colombia', value: 41 }),
                        new DropdownOption({ text: 'Congo', value: 42 }),
                        new DropdownOption({ text: 'Congo (DRC)', value: 207 }),
                        new DropdownOption({ text: 'Cook Islands', value: 43 }),
                        new DropdownOption({ text: 'Costa Rica', value: 44 }),
                        new DropdownOption({ text: 'Côte d’Ivoire', value: 89 }),
                        new DropdownOption({ text: 'Croatia', value: 45 }),
                        new DropdownOption({ text: 'Curaçao', value: 193 }),
                        new DropdownOption({ text: 'Cyprus', value: 46 }),
                        new DropdownOption({ text: 'Czech Republic', value: 47 }),
                        new DropdownOption({ text: 'Denmark', value: 48 }),
                        new DropdownOption({ text: 'Djibouti', value: 49 }),
                        new DropdownOption({ text: 'Dominica', value: 50 }),
                        new DropdownOption({ text: 'Dominican Republic', value: 51 }),
                        new DropdownOption({ text: 'Ecuador', value: 52 }),
                        new DropdownOption({ text: 'Egypt', value: 53 }),
                        new DropdownOption({ text: 'El Salvador', value: 54 }),
                        new DropdownOption({ text: 'Equatorial Guinea', value: 55 }),
                        new DropdownOption({ text: 'Estonia', value: 56 }),
                        new DropdownOption({ text: 'Ethiopia', value: 57 }),
                        new DropdownOption({ text: 'Falkland Islands', value: 58 }),
                        new DropdownOption({ text: 'Faroe Islands', value: 59 }),
                        new DropdownOption({ text: 'Fiji', value: 60 }),
                        new DropdownOption({ text: 'Finland', value: 61 }),
                        new DropdownOption({ text: 'France', value: 62 }),
                        new DropdownOption({ text: 'French Guiana', value: 63 }),
                        new DropdownOption({ text: 'French Polynesia', value: 64 }),
                        new DropdownOption({ text: 'Gabon', value: 65 }),
                        new DropdownOption({ text: 'Gambia, The', value: 66 }),
                        new DropdownOption({ text: 'Georgia', value: 185 }),
                        new DropdownOption({ text: 'Germany', value: 67 }),
                        new DropdownOption({ text: 'Ghana', value: 68 }),
                        new DropdownOption({ text: 'Gibraltar', value: 69 }),
                        new DropdownOption({ text: 'Greece', value: 70 }),
                        new DropdownOption({ text: 'Greenland', value: 71 }),
                        new DropdownOption({ text: 'Grenada', value: 72 }),
                        new DropdownOption({ text: 'Guadeloupe', value: 73 }),
                        new DropdownOption({ text: 'Guam', value: 200 }),
                        new DropdownOption({ text: 'Guatemala', value: 75 }),
                        new DropdownOption({ text: 'Guernsey', value: 215 }),
                        new DropdownOption({ text: 'Guinea', value: 76 }),
                        new DropdownOption({ text: 'Guinea-Bissau', value: 77 }),
                        new DropdownOption({ text: 'Guyana', value: 78 }),
                        new DropdownOption({ text: 'Haiti', value: 79 }),
                        new DropdownOption({ text: 'Honduras', value: 80 }),
                        new DropdownOption({ text: 'Hong Kong SAR', value: 81 }),
                        new DropdownOption({ text: 'Hungary', value: 82 }),
                        new DropdownOption({ text: 'Iceland', value: 83 }),
                        new DropdownOption({ text: 'India', value: 84 }),
                        new DropdownOption({ text: 'Indonesia', value: 85 }),
                        new DropdownOption({ text: 'Iraq', value: 187 }),
                        new DropdownOption({ text: 'Ireland', value: 86 }),
                        new DropdownOption({ text: 'Isle of Man', value: 204 }),
                        new DropdownOption({ text: 'Israel', value: 87 }),
                        new DropdownOption({ text: 'Italy', value: 88 }),
                        new DropdownOption({ text: 'Jamaica', value: 90 }),
                        new DropdownOption({ text: 'Japan', value: 91 }),
                        new DropdownOption({ text: 'Jersey', value: 205 }),
                        new DropdownOption({ text: 'Jordan', value: 92 }),
                        new DropdownOption({ text: 'Kazakhstan', value: 93 }),
                        new DropdownOption({ text: 'Kenya', value: 94 }),
                        new DropdownOption({ text: 'Kosovo', value: 217 }),
                        new DropdownOption({ text: 'Kuwait', value: 95 }),
                        new DropdownOption({ text: 'Kyrgyzstan', value: 96 }),
                        new DropdownOption({ text: 'Laos', value: 208 }),
                        new DropdownOption({ text: 'Latvia', value: 97 }),
                        new DropdownOption({ text: 'Lebanon', value: 98 }),
                        new DropdownOption({ text: 'Lesotho', value: 99 }),
                        new DropdownOption({ text: 'Libya', value: 194 }),
                        new DropdownOption({ text: 'Liechtenstein', value: 100 }),
                        new DropdownOption({ text: 'Lithuania', value: 101 }),
                        new DropdownOption({ text: 'Luxembourg', value: 102 }),
                        new DropdownOption({ text: 'Macao SAR', value: 209 }),
                        new DropdownOption({ text: 'Macedonia, FYRO', value: 103 }),
                        new DropdownOption({ text: 'Madagascar', value: 104 }),
                        new DropdownOption({ text: 'Malawi', value: 105 }),
                        new DropdownOption({ text: 'Malaysia', value: 106 }),
                        new DropdownOption({ text: 'Maldives', value: 210 }),
                        new DropdownOption({ text: 'Mali', value: 107 }),
                        new DropdownOption({ text: 'Malta', value: 108 }),
                        new DropdownOption({ text: 'Martinique', value: 109 }),
                        new DropdownOption({ text: 'Mauritania', value: 110 }),
                        new DropdownOption({ text: 'Mauritius', value: 111 }),
                        new DropdownOption({ text: 'Mayotte', value: 112 }),
                        new DropdownOption({ text: 'Mexico', value: 113 }),
                        new DropdownOption({ text: 'Micronesia', value: 114 }),
                        new DropdownOption({ text: 'Moldova', value: 115 }),
                        new DropdownOption({ text: 'Monaco', value: 116 }),
                        new DropdownOption({ text: 'Mongolia', value: 117 }),
                        new DropdownOption({ text: 'Montenegro', value: 211 }),
                        new DropdownOption({ text: 'Morocco', value: 118 }),
                        new DropdownOption({ text: 'Mozambique', value: 189 }),
                        new DropdownOption({ text: 'Myanmar', value: 212 }),
                        new DropdownOption({ text: 'Namibia', value: 119 }),
                        new DropdownOption({ text: 'Nepal', value: 120 }),
                        new DropdownOption({ text: 'Netherlands', value: 121 }),
                        new DropdownOption({ text: 'New Caledonia', value: 122 }),
                        new DropdownOption({ text: 'New Zealand', value: 123 }),
                        new DropdownOption({ text: 'Nicaragua', value: 124 }),
                        new DropdownOption({ text: 'Niger', value: 125 }),
                        new DropdownOption({ text: 'Nigeria', value: 126 }),
                        new DropdownOption({ text: 'Norfolk Island', value: 127 }),
                        new DropdownOption({ text: 'Northern Mariana Islands', value: 199 }),
                        new DropdownOption({ text: 'Norway', value: 129 }),
                        new DropdownOption({ text: 'Oman', value: 130 }),
                        new DropdownOption({ text: 'Pakistan', value: 131 }),
                        new DropdownOption({ text: 'Palestinian Authority', value: 188 }),
                        new DropdownOption({ text: 'Panama', value: 132 }),
                        new DropdownOption({ text: 'Papua New Guinea', value: 133 }),
                        new DropdownOption({ text: 'Paraguay', value: 134 }),
                        new DropdownOption({ text: 'Peru', value: 135 }),
                        new DropdownOption({ text: 'Philippines', value: 136 }),
                        new DropdownOption({ text: 'Poland', value: 137 }),
                        new DropdownOption({ text: 'Portugal', value: 138 }),
                        new DropdownOption({ text: 'Puerto Rico', value: 198 }),
                        new DropdownOption({ text: 'Qatar', value: 140 }),
                        new DropdownOption({ text: 'Réunion', value: 141 }),
                        new DropdownOption({ text: 'Romania', value: 142 }),
                        new DropdownOption({ text: 'Russia', value: 143 }),
                        new DropdownOption({ text: 'Rwanda', value: 144 }),
                        new DropdownOption({ text: 'Saint Kitts and Nevis', value: 195 }),
                        new DropdownOption({ text: 'Saint Lucia', value: 196 }),
                        new DropdownOption({ text: 'Saint Pierre and Miquelon', value: 145 }),
                        new DropdownOption({ text: 'Samoa', value: 213 }),
                        new DropdownOption({ text: 'San Marino', value: 146 }),
                        new DropdownOption({ text: 'São Tomé and Príncipe', value: 147 }),
                        new DropdownOption({ text: 'Saudi Arabia', value: 148 }),
                        new DropdownOption({ text: 'Senegal', value: 149 }),
                        new DropdownOption({ text: 'Serbia', value: 186 }),
                        new DropdownOption({ text: 'Seychelles', value: 150 }),
                        new DropdownOption({ text: 'Sierra Leone', value: 151 }),
                        new DropdownOption({ text: 'Singapore', value: 152 }),
                        new DropdownOption({ text: 'Slovakia', value: 153 }),
                        new DropdownOption({ text: 'Slovenia', value: 154 }),
                        new DropdownOption({ text: 'South Africa', value: 155 }),
                        new DropdownOption({ text: 'South Korea', value: 156 }),
                        new DropdownOption({ text: 'Spain', value: 157 }),
                        new DropdownOption({ text: 'Sri Lanka', value: 158 }),
                        new DropdownOption({ text: 'Suriname', value: 197 }),
                        new DropdownOption({ text: 'Swaziland', value: 159 }),
                        new DropdownOption({ text: 'Sweden', value: 160 }),
                        new DropdownOption({ text: 'Switzerland', value: 161 }),
                        new DropdownOption({ text: 'Taiwan', value: 162 }),
                        new DropdownOption({ text: 'Tajikistan', value: 163 }),
                        new DropdownOption({ text: 'Tanzania', value: 164 }),
                        new DropdownOption({ text: 'Thailand', value: 165 }),
                        new DropdownOption({ text: 'Togo', value: 166 }),
                        new DropdownOption({ text: 'Tonga', value: 167 }),
                        new DropdownOption({ text: 'Trinidad and Tobago', value: 168 }),
                        new DropdownOption({ text: 'Tunisia', value: 169 }),
                        new DropdownOption({ text: 'Turkey', value: 170 }),
                        new DropdownOption({ text: 'Turkmenistan', value: 171 }),
                        new DropdownOption({ text: 'Turks and Caicos Islands', value: 214 }),
                        new DropdownOption({ text: 'Uganda', value: 173 }),
                        new DropdownOption({ text: 'Ukraine', value: 174 }),
                        new DropdownOption({ text: 'United Arab Emirates', value: 175 }),
                        new DropdownOption({ text: 'United Kingdom', value: 176 }),
                        new DropdownOption({ text: 'United States', value: 177 }),
                        new DropdownOption({ text: 'Uruguay', value: 178 }),
                        new DropdownOption({ text: 'US Minor Outlying Islands', value: 203 }),
                        new DropdownOption({ text: 'Uzbekistan', value: 179 }),
                        new DropdownOption({ text: 'Venezuela', value: 180 }),
                        new DropdownOption({ text: 'Vietnam', value: 181 }),
                        new DropdownOption({ text: 'Virgin Islands', value: 202 }),
                        new DropdownOption({ text: 'Yemen', value: 182 }),
                        new DropdownOption({ text: 'Zambia', value: 183 }),
                        new DropdownOption({ text: 'Zimbabwe', value: 184 })
                    ]
                })
            })
        })
    ];

    public TaxTypeId_Children: SectionField[] = [
        new SectionField({
            id: 'TaxId',
            fieldValue: null,
            metadata: new FieldMetadata({
                fieldGroup: '',
                columnHint: 2,
                required: false,
                label: 'Tax ID ',
                readOnly: false,
                ariaLabel: 'tbd',
                fieldInfo: null,
                control: ControlType.textbox,
                controlOptions: new ControlOptions({
                    maxLength: 11,
                    regExPattern: '^(I|i)(E|e)[0-9][a-zA-Z0-9*+][0-9]{5}[A-Za-z]{1,2}$', // Valid value: IE1a55555Aa
                    placeholder: 'Enter text',
                    helpText: ''
                })
            })
        })
    ];
}

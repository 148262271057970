// Enumeration of supported controls.
// String representations of these are used elsewhere. So if names of these
// are changed or more added later, be sure to update that code too.
export enum ControlType {
    checkbox = 1, // Start with base 1 rather than 0, as 0 is falsey and causes problems.
    dropdown,
    html,
    htmlalert,
    htmlmodal,
    label,
    radiobutton,
    textbox,
    upload,
    iframe
}

// Helper function that returns a ControlType enum.
export function controlTypeFromString(controlType: string|number): ControlType {
    let controlTypeEnum: ControlType;

    if (typeof controlType === 'number') {
        return <ControlType>controlType;
    } else if (typeof controlType === 'string') {
        switch (controlType.toLowerCase()) {
            case 'check':
            case 'checkbox':
                controlTypeEnum = ControlType.checkbox;
                break;
            case 'option':
            case 'select':
            case 'dropdown':
                controlTypeEnum = ControlType.dropdown;
                break;
            case 'html':
                controlTypeEnum = ControlType.html;
                break;
            case 'htmlalert':
                controlTypeEnum = ControlType.htmlalert;
                break;
            case 'htmlmodal':
                controlTypeEnum = ControlType.htmlmodal;
                break;
            case 'label':
                controlTypeEnum = ControlType.label;
                break;
            case 'radio':
            case 'radiobutton':
                controlTypeEnum = ControlType.radiobutton;
                break;
            case 'text':
            case 'textbox':
                controlTypeEnum = ControlType.textbox;
                break;
            case 'upload':
                controlTypeEnum = ControlType.upload;
                break;
            case 'iframe':
                controlTypeEnum = ControlType.iframe;
                break;
        }
    }

    return controlTypeEnum;
}

// Returns true if the control accepts user input, otherwise false.
export function isUserInputControl(controlType: ControlType): boolean {
    switch (controlType) {
        case ControlType.checkbox:
        case ControlType.dropdown:
        case ControlType.radiobutton:
        case ControlType.textbox:
        case ControlType.upload:
            return true;
        case ControlType.html:
        case ControlType.htmlalert:
        case ControlType.htmlmodal:
        case ControlType.label:
        case ControlType.upload:
        case ControlType.iframe:
            return false;
    }
}

import * as $ from 'jquery';
import { Component, OnInit } from '@angular/core';
import { LocalizationManagerService } from 'app/common/services/localization-manager.service';
import { AppSharedUtilityService } from 'app/common/services/app-shared-utility.service';
import { InstrumentationService } from 'app/common/services/instrumentation.service';
import { LoadingScreenData } from 'app/common/components/loadingscreen-display/loadingscreen-data';
import { LoadingScreenType } from './loadingscreen-type';

@Component({
    selector: 'app-loadingscreen-display',
    templateUrl: './loadingscreen-display.component.html',
    styleUrls: ['./loadingscreen-display.component.css']
})
export class LoadingScreenDisplayComponent implements OnInit {
    public display = 'none';
    public loadingScreenData: LoadingScreenData = { loadingScreenType: LoadingScreenType.Close, closedCallback: null };

    // LoadingScreenDisplayComponent constructor.
    constructor(
        private appSharedUtilityService: AppSharedUtilityService,
        public instrumentationService: InstrumentationService,
        public lms: LocalizationManagerService /* Using short name on purpose for data binding. */
     ) {
        // Subscribe to the message display emitter.
        this.appSharedUtilityService.loadingScreenDisplayEmitter.subscribe((loadingScreenData: LoadingScreenData) => {
            this.loadingScreenData = loadingScreenData; 
            if(loadingScreenData.loadingScreenType === LoadingScreenType.Open){
                this.openModal();
            } else {
                this.closeModal(); 
            }
       });
    }

    private keyDownHandler(e){
        var evt = e || window.event;
        var keyCode = evt.which || evt.keyCode;
        if(keyCode === 9 || e.shiftKey ) {
            if(evt.preventDefault) evt.preventDefault();
            else evt.returnValue = false;
            document.getElementById('loadingScreenText').focus();
        }
    }

    private openModal() {
        setTimeout(() => {
            this.display = 'block';

            if (document.getElementById('loadingScreenText').getAttribute('listener') !== 'true') {
                document.getElementById('loadingScreenText').addEventListener("keydown", this.keyDownHandler, false);
            }

            // setting time out to give bootstrap time to load modal. Setting focus to message
            setTimeout(() => {
                $('#loadingScreenText').focus();
            }, 0);
        }, 200);
    }

    private closeModal() {
        this.display = 'none';

        // If the closedCallback exists in the loadingScreenData then call it.
        if (!!this.loadingScreenData.closedCallback) {
            this.loadingScreenData.closedCallback();
        }
    }

    // Angular OnInit handler. Part of the lifecycle hooks: https://angular.io/guide/lifecycle-hooks
    public ngOnInit(): void {
    }
}

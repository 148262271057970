import { ReflectiveInjector } from '@angular/core';
import { Location } from '@angular/common';
import { InstrumentationService } from 'app/common/services/instrumentation.service';

// Base page component for all other page components. Adds common functionality, such as instrumentation,
// that will run when any page is visited. Other common page functionality can be placed here too.
export abstract class BasePageComponent {
    private instrumentationService: InstrumentationService;

    // BasePageComponent constructor.
    constructor(location: Location) {
        // Manually retrieve the monitoring service from the injector so that constructor has no dependencies that must be passed in from child.
        const injector = ReflectiveInjector.resolveAndCreate([
            InstrumentationService
        ]);

        this.instrumentationService = injector.get(InstrumentationService);
        this.instrumentationService.trackPageView(location.path(), null /* AI will use the full current URL here */, { referrer: document.referrer }, null, null);
    }
}

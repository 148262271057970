import * as $ from 'jquery';
import { Component, OnInit, ViewChild, AfterViewInit, forwardRef, ChangeDetectorRef, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { FieldWatcherService } from 'app/common/services/field-watcher.service';
import { MetadataApiService } from 'app/common/services/metadata-api.service';
import { BaseControl } from 'app/common/components/controls/base-control';
import { MiscUtil } from 'app/common/utility/miscUtil';
import { SectionField } from 'app/common/metadata-models/sectionField';
import { LocalizationManagerService } from 'app/common/services/localization-manager.service';
import { AppSharedStateService } from 'app/common/services/app-shared-state.service';
import { AppSharedUtilityService } from 'app/common/services/app-shared-utility.service';
import { InputMustEqualErrorState } from 'app/common/components/controls/textbox/inputMustEqualValidator';

@Component({
    selector: 'app-textbox',
    templateUrl: './textbox.component.html',
    styleUrls: ['./textbox.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TextboxComponent),
            multi: true
        }
    ]
})
export class TextboxComponent extends BaseControl implements OnInit, AfterViewInit {
    @ViewChild('inputElem') private inputElem: ElementRef;
    private previousValue: string = '';
    private suppressRequiredBorderClass: string = 'suppress-required-border';
    public mustEqualFieldLabel: string;

    // Constructor for TextboxComponent.
    constructor(
        protected fieldWatcherService: FieldWatcherService,
        protected metadataApiService: MetadataApiService,
        protected changeDetectorRef: ChangeDetectorRef,
        protected domSanitizer: DomSanitizer,
        protected appSharedStateService: AppSharedStateService,
        protected appSharedUtilityService: AppSharedUtilityService,
        public lms: LocalizationManagerService /* Using short name on purpose for data binding. */) {
        super(fieldWatcherService, metadataApiService, changeDetectorRef, domSanitizer, appSharedStateService, appSharedUtilityService, lms);
    }

    // Angular OnInit handler. Part of the lifecycle hooks: https://angular.io/guide/lifecycle-hooks
    public ngOnInit(): void {
    }

    // Angular AfterViewInit handler. Part of the lifecycle hooks: https://angular.io/guide/lifecycle-hooks
    public ngAfterViewInit(): void {
        // This is done in a setTimeout so it defers running until a data binding pass is done first.
        setTimeout(() => {
            // If fieldValue is not set and is empty or undefined or null, and the field is required.
            if (MiscUtil.isNullOrUndefinedOrEmptyString(this.fieldData.fieldValue) &&
                this.fieldData.metadata.required) {
                // The below will set the text control border to 'gray' on initial load, which will suppress the red
                // required border if no field value exists in the metadata (such as when user visits page for first
                // time and fields are blank).
                $(`#${this.fieldData.id}`).addClass(this.suppressRequiredBorderClass);
            }

            // Adding field watchers must be done in ngAfterViewInit.
            this.addCommonFieldWatchers();

            // Mark this control as ready after the field watchers have been set.
            this.fieldData.isControlReady = true;

            // Manually trigger a change for the control if there was a value initially set.
            if (!MiscUtil.isNullOrUndefinedOrEmptyString(this.fieldData.fieldValue)) {
                // Run this once all controls are ready and have registered their own field watchers.
                this.pageMetadataState.runWhenControlsReadyForActiveSection(() => {
                    this.notifyChanges();
                });
            }
        });
    }

    // Value changed event handler. This happens during focus out of the textbox.
    public onChange(event: Event): void {
        this.notifyChanges();
    }

    public onBlur(event: Event): void {
        if (!!this.fieldData.childFieldsDynamic &&
            this.previousValue === this.fieldData.fieldValue) {
                this.fieldData.childFieldsDynamic.userChangedOwningField = false;
        }
    }

    // Key up event handler. This fires every keypress.
    public onKeyUp(event: Event): void {
        // Notify changes on every keypress. Do this only if there are no dynamic children configured for this field.
        // Reason is we don't want to make the api call to get dynamic children on every keypress - only do that
        // in the normal onChange (focus out of textbox). This onKeyUp handler for every keypress works for other
        // fields that don't have child fields, and gives immediate feedback for control/form validity.
        if (!this.fieldData.childFieldsDynamic) {
            this.notifyChanges();
        }

        // If this field DOES have childFieldsDynamic then set the userChangedOwningField to true.
        // This flag is used in displaySection.ts as part of checkIfAllDynamicDataLoaded logic.
        if (!!this.fieldData.childFieldsDynamic) {
            this.fieldData.childFieldsDynamic.userChangedOwningField = true;
        }

        // When a keyup key press is detected, remove the suppress border style so that the
        // Kendo styles will take over to indicate that field is required.
        if ($(`#${this.fieldData.id}`).hasClass(this.suppressRequiredBorderClass)) {
            $(`#${this.fieldData.id}`).removeClass(this.suppressRequiredBorderClass);
        }
    }

    // Fire change notification.
    private notifyChanges(): void {
        // Fire the change notification within a setTimeout. Reason is form control validation is not done until until the UI has
        // had a chance to process the value change.
        setTimeout(() => {
            // Only trigger change notification if the value changed from previous.
            if (this.previousValue !== this.fieldData.fieldValue) {
                this.fieldWatcherService.notifyChanges(this.fieldData);
                this.previousValue = <string>this.fieldData.fieldValue;
            }
        });
    }

    // Determine to show the must equal field notice.
    public get showMustEqualFieldNotice(): boolean {
        if (!!this.form && !!this.form.controls[this.fieldData.id] && !!this.form.controls[this.fieldData.id].errors) {
            const inputMustEqualErrorState: InputMustEqualErrorState = this.form.controls[this.fieldData.id].errors['inputMustEqualValidator'];
            if (!!inputMustEqualErrorState && !inputMustEqualErrorState.valid) { // If inputMustEqualErrorState is present and it is not valid.
                const mustEqualField: SectionField = this.pageMetadataState.findFieldRecursive(inputMustEqualErrorState.mustEqualFieldId);
                if (!!mustEqualField.fieldValue && !!this.fieldData.fieldValue) { // Only if both fields are not empty.
                    this.mustEqualFieldLabel = mustEqualField.metadata.label;
                    return true;
                }
            }
        }

        return false;
    }
}

import { Component, OnInit, AfterViewInit, ChangeDetectorRef, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { MetadataApiService } from 'app/common/services/metadata-api.service';
import { FieldWatcherService } from 'app/common/services/field-watcher.service';
import { BaseControl } from 'app/common/components/controls/base-control';
import { MiscUtil } from 'app/common/utility/miscUtil';
import { LocalizationManagerService } from 'app/common/services/localization-manager.service';
import { AppSharedStateService } from 'app/common/services/app-shared-state.service';
import { AppSharedUtilityService } from 'app/common/services/app-shared-utility.service';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxComponent),
            multi: true
        }
    ]
})
export class CheckboxComponent extends BaseControl implements OnInit, AfterViewInit {
    // Constructor for CheckboxComponent.
    constructor(
        protected fieldWatcherService: FieldWatcherService,
        protected metadataApiService: MetadataApiService,
        protected changeDetectorRef: ChangeDetectorRef,
        protected domSanitizer: DomSanitizer,
        protected appSharedStateService: AppSharedStateService,
        protected appSharedUtilityService: AppSharedUtilityService,
        public lms: LocalizationManagerService /* Using short name on purpose for data binding. */) {
        super(fieldWatcherService, metadataApiService, changeDetectorRef, domSanitizer, appSharedStateService, appSharedUtilityService, lms);
    }

    // Angular OnInit handler. Part of the lifecycle hooks: https://angular.io/guide/lifecycle-hooks
    ngOnInit(): void {
    }

    // Angular AfterViewInit handler. Part of the lifecycle hooks: https://angular.io/guide/lifecycle-hooks
    ngAfterViewInit(): void {
        // This is done in a setTimeout so it defers running until a data binding pass is done first.
        setTimeout(() => {
            // Adding field watchers must be done in ngAfterViewInit.
            this.addCommonFieldWatchers();

            // Mark this control as ready after the field watchers have been set.
            this.fieldData.isControlReady = true;

            // Manually trigger a change for the control if there was a value initially set.
            if (!MiscUtil.isNullOrUndefinedOrEmptyString(this.fieldData.fieldValue)) {
                // Run this once all controls are ready and have registered their own field watchers.
                this.pageMetadataState.runWhenControlsReadyForActiveSection(() => {
                    this.notifyChanges();
                });
            }
        });
    }

    // Value changed event handler.
    public onChange(event: Event): void {
        this.notifyChanges();
    }

    // Fire change notification.
    private notifyChanges(): void {
        // Fire the change notification within a setTimeout. Reason is form control validation is not done until until the UI has
        // had a chance to process the value change.
        setTimeout(() => {
            this.fieldWatcherService.notifyChanges(this.fieldData);
        });
    }
}

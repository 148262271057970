import { Section } from 'app/common/metadata-models/section';

// Mock data used for development and testing.
export class MockDynamicSections {
    public whenPaymentMethodCheck: Section[] = [
        new Section({
            id: 'BankProfileDetails',
            sectionName: 'Details',
            isSaved: true
        })
        // Uncomment the following to see a new section gets added that wasn't there before.
        /*,
        new Section({
            id: 'NewTestSection',
            sectionName: 'Test Dynamic Section - only shows when payment method Check selected',
            isSaved: false
        })*/
    ];

    public whenPaymentMethodElectronicBankTransfer: Section[] = [
        new Section({
            id: 'BankProfileDetails',
            sectionName: 'Details',
            isSaved: true
        }),
        new Section({
            id: 'BankAccountDetails',
            sectionName: 'Bank account',
            isSaved: false
        }),
        new Section({
            id: 'BeneficiaryDetails',
            sectionName: 'Beneficiary',
            isSaved: false
        })
    ];
}

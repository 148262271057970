import { ChildFieldsDynamic } from 'app/common/metadata-models/childFieldsDynamic';
import { DropdownOptionDynamic } from 'app/common/metadata-models/dropdownOptionDynamic';
import { ControlOptions } from 'app/common/metadata-models/controlOptions';
import { FieldMetadata } from 'app/common/metadata-models/fieldMetadata';
import { SectionField } from 'app/common/metadata-models/sectionField';
import { ControlType } from 'app/common/metadata-models/controlType';
import { DropdownOption } from 'app/common/metadata-models/dropdownOption';

// Mock data used for development and testing.
export class MockSectionFields {
    public sectionFields_examples: SectionField[] = [
        new SectionField({
            id: 'SampleHtml1',
            metadata: new FieldMetadata({
                fieldGroup: 'SectionInfoText1',
                columnHint: 1,
                label: 'Some sample <b>html content</b> that is in its own <i>fieldGroup</i>. This causes it to appear in its own row within the section.',
                control: ControlType.html
            })
        }),
        new SectionField({
            id: 'SampleTextbox',
            fieldValue: 'Sample textbox',
            metadata: new FieldMetadata({
                fieldGroup: '',
                columnHint: 1,
                required: false,
                label: 'Sample textbox',
                readOnly: false,
                ariaLabel: 'Sample textbox',
                fieldInfo: 'Sample textbox',
                control: ControlType.textbox,
                controlOptions: new ControlOptions({
                    maxLength: 50,
                    regExPattern: '[A-Za-z0-9 ]+',
                    placeholder: 'Enter text',
                    helpText: ''
                })
            })
        }),
        new SectionField({
            id: 'SampleDropdown',
            fieldValue: 2,
            metadata: new FieldMetadata({
                fieldGroup: '',
                columnHint: 2,
                required: false,
                label: 'Sample dropdown',
                readOnly: false,
                ariaLabel: 'Sample dropdown',
                fieldInfo: 'Sample dropdown',
                control: ControlType.dropdown,
                controlOptions: new ControlOptions({
                    nullSelectionText: 'Select something',
                    dropdownOptionsStatic: [
                        new DropdownOption({ text: 'red', value: 0 }),
                        new DropdownOption({ text: 'green', value: 1 }),
                        new DropdownOption({ text: 'blue', value: 2 }),
                        new DropdownOption({ text: 'purple', value: 3 })
                    ]
                })
            })
        }),
        new SectionField({
            id: 'SampleLabel',
            fieldValue: 'Sample label',
            metadata: new FieldMetadata({
                fieldGroup: '',
                columnHint: 3,
                label: 'Sample label',
                control: ControlType.label
            })
        }),
        new SectionField({
            id: 'SampleCheckbox',
            fieldValue: true,
            metadata: new FieldMetadata({
                fieldGroup: '',
                columnHint: 4,
                required: false,
                label: 'Sample checkbox',
                readOnly: false,
                ariaLabel: 'Sample checkbox',
                fieldInfo: 'Sample checkbox',
                control: ControlType.checkbox
            })
        }),
        new SectionField({
            id: 'SampleRadioButton1',
            fieldValue: true,
            metadata: new FieldMetadata({
                fieldGroup: '',
                columnHint: 4,
                required: false,
                label: 'Sample radiobutton 1',
                readOnly: false,
                ariaLabel: 'Sample radiobutton 1',
                fieldInfo: 'Sample radiobutton 1',
                control: ControlType.radiobutton,
                controlOptions: new ControlOptions({
                    groupName: 'SampleRadioButtonGroup'
                })
            })
        }),
        new SectionField({
            id: 'SampleRadioButton2',
            fieldValue: false,
            metadata: new FieldMetadata({
                fieldGroup: '',
                columnHint: 4,
                required: false,
                label: 'Sample radiobutton 2',
                readOnly: false,
                ariaLabel: 'Sample radiobutton 2',
                fieldInfo: 'Sample radiobutton 2',
                control: ControlType.radiobutton,
                controlOptions: new ControlOptions({
                    groupName: 'SampleRadioButtonGroup'
                })
            })
        }),
        new SectionField({
            id: 'SampleHtml2',
            metadata: new FieldMetadata({
                fieldGroup: 'SectionInfoText2',
                columnHint: 1,
                label: 'Some more sample <u><b style="color:green;">html</b> <b style="color:orange;">content</b></u> that is in a different <i>fieldGroup</i> causing a new row. Lets make this content really long so that it wraps to the next line and also because run-on sentences are awesome and so are ninjas if you dont believe me then check <a target="_blank" href="http://realultimatepower.net/">this out</a>.',
                control: ControlType.html
            })
        }),
        new SectionField({
            id: 'SampleUpload',
            fieldValue: '',
            metadata: new FieldMetadata({
                fieldGroup: '',
                columnHint: 1,
                required: false,
                label: 'Sample upload',
                readOnly: false,
                ariaLabel: 'Sample upload',
                fieldInfo: 'Sample upload',
                control: ControlType.upload,
                controlOptions: new ControlOptions({
                    acceptedFileExtensions: 'doc,docx,xls,xlsx,png,gif,jpg,jpeg,pdf',
                    maxFileSize: 5000000,
                    uploadFileUrl: 'https://some/upload/url'
                })
            })
        }),

        // Fields with children test examples.
        new SectionField({
            id: 'ChildrenTestHeading',
            metadata: new FieldMetadata({
                fieldGroup: 'ChildrenTestHeading',
                columnHint: 1,
                label: '<hr style="margin-bottom:8px;">Fields with children test examples.',
                control: ControlType.html
            })
        }),
        new SectionField({
            id: 'DropdownWithChildFields_Pets',
            fieldValue: null,
            childFieldsDynamic: new ChildFieldsDynamic({
                httpVerb: 'GET',
                api: 'Sections/{sectionId}/Fields/{fieldId}/ChildFields?fieldValue={fieldValue}'
            }),
            childFields: null,
            metadata: new FieldMetadata({
                fieldGroup: 'ChildrenTestGroup',
                columnHint: 1,
                required: false,
                label: 'What kind of pet do you like?',
                readOnly: false,
                ariaLabel: 'What kind of pet do you like?',
                fieldInfo: 'Do you like cats or dogs or panda bears?',
                control: ControlType.dropdown,
                controlOptions: new ControlOptions({
                    nullSelectionText: 'Choose your pet!',
                    dropdownOptionsStatic: [
                        new DropdownOption({ text: 'Cats', value: 'Cats' }),
                        new DropdownOption({ text: 'Dogs', value: 'Dogs' }),
                        new DropdownOption({ text: 'Pandas', value: 'Pandas' }),
                        new DropdownOption({ text: 'Sloths', value: 'Sloths' })
                    ]
                })
            })
        }),
        new SectionField({
            id: 'CheckboxWithChildren_Goat',
            fieldValue: false,
            childFieldsDynamic: new ChildFieldsDynamic({
                httpVerb: 'GET',
                api: 'Sections/{sectionId}/Fields/{fieldId}/ChildFields?fieldValue={fieldValue}'
            }),
            metadata: new FieldMetadata({
                fieldGroup: 'ChildrenTestGroup',
                columnHint: 2,
                required: false,
                label: 'I like goats',
                readOnly: false,
                ariaLabel: 'I like goats',
                fieldInfo: 'I like goats',
                control: ControlType.checkbox
            })
        }),
        new SectionField({
            id: 'RadioButtonWithChildren_Sheep',
            fieldValue: false,
            childFieldsDynamic: new ChildFieldsDynamic({
                httpVerb: 'GET',
                api: 'Sections/{sectionId}/Fields/{fieldId}/ChildFields?fieldValue={fieldValue}'
            }),
            metadata: new FieldMetadata({
                fieldGroup: 'ChildrenTestGroup',
                columnHint: 3,
                required: false,
                label: 'I like sheep',
                readOnly: false,
                ariaLabel: 'I like sheep',
                fieldInfo: 'I like sheep',
                control: ControlType.radiobutton,
                controlOptions: new ControlOptions({
                    groupName: 'RadioButtonWithChildrenGroup'
                })
            })
        }),
        new SectionField({
            id: 'RadioButtonWithChildren_Pig',
            fieldValue: false,
            childFieldsDynamic: new ChildFieldsDynamic({
                httpVerb: 'GET',
                api: 'Sections/{sectionId}/Fields/{fieldId}/ChildFields?fieldValue={fieldValue}'
            }),
            metadata: new FieldMetadata({
                fieldGroup: 'ChildrenTestGroup',
                columnHint: 4,
                required: false,
                label: 'I like pigs',
                readOnly: false,
                ariaLabel: 'I like pigs',
                fieldInfo: 'I like pigs',
                control: ControlType.radiobutton,
                controlOptions: new ControlOptions({
                    groupName: 'RadioButtonWithChildrenGroup'
                })
            })
        }),
        new SectionField({
            id: 'CheckboxMultilevel1',
            fieldValue: false,
            childFieldsDynamic: new ChildFieldsDynamic({
                httpVerb: 'GET',
                api: 'Sections/{sectionId}/Fields/{fieldId}/ChildFields?fieldValue={fieldValue}'
            }),
            metadata: new FieldMetadata({
                fieldGroup: 'MultiLevelCheckboxGroup',
                columnHint: 1,
                required: false,
                label: 'Checkbox level 1',
                readOnly: false,
                ariaLabel: 'Checkbox level 1',
                fieldInfo: 'Checkbox level 1',
                control: ControlType.checkbox
            })
        }),
        new SectionField({
            id: 'iframetest',
            metadata: new FieldMetadata({
                fieldGroup: 'iframetest',
                columnHint: 1,
                label: 'CTI tax page in an iframe',
                control: ControlType.iframe,
                controlOptions: new ControlOptions({
                    src: 'https://ihsmarkit.com/products/tax-solutions.html'
                })
            })
        })
    ];
}

<div *ngIf="!!fieldData">
    <label for="{{fieldData.id}}" class="control-label">
        <span [innerHTML]="htmlTransform(fieldData.metadata.label)"></span>
        <span *ngIf="fieldData.metadata.required" class="required">*</span>
        <img *ngIf="!!fieldData.metadata.fieldInfo || !!fieldData.metadata.controlOptions.helpText" class="tip-icon" src="assets/common/info-icon.svg" 
            placement="right" [ngbTooltip]="tipContent" alt="{{fieldData.metadata.fieldInfo}}" tabindex="0"/>
    </label>

    <!-- Tooltip content used with above icon. Note this same block of html exists on other control(s) like textbox. -->
    <ng-template #tipContent>
        <div>
            <div *ngIf="!!fieldData.metadata.fieldInfo && fieldData.metadata.fieldInfo.length > 0">{{fieldData.metadata.fieldInfo}}</div>
        </div>
    </ng-template>

    <br/>
    <div class="control-group">
        <div class="control-spinner-wrapper">
            <kendo-upload
                [saveUrl]="saveUrl"
                [restrictions]="fileTypeRestrictions">
            </kendo-upload>

            <div class="spinner-container">
                <div *ngIf="(!!fieldData.childFieldsDynamic && fieldData.childFieldsDynamic.isReloading) || (!!fieldData.sectionsDynamic && fieldData.sectionsDynamic.isReloading)">
                    <span class='fas fa-spinner fa-pulse' aria-hidden='true'></span>
                </div>
            </div>
        </div>

        <div class='supported-file-types'>{{lms.get('SUPPORTED_FILE_TYPES_COLON')}}</div>
        <div class='file-types'>{{fileTypesDisplay}}</div>
    </div>
</div>

import { Component, OnInit, Input, AfterViewInit, ChangeDetectorRef, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { FieldWatcherService } from 'app/common/services/field-watcher.service';
import { MetadataApiService } from 'app/common/services/metadata-api.service';
import { BaseControl } from 'app/common/components/controls/base-control';
import { SectionField } from 'app/common/metadata-models/sectionField';
import { MiscUtil } from 'app/common/utility/miscUtil';
import { ControlType } from '../../../metadata-models/controlType';
import { LocalizationManagerService } from 'app/common/services/localization-manager.service';
import { AppSharedStateService } from 'app/common/services/app-shared-state.service';
import { AppSharedUtilityService } from 'app/common/services/app-shared-utility.service';

// todo: Radio button required is not functioning as expected. If any radio button metadata in a group is marked as required then at
// least one radio button should be selected from the group. If both radio buttons are unselected then it should cause a form validation error.
// See: https://stackoverflow.com/questions/8287779/html5-how-to-use-the-required-attribute-with-a-radio-input-field

@Component({
    selector: 'app-radiobutton',
    templateUrl: './radiobutton.component.html',
    styleUrls: ['./radiobutton.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadiobuttonComponent),
            multi: true
        }
    ]
})
export class RadiobuttonComponent extends BaseControl implements OnInit, AfterViewInit {
    private unselectedValue: string = 'unselected';

    // Constructor for RadiobuttonComponent.
    constructor(
        protected fieldWatcherService: FieldWatcherService,
        protected metadataApiService: MetadataApiService,
        protected changeDetectorRef: ChangeDetectorRef,
        protected domSanitizer: DomSanitizer,
        protected appSharedStateService: AppSharedStateService,
        protected appSharedUtilityService: AppSharedUtilityService,
        public lms: LocalizationManagerService /* Using short name on purpose for data binding. */) {
        super(fieldWatcherService, metadataApiService, changeDetectorRef, domSanitizer, appSharedStateService, appSharedUtilityService, lms);
    }

    // Angular OnInit handler. Part of the lifecycle hooks: https://angular.io/guide/lifecycle-hooks
    public ngOnInit(): void {
        // If the fieldValue is a boolean and is true, then alter the fieldValue to match that of the field id.
        // This is how radio buttons work... the value of each radio button in the group must be a unique string.
        // So we are using the field id as the unique string.
        // The metadata service should really also return a selected radio button fieldValue as the same string (field id)
        // but could also return true for the selected radio button (it will just be swapped out here). During save, the fieldValue
        // will be a string like this. So the server side code should look for such a string in the fieldValue to know if the
        // user had selected this radio button (that is, server side should not look for true/false in fieldValue for this).
        if (this.fieldData.fieldValue === true) {
            this.fieldData.fieldValue = this.fieldData.id;
        }
    }

    // Angular AfterViewInit handler. Part of the lifecycle hooks: https://angular.io/guide/lifecycle-hooks
    public ngAfterViewInit(): void {
        // This is done in a setTimeout so it defers running until a data binding pass is done first.
        setTimeout(() => {
            // Adding field watchers must be done in ngAfterViewInit.
            this.addCommonFieldWatchers();

            // Mark this control as ready after the field watchers have been set.
            this.fieldData.isControlReady = true;

            // Manually trigger a change for the control if there was a value initially set.
            if (!MiscUtil.isNullOrUndefinedOrEmptyString(this.fieldData.fieldValue)) {
                // Run this once all controls are ready and have registered their own field watchers.
                this.pageMetadataState.runWhenControlsReadyForActiveSection(() => {
                    this.notifyChanges();
                });
            }

            // Wait for other controls to load, then add a field watcher for all other radio button fields in the same groupName.
            this.pageMetadataState.runWhenControlsReadyForActiveSection(() => {
                const otherRadioButtonFields: SectionField[] = this.pageMetadataState.findAllFieldsForControlTypeInActiveSection(ControlType.radiobutton);
                for (let f: number = 0; f < otherRadioButtonFields.length; f++) {
                    this.fieldWatcherService.addFieldWatcher(otherRadioButtonFields[f].id,
                        (changedField: SectionField) => {
                            // If the changed radio button field was not this instance.
                            // And if the changed radio button field had the same groupName as this one.
                            // And if the changed field value was truthy.
                            if (!!changedField.metadata.controlOptions && !!this.fieldData.metadata.controlOptions && !!this.fieldData.metadata.controlOptions.groupName &&
                                changedField.id !== this.fieldData.id &&
                                changedField.metadata.controlOptions.groupName === this.fieldData.metadata.controlOptions.groupName &&
                                !!changedField.fieldValue &&
                                changedField.fieldValue !== this.unselectedValue) {
                                // If those other radio button fields change to be the selected one then this one must clear its field value.
                                // So, set this fields value to the string value 'unselected'. This is just an arbitrary string used to
                                // indicate an unselected radio button. For the radio button to be selected then the fieldValue must equal
                                // the field id (because in the html: [value]="fieldData.id").
                                this.fieldData.fieldValue = this.unselectedValue;
                                // Notify this field has changed.
                                this.notifyChanges();
                            }
                        }
                    );
                }
            });
        });
    }

    // Value changed event handler.
    public onChange(event: Event): void {
        this.notifyChanges();
    }

    // Fire change notification.
    private notifyChanges(): void {
        // Fire the change notification within a setTimeout. Reason is form control validation is not done until until the UI has
        // had a chance to process the value change.
        setTimeout(() => {
            this.fieldWatcherService.notifyChanges(this.fieldData);
        });
    }
}

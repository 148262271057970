import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MetadataJsonSchemaValidatorService } from 'app/common/services/metadata-json-schema-validator.service';
import { Section } from 'app/common/metadata-models/section';
import { IMetadataApiService, SupportedMicroservice } from 'app/common/services/metadata-api.service';
import { SectionField, MinSectionField } from 'app/common/metadata-models/sectionField';
import { PageMetadataState } from 'app/common/utility/page-metadata-state';
import { ChildFieldsDynamic } from 'app/common/metadata-models/childFieldsDynamic';
import { SectionsDynamic } from 'app/common/metadata-models/sectionsDynamic';
import { SessionData } from 'app/common/metadata-models/sessionData';
import { DropdownOption } from 'app/common/metadata-models/dropdownOption';
import { DropdownOptionDynamic } from 'app/common/metadata-models/dropdownOptionDynamic';
import { ControlType } from 'app/common/metadata-models/controlType';
import { GeneralExampleMockDataProvider } from 'app/pages/compliance/mockData/generalExample/generalExampleMockDataProvider';

export enum ComplianceMockData {
    NothingSelected,
    GeneralExample
}

export const ComplianceMockDataDescription: { [key: string]: string; } = {
    generalExample: 'General example'
};

// Mock data provider for compliance data.
@Injectable()
export class ComplianceMockDataProviderService implements IMetadataApiService {
    public selectedComplianceMockData: ComplianceMockData;
    private mockDataProvider: IMetadataApiService;

    // ComplianceMockDataProviderService constructor.
    constructor(private metadataJsonValidator: MetadataJsonSchemaValidatorService, private router: Router) {
        this.changeMockData(ComplianceMockData.NothingSelected);
    }

    // Change mock data.
    public changeMockData(complianceMockData: ComplianceMockData, forceNavigation: boolean = false) {
        const setMockProvider: (complianceMockData: ComplianceMockData) => void = () => {
            this.selectedComplianceMockData = complianceMockData;
            switch (this.selectedComplianceMockData) {
                case ComplianceMockData.NothingSelected:
                    return;
                case ComplianceMockData.GeneralExample:
                    this.mockDataProvider = new GeneralExampleMockDataProvider(this.metadataJsonValidator);
                    break;
            }
        };

        if (forceNavigation) {
            this.router.navigateByUrl('compliance').then(() => {
                setMockProvider(complianceMockData);
            });
        } else {
            setMockProvider(complianceMockData);
        }
    }

    // Configure service.
    public configureService(microService: SupportedMicroservice, baseUrl: string, apiVersionParam: string, apiVersionValue: string) {
        return this.mockDataProvider.configureService(microService, baseUrl, apiVersionParam, apiVersionValue);
    }

    // Get sections.
    public getSections(): Observable<Section[]> {
        if (this.selectedComplianceMockData === ComplianceMockData.NothingSelected) {
            return of([new Section({ sectionName: 'No mock data selected', id: 'x', isSaved: false }) ]);
        }
        return this.mockDataProvider.getSections();
    }

    // Get section data for a given section.
    public getSectionFields(sectionId: string): Observable<SectionField[]> {
        if (this.selectedComplianceMockData === ComplianceMockData.NothingSelected) {
            return of([new SectionField({ id: 'x', fieldValue: '', metadata: {label: 'No mock data selected', control: ControlType.label}}) ]);
        }
        return this.mockDataProvider.getSectionFields(sectionId);
    }

    // Put (save) section data for a given section.
    public putSectionFields(sectionId: string, minSectionFields: MinSectionField[]): Observable<Object> {
        return this.mockDataProvider.putSectionFields(sectionId, minSectionFields);
    }

    // Get dynamic dropdown data.
    public getDynamicDropdownData(dropdownOptionsDynamic: DropdownOptionDynamic, fieldId: string, changedFieldId: string,
        changedFieldValue: number|string|boolean, pageMetadataState: PageMetadataState): Observable<DropdownOption[]> {
        return this.mockDataProvider.getDynamicDropdownData(dropdownOptionsDynamic, fieldId, changedFieldId, changedFieldValue, pageMetadataState);
    }

    // Get dynamic child fields.
    public getDynamicChildFields(childFieldsDynamic: ChildFieldsDynamic, fieldId: string, changedFieldValue: number|string|boolean,
        pageMetadataState: PageMetadataState): Observable<SectionField[]> {
        return this.mockDataProvider.getDynamicChildFields(childFieldsDynamic, fieldId, changedFieldValue, pageMetadataState);
    }

    // Get dynamic sections.
    public getDynamicSections(sectionsDynamic: SectionsDynamic, fieldId: string, changedFieldValue: number|string|boolean,
        pageMetadataState: PageMetadataState): Observable<Section[]> {
        return this.mockDataProvider.getDynamicSections(sectionsDynamic, fieldId, changedFieldValue, pageMetadataState);
    }

    // Get session data.
    public getSessionData(): Observable<SessionData> {
        return this.mockDataProvider.getSessionData();
    }
}

import { Injectable } from '@angular/core';
import { SectionField } from 'app/common/metadata-models/sectionField';

// Interface defining a field watcher dictionary. Each field key in the dictionary has an
// associated array of callback functions which will receive the changed field value as a parameter.
interface IFieldWatcherDictionary {
    [fieldId: string]: ((changedField: SectionField) => void)[];
}

// The FieldWatcherService manages field changes by providing methods to add field watchers and to
// notify when changes are made to any watched field. This service keeps an internal field watcher
// dictionary where each key in the dictionary is an array of callback functions. Any field can
// have any number of watchers with associated callback functions.
@Injectable()
export class FieldWatcherService {
    private fieldWatcher: IFieldWatcherDictionary = {};

    // Constructor for FieldWatcherService.
    constructor() {
    }

    // Add field watchers to the list for the specified fieldId.
    addFieldWatcher(fieldId: string, callback: (changedField: SectionField) => void)  {
        if (!this.fieldWatcher[fieldId]) {
            this.fieldWatcher[fieldId] = [];
        }
        this.fieldWatcher[fieldId].push(callback);
    }

    // Notify all field watchers by calling the registered callbacks.
    notifyChanges(sectionField: SectionField): void {
        if (!this.fieldWatcher[sectionField.id]) {
            return;
        }

        for (let i = 0; i < this.fieldWatcher[sectionField.id].length; i++) {
            // Call the callback method.
            this.fieldWatcher[sectionField.id][i](sectionField);
        }
    }

    // Clears all field watchers.
    clearFieldWatchers(): void {
        this.fieldWatcher = {};
    }

    // Clear field watchers for specified field.
    clearFieldWatchersForField(fieldId: string) {
        if (!!this.fieldWatcher[fieldId]) {
            this.fieldWatcher[fieldId] = [];
        }
    }
}

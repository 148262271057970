import { Component, OnInit, Input } from '@angular/core';
import { PageMetadataState } from 'app/common/utility/page-metadata-state';
import { AppSharedUtilityService } from 'app/common/services/app-shared-utility.service';
import { LocalizationManagerService } from 'app/common/services/localization-manager.service';
import { SessionManagerService } from 'app/common/services/session-manager.service';
import { InstrumentationService } from 'app/common/services/instrumentation.service';

@Component({
    selector: 'app-metadata-page-wrapper',
    templateUrl: './metadata-page-wrapper.component.html',
    styleUrls: ['./metadata-page-wrapper.component.css']
})
export class MetadataPageWrapperComponent implements OnInit {
    @Input('pageMetadataState') public pageMetadataState: PageMetadataState;
    @Input('heading') public heading: string;

    // MetadataPageWrapperComponent constructor.
    constructor(
        private appSharedUtilityService: AppSharedUtilityService,
        public sessionManagerService: SessionManagerService,
        public instrumentationService: InstrumentationService,
        public lms: LocalizationManagerService /* Using short name on purpose for data binding. */) {
    }

    // Angular OnInit handler. Part of the lifecycle hooks: https://angular.io/guide/lifecycle-hooks
    public ngOnInit() {
    }

    // Cancel button click handler.
    public onClickCancel(): void {
        // Navigate back to the return uri.
        this.appSharedUtilityService.navigateToReturnUri();
    }
}

import { NgModule } from '@angular/core';
import { commonDeclarations, commonImports, commonProviders } from 'app/app-objects';
import { AppComponent } from 'app/shell/app/app.component';

// Note: When generating new components with Angular CLI - it may add those components here, or not add them here or in
// the app-objects.ts file. Depends on how much smarts Angular CLI has on inferring this. Normally those arrays are
// directly here in this file (in the @NgModule directly).
// Manually move or add objects as needed to app-objects.ts in the proper place.
@NgModule({
    declarations: commonDeclarations,
    imports: commonImports,
    providers: commonProviders,
    bootstrap: [AppComponent]
})
export class AppModule { }

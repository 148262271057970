import * as $ from 'jquery';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { SectionField } from 'app/common/metadata-models/sectionField';
import { DropdownOption } from 'app/common/metadata-models/dropdownOption';
import { DropdownOptionDynamic } from 'app/common/metadata-models/dropdownOptionDynamic';
import { ControlOptions } from 'app/common/metadata-models/controlOptions';
import { FieldWatcherService } from 'app/common/services/field-watcher.service';
import { MetadataApiService } from 'app/common/services/metadata-api.service';
import { BaseControl } from 'app/common/components/controls/base-control';
import { MiscUtil } from 'app/common/utility/miscUtil';
import { environment } from 'environments/environment';
import { LocalizationManagerService } from 'app/common/services/localization-manager.service';
import { AppSharedStateService } from 'app/common/services/app-shared-state.service';
import { AppSharedUtilityService } from 'app/common/services/app-shared-utility.service';
import { InstrumentationService } from 'app/common/services/instrumentation.service';

enum NothingSelectedTextOpt { Metadata, Loading, LoadFailed }

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DropdownComponent),
            multi: true
        }
    ]
})
export class DropdownComponent extends BaseControl implements OnInit, AfterViewInit {
    @ViewChild('dropDownList') public dropDownList: DropDownListComponent;

    // Debug flag to optionally keep the dropdown open, which allows for inspecting the element. Keep this set to false normally.
    private debugKeepOpen: boolean = false;
    public dropdownData: DropdownOption[] = null;
    public dynamicDrivenByField: SectionField;
    public dropdownOptionsDynamic: DropdownOptionDynamic;
    public nothingSelectedText: DropdownOption = new DropdownOption({text: '', value: null});
    private prevSelectedValue: number|string|boolean = null;

    // Constructor for DropdownComponent.
    constructor(
        protected fieldWatcherService: FieldWatcherService,
        protected metadataApiService: MetadataApiService,
        protected changeDetectorRef: ChangeDetectorRef,
        protected domSanitizer: DomSanitizer,
        protected appSharedStateService: AppSharedStateService,
        protected appSharedUtilityService: AppSharedUtilityService,
        private instrumentationService: InstrumentationService,
        public lms: LocalizationManagerService /* Using short name on purpose for data binding. */) {
        super(fieldWatcherService, metadataApiService, changeDetectorRef, domSanitizer, appSharedStateService, appSharedUtilityService, lms);
    }

    // Angular OnInit handler. Part of the lifecycle hooks: https://angular.io/guide/lifecycle-hooks
    public ngOnInit(): void {
    }

    // Angular AfterViewInit handler. Part of the lifecycle hooks: https://angular.io/guide/lifecycle-hooks
    public ngAfterViewInit(): void {
        // This is done in a setTimeout so it defers running until a data binding pass is done first.
        setTimeout(() => {
            this.setNothingSelectedText(NothingSelectedTextOpt.Metadata);

            const controlOptions: ControlOptions = this.fieldData.metadata.controlOptions;
            if (!!controlOptions.dropdownOptionsStatic) {
                this.dropdownData = this.fieldData.metadata.controlOptions.dropdownOptionsStatic;

                // If dropdownData is only a list of one item - then pre-select that one item automatically.
                if (this.dropdownData.length === 1) {
                    this.fieldData.fieldValue = this.dropdownData[0].value;
                    this.onValueChange(this.fieldData.fieldValue);
                }
            } else if (!!controlOptions.dropdownOptionsDynamic) {
                // Disable this control until the watched field has a change. It is empty until a change occurs,
                // so do not have an enabled dropdown with nothing in it yet. Will be enabled once it gets populated
                // after watched field changes.
                this.dropDownList.disabled = true;
                this.dropdownOptionsDynamic = this.fieldData.metadata.controlOptions.dropdownOptionsDynamic;

                if (!!this.dropdownOptionsDynamic) {
                    // Set the drivenByFieldLabel based on the dropdownOptionsDynamic.drivenByFieldId.
                    this.dynamicDrivenByField = this.pageMetadataState.findFieldRecursive(this.dropdownOptionsDynamic.drivenByFieldId);
                    // If the drivenByFieldId is in the parent chain above this control (that is, this control is in the childFields of the parent
                    // or grandparents). We should not set up a field watcher for that drivenByFieldId as when it changes then a whole new set
                    // of children would be retrieved, wiping out this control instance. So, in this case, just immediately call getDynamicDropdownData
                    // to get the dynamic dropdown values based on the already selected value in the dynamicDrivenByField.
                    if (this.pageMetadataState.isFieldDescendantOf(this.fieldData.id, this.dropdownOptionsDynamic.drivenByFieldId)) {
                        this.setupDynamicDropdown(false);
                    } else {
                        this.setupDynamicDropdown();
                    }
                }
            }

            // Adding field watchers must be done in ngAfterViewInit.
            this.addCommonFieldWatchers();

            // Mark this control as ready after the field watchers have been set.
            this.fieldData.isControlReady = true;

            // Manually trigger a change for the control if there was a value initially set.
            if (!MiscUtil.isNullOrUndefinedOrEmptyString(this.fieldData.fieldValue)) {
                // Run this once all controls are ready and have registered their own field watchers.
                this.pageMetadataState.runWhenControlsReadyForActiveSection(() => {
                    this.onValueChange(this.fieldData.fieldValue);
                });
            }
        });
    }

    // Set up the dynamic dropdown.
    private setupDynamicDropdown(useFieldWatcher: boolean = true): void {
        // If there is no dropdownOptionsDynamic then do nothing and return.
        if (!this.dropdownOptionsDynamic) {
            return;
        }

        // If the driven by field has no value (null or undefined or empty string) then just set
        // dropdownOptionsDynamic.initialLoadComplete to true.
        if (MiscUtil.isNullOrUndefinedOrEmptyString(this.dynamicDrivenByField.fieldValue)) {
            this.dropdownOptionsDynamic.initialLoadComplete = true;
        } else {
            this.dropdownOptionsDynamic.initialLoadComplete = false;
        }

        const callback = (changedField: SectionField) => {
            console.log(`Dropdown field ${this.fieldData.id} got a change notification from field ${this.dropdownOptionsDynamic.drivenByFieldId} and value is: ${changedField.fieldValue}`);

            // Set the dropdownOptionsDynamic.isLoadingOrReloading flag to true.
            this.dropdownOptionsDynamic.isLoadingOrReloading = true;

            // If section data is not loading then null out the field value for this control. Since the
            // drivenByFieldId (parent control) has changed, then user must re-select something for this control.
            // If section data is loading then we don't want to null out the field value for this control.
            if (!this.pageMetadataState.sectionFieldsLoading) {
                // Disable this DropDownList as it will be reloaded with new data.
                this.dropDownList.disabled = true;
                // Null out the field value.
                this.fieldData.fieldValue = null;
                this.onValueChange(this.fieldData.fieldValue);
            }

            // This next line is a bit of a hack. The Kendo DropDownList will only display the red validation box
            // around the control if the ng-touched class is on the control. Kendo DropDownList does not set this
            // ng-touched class duing initial control load on purpose as the user has not yet "touched" this control.
            // In other words, Kendo will not scream at the user with a red box around the control saying it is invalid
            // until the user tries to change it. In this case, we do want the red box to appear immediately if the
            // control is invalid based on the user "touching" the drivenByFieldId (parent) control. That is, if the
            // parent control is touched then we implicitly touch this control too. Manually setting the class here
            // works around this. This needs to be done regardless of this.pageMetadataState.sectionFieldsLoading.
            $(`#${this.fieldData.id}`).closest('.k-dropdown').removeClass('ng-untouched').addClass('ng-touched');

            if (MiscUtil.isNullOrUndefined(changedField.fieldValue)) {
                // Clear out the data for this dropdown.
                this.dropdownData = null;
                // Disable this DropDownList as there is no data.
                this.dropDownList.disabled = true;
                // Set the dropdownOptionsDynamic.initialLoadComplete flag to true as there was no data to load.
                this.dropdownOptionsDynamic.initialLoadComplete = true;
                // Set the dropdownOptionsDynamic.isLoadingOrReloading flag to false.
                this.dropdownOptionsDynamic.isLoadingOrReloading = false;
            } else {
                this.setNothingSelectedText(NothingSelectedTextOpt.Loading);
                setTimeout(() => {
                    // Call the API specified in the dropdownOptionsDynamic.
                    this.metadataApiService.getDynamicDropdownData(this.dropdownOptionsDynamic, this.fieldData.id,
                        this.dropdownOptionsDynamic.drivenByFieldId, changedField.fieldValue, this.pageMetadataState)
                        .subscribe((dropdownOptions: DropdownOption[]) => {
                            this.setNothingSelectedText(NothingSelectedTextOpt.Metadata);
                            this.dropdownData = dropdownOptions;
                            // Enable this DropDownList now that there is data.
                            this.dropDownList.disabled = false;
                            // Set the dropdownOptionsDynamic.initialLoadComplete flag to true.
                            this.dropdownOptionsDynamic.initialLoadComplete = true;
                            // Set the dropdownOptionsDynamic.isLoadingOrReloading flag to false.
                            this.dropdownOptionsDynamic.isLoadingOrReloading = false;

                            // If dropdownOptions is only a list of one item - then pre-select that one item automatically.
                            // This is just a basic user assistance thing - where we select the one item automatically.
                            if (dropdownOptions.length === 1) {
                                this.fieldData.fieldValue = dropdownOptions[0].value;
                                this.onValueChange(this.fieldData.fieldValue);
                            }
                        }, (error: any) => {
                            // The call failed. The CustomErrorHandlerService will cause an error to display.
                            this.setNothingSelectedText(NothingSelectedTextOpt.LoadFailed);
                        });
                }, environment.useLocalMockData ? environment.useLocalMockDataSimulatedDelay : 0); // If using local mock data, then simulate a delay.
            }
        };

        // If useFieldWatcher is true then add a field watcher to watch for changes in the drivenByFieldId. The callback will be called whenever
        // that field changes. Otherwise if this is false then just call the callback right now.
        if (useFieldWatcher) {
            this.fieldWatcherService.addFieldWatcher(this.dropdownOptionsDynamic.drivenByFieldId, callback);
        } else {
            callback(this.dynamicDrivenByField);
        }
    }

    // Kendo DropDownList close handler.
    public onClose(event: Event): void {
        // Check to see if the dropdown selected value is different than the bound value. This can happen if the user uses
        // the keyboard to navigate the list and then click out of the dropdown elsewhere rather than click on the dropdown item.
        if (this.fieldData.fieldValue !== this.dropDownList.value) {
            this.fieldData.fieldValue = this.dropDownList.value;
            this.onValueChange(this.fieldData.fieldValue);
        }

        if (this.debugKeepOpen) {
            event.preventDefault();
                // Close the list if the component is no longer focused.
                setTimeout(() => {
                    if (!this.dropDownList.wrapper.nativeElement.contains(document.activeElement)) {
                        this.dropDownList.toggle(false);
                    }
                });
        }
    }

    // Kendo DropDownList value changed handler.
    public onValueChange(value: number|string|boolean): void {
        // If value or this.prevSelectedValue were undefined, make them null. This is to eliminate problems due to undefined !== null.
        if (value === undefined) {
            value = null;
        }
        if (this.prevSelectedValue === undefined) {
            this.prevSelectedValue = null;
        }

        if (this.prevSelectedValue !== value) {
            this.notifyChanges();
            if (!this.pageMetadataState.sectionFieldsLoading && (!this.dropdownOptionsDynamic || !this.dropdownOptionsDynamic.isLoadingOrReloading)) {
                this.instrumentationService.trackEvent(
                    // Commenting the below out for now to see if it helps with userFlow telemetry by not having the actual selection in name
                    // `Dropdown change:  ${this.fieldData.id}, ${this.dropDownList.dataItem.text}`,
                    `Dropdown change:  ${this.fieldData.id}`,
                    {
                        id: this.fieldData.id,
                        value: this.dropDownList.dataItem.text,
                        correlationId: this.instrumentationService.generateCorrelationId(),
                        referenceId: this.appSharedStateService.referenceId,
                        userKey: this.appSharedStateService.userKey
                    }
                );
            }

            this.prevSelectedValue = value;
        }
    }

    // Fire change notification.
    private notifyChanges(): void {
        // Fire the change notification within a setTimeout. Reason is form control validation is not done until until the UI has
        // had a chance to process the value change. For a dropdown marked as required, when changing from nothing selected to
        // a selected value - then it is initially invalid, but after a setTimeout when the dropdown has a selection then the control
        // is valid.
        setTimeout(() => {
            this.fieldWatcherService.notifyChanges(this.fieldData);
        });
    }

    // Sets nothing selected text (the default item in the dropdown when nothing is selected).
    private setNothingSelectedText(nothingSelectedTextOpt: NothingSelectedTextOpt): void {
        switch (nothingSelectedTextOpt) {
            case NothingSelectedTextOpt.Metadata:
                this.nothingSelectedText = new DropdownOption({text: this.fieldData.metadata.controlOptions.nullSelectionText, value: null});
                break;
            case NothingSelectedTextOpt.Loading:
                this.nothingSelectedText = new DropdownOption({text: this.lms.get('LOADING_ELLIPSIS'), value: null});
                break;
            case NothingSelectedTextOpt.LoadFailed:
                this.nothingSelectedText = new DropdownOption({text: this.lms.get('LOAD_FAILED'), value: null});
                break;
        }
    }
}

import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { AppSharedStateService } from 'app/common/services/app-shared-state.service';
import { LocalizationManagerService, supportedLanguages, DisplayLanguage } from 'app/common/services/localization-manager.service';
import { environment } from 'environments/environment';

/*
Header URLs that can be used to test co-branding (alternate header) support.
MPC/NVA portal - various environments:
    https://uatepportal.cloudapp.net/header.cshtml
    https://devepportal.cloudapp.net/header.cshtml
    https://paymentcentral.microsoft.com/header.cshtml
UST/Partner Incentives portal - various environments:
    https://uat2-partnerincentives.microsoft.com/webapi/header.cshtml
    https://partnerincentives.microsoft.com/webapi/header.cshtml
*/

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {
    @ViewChild('dropDownListLanguageSelector') public dropDownListLanguageSelector: DropDownListComponent;
    public displayLanguageSelector: boolean = false;
    public supportedLanguages: DisplayLanguage[] = supportedLanguages;
    private prevSelectedLanguage: string;

    // Constructor for HeaderComponent.
    constructor(
        public appSharedStateService: AppSharedStateService,
        public lms: LocalizationManagerService /* Using short name on purpose for data binding. */) {
        // future todo: Only display the language selector for non-production builds. For now at least.
        // Reason is that as of right now, this site is only part of the larger set of sites which
        // includes legacy POP and NVA. Changing a language here would not affect the other two sites and
        // would be confusing. Once this site contains ALL functionality and the legacy sites are gone
        // then always display this selector.
        // future todo: It would be nice to save this setting across sessions, such as maybe using
        // local storage or a cookie.
        if (environment.displayLanguageSelector) {
            this.displayLanguageSelector = true;
        }
    }

    // Checks to see if the app is in a busy state.
    // If busy then the language selector dropdown will be disabled.
    public get isBusy(): boolean {
        if (!!this.appSharedStateService.activePageMetadataState) {
            if (!this.appSharedStateService.activePageMetadataState.displaySectionsLoaded) {
                return true;
            } else if (this.appSharedStateService.activePageMetadataState.sectionFieldsLoading) {
                return true;
            }
        }
        return false;
    }

    // Angular OnInit handler. Part of the lifecycle hooks: https://angular.io/guide/lifecycle-hooks
    public ngOnInit(): void {
    }

    // Angular AfterViewInit handler. Part of the lifecycle hooks: https://angular.io/guide/lifecycle-hooks
    public ngAfterViewInit(): void {
        setTimeout(() => {
            this.prevSelectedLanguage = this.lms.selectedLanguageCode;
        });
    }

    // Request support clicked handler.
    public requestSupportClicked(): void {
        alert('to-do: add support functionality similar to legacy app');
    }

    // Kendo DropDownList value changed handler.
    public onValueChange(value: string): void {
        if (!this.isBusy) {
            if (this.prevSelectedLanguage !== value) {
                this.lms.changeLocalization(value);
                this.prevSelectedLanguage = value;
            }
        }
    }
}

import { ControlType } from 'app/common/metadata-models/controlType';
import { SectionField } from 'app/common/metadata-models/sectionField';
import { FieldMetadata } from 'app/common/metadata-models/fieldMetadata';
import { ChildFieldsDynamic } from 'app/common/metadata-models/childFieldsDynamic';

// Mock data used for development and testing.
export class MockSectionDynamicChildFields {
    public cat: SectionField[] = [
        new SectionField({
            id: 'Cat',
            metadata: new FieldMetadata({
                fieldGroup: 'ChildrenTestGroup',
                columnHint: 1,
                indentHint: 0,
                label: '<img src="http://2.bp.blogspot.com/-SxPx6_sBLfY/T9uflRttTUI/AAAAAAAAAD4/3gZRfALWMiE/s1600/cute_cat_by_silentpain0.jpg" style="width:175px;box-shadow:10px 10px 5px #888888;">',
                control: ControlType.html
            })
        }),
    ];

    public dog: SectionField[] = [
        new SectionField({
            id: 'Dog',
            metadata: new FieldMetadata({
                fieldGroup: 'ChildrenTestGroup',
                columnHint: 1,
                indentHint: 0,
                label: '<img src="http://cdn7.whiskeyriff.com/wp-content/uploads/cutest-puppy-ever-1384460985_org.jpg" style="width:175px;box-shadow:10px 10px 5px #888888;">',
                control: ControlType.html
            })
        }),
    ];

    public panda: SectionField[] = [
        new SectionField({
            id: 'Panda',
            metadata: new FieldMetadata({
                fieldGroup: 'ChildrenTestGroup',
                columnHint: 1,
                indentHint: 0,
                label: '<img src="http://imgc.allpostersimages.com/images/P-473-488-90/61/6159/UAWG100Z/posters/frank-lukasseck-baby-giant-panda.jpg" style="width:175px;box-shadow:10px 10px 5px #888888;">',
                control: ControlType.html
            })
        }),
    ];

    public sloth: SectionField[] = [
        new SectionField({
            id: 'Sloth',
            metadata: new FieldMetadata({
                fieldGroup: 'ChildrenTestGroup',
                columnHint: 1,
                indentHint: 0,
                label: '<img src="http://sloths.com.au/wp-content/uploads/2014/10/hug.jpg" style="width:175px;box-shadow:10px 10px 5px #888888;">',
                control: ControlType.html
            })
        }),
    ];

    public goat: SectionField[] = [
        new SectionField({
            id: 'Goat',
            metadata: new FieldMetadata({
                fieldGroup: 'ChildrenTestGroup',
                columnHint: 2,
                indentHint: 0,
                label: '<img src="https://s-media-cache-ak0.pinimg.com/736x/ba/aa/96/baaa96505ab3f1771c23d0af363bd168.jpg" style="width:175px;box-shadow:10px 10px 5px #888888;">',
                control: ControlType.html
            })
        }),
    ];

    public sheep: SectionField[] = [
        new SectionField({
            id: 'Sheep',
            metadata: new FieldMetadata({
                fieldGroup: 'ChildrenTestGroup',
                columnHint: 3,
                indentHint: 0,
                label: '<img src="https://i.pinimg.com/originals/bd/63/af/bd63af7f6a89449f5e0145de0bc8cefd.jpg" style="width:175px;box-shadow:10px 10px 5px #888888;">',
                control: ControlType.html
            })
        }),
    ];

    public pig: SectionField[] = [
        new SectionField({
            id: 'Pig',
            metadata: new FieldMetadata({
                fieldGroup: 'ChildrenTestGroup',
                columnHint: 4,
                indentHint: 0,
                label: '<img src="http://media3.onsugar.com/files/2011/09/39/3/192/1922243/pigletssq/i/Cute-Piglets-Zoo.jpg" style="width:175px;box-shadow:10px 10px 5px #888888;">',
                control: ControlType.html
            })
        }),
    ];

    public checkboxMultilevel2: SectionField[] = [
        new SectionField({
            id: 'CheckboxMultilevel2',
            fieldValue: false,
            childFieldsDynamic: new ChildFieldsDynamic({
                httpVerb: 'GET',
                api: 'Sections/{sectionId}/Fields/{fieldId}/ChildFields?fieldValue={fieldValue}'
            }),
            metadata: new FieldMetadata({
                fieldGroup: 'MultiLevelCheckboxGroup',
                columnHint: 1,
                required: false,
                label: 'Checkbox level 2',
                readOnly: false,
                ariaLabel: 'Checkbox level 2',
                fieldInfo: 'Checkbox level 2',
                control: ControlType.checkbox
            })
        }),
    ];

    public checkboxMultilevel3: SectionField[] = [
        new SectionField({
            id: 'CheckboxMultilevel3',
            fieldValue: false,
            childFieldsDynamic: new ChildFieldsDynamic({
                httpVerb: 'GET',
                api: 'Sections/{sectionId}/Fields/{fieldId}/ChildFields?fieldValue={fieldValue}'
            }),
            metadata: new FieldMetadata({
                fieldGroup: 'MultiLevelCheckboxGroup',
                columnHint: 1,
                required: false,
                label: 'Checkbox level 3',
                readOnly: false,
                ariaLabel: 'Checkbox level 3',
                fieldInfo: 'Checkbox level 3',
                control: ControlType.checkbox
            })
        }),
    ];
}

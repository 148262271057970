import { Injectable, EventEmitter } from '@angular/core';
import { PageMetadataState, IPageMetadataState } from 'app/common/utility/page-metadata-state';
import { SessionData } from 'app/common/metadata-models/sessionData';
import { MessageData } from 'app/common/components/message-display/message-data';
import { AlertType } from 'app/common/components/message-display/alert-type';
import { LocalizationManagerService } from 'app/common/services/localization-manager.service';
import { AppSharedStateService } from 'app/common/services/app-shared-state.service';
import { LoadingScreenData } from 'app/common/components/loadingscreen-display/loadingscreen-data';

// Header/Footer type using string backed enum.
export enum HeaderFooterType {
    Undetermined = 'Undetermined',                       // The initial default value, until it is determined to be one of the below.
    BuiltIn = 'BuiltIn',                                 // The built in default header/footer.
    CobrandRemoteJavascript = 'CobrandRemoteJavascript', // A javascript based header, typically as generated from a ASP.NET MVC .cshtml file.
    CobrandRemoteHtml = 'CobrandRemoteHtml'              // A html based header/footer.
}

// App shared state service.
// This object is intended to hold any shared state needed by the entire app.
@Injectable()
export class AppSharedUtilityService {
    public messageDisplayEmitter: EventEmitter<MessageData> = new EventEmitter<MessageData>();
    public loadingScreenDisplayEmitter: EventEmitter<LoadingScreenData> = new EventEmitter<LoadingScreenData>(); 

    // Constructor for AppSharedUtilityService.
    constructor(
        private appSharedStateService: AppSharedStateService,
        private lms: LocalizationManagerService) {
    }

    // Emit an event which will cause a message to be displayed.
    public displayMessage(messageData: MessageData) {
        this.messageDisplayEmitter.emit(messageData);
    }

    // Emit an event to open/close the loading screen
    public notifyLoadingScreen(loadingscreenData : LoadingScreenData){
        this.loadingScreenDisplayEmitter.emit(loadingscreenData); 
    }

    // Redirect back to return uri.
    public navigateToReturnUri() {
        if (!!this.appSharedStateService.sessionData && this.appSharedStateService.sessionData.returnUri) {
            window.location.href = this.appSharedStateService.sessionData.returnUri;
            return;
        } else {
            const messageData: MessageData = {
                message: this.lms.get('ERROR_INVALID_RETURN_URI'),
                alertType: AlertType.Error
            };
            this.displayMessage(messageData);
        }
    }
}

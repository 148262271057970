import { DropdownOption } from 'app/common/metadata-models/dropdownOption';

// Mock data used for development and testing.
export class MockDynamicDropdownData {
    public currencies_1: DropdownOption[] = [
        new DropdownOption({
            text: 'US Dollar, USD',
            value: 'USD'
        }),
        new DropdownOption({
            text: 'Japanese Yen, JPY',
            value: 'JPY'
        }),
        new DropdownOption({
            text: 'China Renminbi, CNY',
            value: 'CNY'
        }),
        new DropdownOption({
            text: 'Russia Ruble, RUB',
            value: 'RUB'
        })
    ];

    public currencies_2: DropdownOption[] = [
        new DropdownOption({
            text: 'US Dollar, USD',
            value: 'USD'
        })
    ];
}

import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { throwError as observableThrowError, Observable, Subscribable } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { AppSharedStateService } from 'app/common/services/app-shared-state.service';
import { default as englishDefaultJson } from 'assets/resourceJson/en.json';
import { MiscUtil } from 'app/common/utility/miscUtil';

// Represents a display language and supported language codes.
export interface DisplayLanguage {
    name: string;
    code: 'en' | 'bg' | 'zh-Hans' | 'zh-Hant' | 'cs' | 'fr' | 'de' | 'el' | 'hu' | 'id' |
    'it' | 'ja' | 'ko' | 'pl' | 'pt-BR' | 'ru' | 'sr' | 'es' | 'th' | 'tr' | 'vi';
}

// Supported languages
export const supportedLanguages: DisplayLanguage[] = [
    { name: 'English', code: 'en' },
    { name: 'Bulgarian / Български', code: 'bg' },
    { name: 'Chinese - Simplified / 简体中文', code: 'zh-Hans' },
    { name: 'Chinese - Traditional / 繁體中文', code: 'zh-Hant' },
    { name: 'Czech / Čeština', code: 'cs' },
    { name: 'French / Français', code: 'fr' },
    { name: 'German / Deutsch', code: 'de' },
    { name: 'Greek / Ελληνικά', code: 'el' },
    { name: 'Hungarian / magyar', code: 'hu' },
    { name: 'Indonesian / Indonesia', code: 'id' },
    { name: 'Italian / Italiano', code: 'it' },
    { name: 'Japanese / 日本語', code: 'ja' },
    { name: 'Korean / 한국어', code: 'ko' },
    { name: 'Polish / Polski', code: 'pl' },
    { name: 'Portuguese - Brazil / Português-Brasil', code: 'pt-BR' },
    { name: 'Russian / русский', code: 'ru' },
    { name: 'Serbian / srpski', code: 'sr' },
    { name: 'Spanish / Español', code: 'es' },
    { name: 'Thai / ไทย', code: 'th' },
    { name: 'Turkish / Türkçe', code: 'tr' },
    { name: 'Vietnamese / Việt Nam', code: 'vi' }
];

// Represents a localization key/value pair in a localization json file.
export interface Localization {
    [key: string]: string;
}

// Localization manager service.
@Injectable()
export class LocalizationManagerService {
    // The selected language code.
    public selectedLanguageCode: 'en' | 'bg' | 'zh-Hans' | 'zh-Hant' | 'cs' | 'fr' | 'de' | 'el' | 'hu' | 'id' |
        'it' | 'ja' | 'ko' | 'pl' | 'pt-BR' | 'ru' | 'sr' | 'es' | 'th' | 'tr' | 'vi' = 'en';

    public activeLocalization: Localization = {};
    public isLocalizedResourceLoaded: boolean = false;
    public localizationChangedEmitter: EventEmitter<void> = new EventEmitter<void>();

    // LocalizationManagerService constructor.
    constructor(
        private appSharedStateService: AppSharedStateService,
        private httpClient: HttpClient) {
        this.initLocalizedResource();
    }

    // Init localized resource.
    public initLocalizedResource(): void {
        const runWhenSessionDataLoaded = (): void => {
            let langCodeFull: string;
            // If session data has a locale to use (passed in from redirection from NVA or partner system) then use that locale.
            // Otherwise use navigator.language.
            if (!!this.appSharedStateService.sessionData && !!this.appSharedStateService.sessionData.locale) {
                langCodeFull = this.appSharedStateService.sessionData.locale;
            } else {
                langCodeFull = navigator.language;
            }

            const langCodeShort = langCodeFull.substr(0, 2);
            switch (langCodeShort) {
                case 'bg':
                    this.selectedLanguageCode = 'bg';
                    break;
                case 'zh':
                    if (langCodeFull === 'zh-Hant') {
                        this.selectedLanguageCode = 'zh-Hant';
                    } else {
                        this.selectedLanguageCode = 'zh-Hans';
                    }
                    break;
                case 'cs':
                    this.selectedLanguageCode = 'cs';
                    break;
                case 'fr':
                    this.selectedLanguageCode = 'fr';
                    break;
                case 'de':
                    this.selectedLanguageCode = 'de';
                    break;
                case 'el':
                    this.selectedLanguageCode = 'el';
                    break;
                case 'hu':
                    this.selectedLanguageCode = 'hu';
                    break;
                case 'id':
                    this.selectedLanguageCode = 'id';
                    break;
                case 'it':
                    this.selectedLanguageCode = 'it';
                    break;
                case 'ja':
                    this.selectedLanguageCode = 'ja';
                    break;
                case 'ko':
                    this.selectedLanguageCode = 'ko';
                    break;
                case 'pl':
                    this.selectedLanguageCode = 'pl';
                    break;
                case 'pt':
                    this.selectedLanguageCode = 'pt-BR';
                    break;
                case 'ru':
                    this.selectedLanguageCode = 'ru';
                    break;
                case 'sr':
                    this.selectedLanguageCode = 'sr';
                    break;
                case 'es':
                    this.selectedLanguageCode = 'es';
                    break;
                case 'th':
                    this.selectedLanguageCode = 'th';
                    break;
                case 'tr':
                    this.selectedLanguageCode = 'tr';
                    break;
                case 'vi':
                    this.selectedLanguageCode = 'vi';
                    break;
                default: // Default to english.
                case 'en':
                    this.selectedLanguageCode = 'en';
                    break;
            }
            document.getElementsByTagName('html')[0].setAttribute('lang', this.selectedLanguageCode);

            this.downloadSelectedLocalization();
        };

        MiscUtil.waitFn(() => {
            return !!this.appSharedStateService.sessionData;
        }, runWhenSessionDataLoaded, 100, 30 /* 100 * 30 == 3000ms or 3 seconds - wait UP TO this long for session data. If elapsed it will call callback anyway. */);
    }

    // Change localization to specified code.
    public changeLocalization(code: string) {
        if (code === 'en' || code === 'bg' || code === 'zh-Hans' || code === 'zh-Hant' || code === 'cs' || code === 'fr' || code === 'de' ||
            code === 'el' || code === 'hu' || code === 'id' || code === 'it' || code === 'ja' || code === 'ko' || code === 'pl' ||
            code === 'pt-BR' || code === 'ru' || code === 'sr' || code === 'es' || code === 'th' || code === 'tr' || code === 'vi') {
            this.selectedLanguageCode = code;
        } else {
            this.selectedLanguageCode = 'en';
        }
        document.getElementsByTagName('html')[0].setAttribute('lang', this.selectedLanguageCode);
        this.downloadSelectedLocalization();
        this.localizationChangedEmitter.emit();
    }

    // Download the selected localization file.
    public downloadSelectedLocalization(): void {
        if (this.selectedLanguageCode === 'en') {
            // Optimization for en language since this is by far the most used language in this system. For en, we bake in the resource file into
            // the app bundles by doing the "import * as englishDefaultJson from 'assets/resourceJson/en.json';" above. So the en.json is embedded
            // directly into the bundle. Optimization is that it need not download anything further for english. For when using other languages,
            // there is the small overhead of having two localization json data in memory. English and the selected language.
            console.log(`Using default localization for language en`);
            this.activeLocalization = <any>englishDefaultJson;
            this.isLocalizedResourceLoaded = true;
        } else {
            const locJsonUrl = `${window.location.origin}/assets/resourceJson/${this.selectedLanguageCode}.json`;
            this.httpClient.get<Localization>(locJsonUrl, { observe: 'response' }).pipe(
                map((response: HttpResponse<Localization>) => {
                    return response.body;
                }),
                tap(data => {
                    console.log(`Downloaded localization file: ${locJsonUrl}`);
                    this.activeLocalization = data;
                    this.isLocalizedResourceLoaded = true;
                }),
                catchError((err: any, caught: Observable<any>) => {
                    console.log(`Using default localization for language en`);
                    this.activeLocalization = <any>englishDefaultJson;
                    this.isLocalizedResourceLoaded = true;
                    return observableThrowError(err);
                }))
                .subscribe();
        }
    }

    // Get active localization at key.
    public get(key: string) {
        return this.activeLocalization[key];
    }
}

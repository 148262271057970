<div [ngStyle]="{'display': !lms.isLocalizedResourceLoaded ? 'block' : 'none'}" class="loading-spinner-container">
    <div class="loading-spinner-outer">
        <div class="loading-spinner-inner">
            <span class="fas fa-spinner fa-pulse" aria-hidden="true"></span>
        </div>
    </div>
</div>
<div class="wrapper" [ngStyle]="{'display': lms.isLocalizedResourceLoaded ? 'block' : 'none'}">
    <app-header></app-header>
    <app-dev-tools *ngIf="displayDevTools"></app-dev-tools>
    <div class="content">
        <router-outlet></router-outlet>
    </div>
    <app-footer class="footer"></app-footer>

    <!-- Place this error display at the bottom so z-index makes this content appear on top. The error display is absolute positioned. -->
    <app-error-display></app-error-display>
    <!-- Same with message display. -->
    <app-message-display></app-message-display>
    <!-- Same with loading screen -->
    <app-loadingscreen-display></app-loadingscreen-display>
</div>
